import { FC } from "react";
import { Page } from "../../common/components/page-component/Page";
import ConfigModal from "./ConfigModal";
import LandingPage from "./LandingPage";

export const ConfigPage: FC = () => {

  return (
    <Page>
      <ConfigModal visible={true} closable={false} />
      <LandingPage /> { /* show landing page behind mask */ }
    </Page>
  );
};