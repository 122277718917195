import { FC } from 'react';
import useConfigContext from '../../hooks/useConfigContext';
import { BoundButton, DefaultModal } from '../../components';
import { TSkeleton } from '../../components/TranslationSkeleton';
import { TextBindings } from '../../../compiler/enums';
import { useBinding, useIsMobile, useIsSmallestMobile, useTheme } from '../../utils/common';

import './ExitSiteSafelyModal.css';

export const ExitSiteSafelyModal: FC<{ visible: boolean }> = ({
  visible
}) => {
  const { setHasConfirmedExitSafely, hasConfirmedConfig } = useConfigContext();
  const { translationsLoading } = useConfigContext();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const isSmallestMobile = useIsSmallestMobile();

  const boundExitSafelyTitle = useBinding(`${TextBindings.ModalExitSiteSafelyTitle}.Text`);
  const boundExitSafelyText = useBinding(`${TextBindings.ModalExitSiteSafelyText}.Text`);

  if (!visible) {
    return null;
  }

  const exitSafelyModalBody = (
    <>
      <TSkeleton paragraph={{rows: 2}} width={isMobile ? "100%" : 350} active={hasConfirmedConfig}>
        <h2 className="averia-serif title">
          {boundExitSafelyTitle}
        </h2>
        <div className="lato body">
          {boundExitSafelyText}
        </div>
      </TSkeleton>
      <BoundButton 
        disabled={translationsLoading} 
        binding={TextBindings.ButtonIUnderstand} 
        text="I Understand" 
        onClick={() => {setHasConfirmedExitSafely(true)}}
        tabIndex={1}
      />
    </>
  );

  return (
    <>
      {isMobile && (
        <DefaultModal 
          className={"exit-safely-modal" + theme + (isMobile ? " mobile" : "")}
          wrapClassName={"exit-safely-modal-wrapper" + (isMobile ? " mobile" : "")}
          styles={{
            mask: { top: isSmallestMobile ? "110px" : (isMobile ? "70px" : "140px") },
          }}
          visible={visible}
          closable={false}
          maskClosable={false}
          footer={null}
        >
          { exitSafelyModalBody }
        </DefaultModal>
      )}
      { !isMobile && (
        <div className="exit-safely-modal">
          { exitSafelyModalBody }
        </div>
      )}
    </>
  );
};
