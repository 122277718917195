import { FC } from "react";
import { Page } from "../../common/components/page-component/Page";

import { decode } from 'html-entities';
import { LocalizedPageContent } from "../../compiler/types";
import { HeroCardProps } from "../../common/components/HeroCard";
import { getMediaUrl, useIsMobile } from "../../common/utils/common";
import useConfig from "../../config/hooks/useConfig";
import { NavCardProps } from "../../common/components/NavCard";
import DefaultImage from "../../common/components/DefaultImage";
import { HeroCardGroup } from "../../common/components/HeroCardGroup";
import { NavCardGroup } from "../../common/components/NavCardGroup";

import "./FeatureHubComponent.css";

type FeatureHubComponentProps = {
  content?: LocalizedPageContent,
  headerImageAltText: string,
  heroCards?: Array<HeroCardProps>
  navCards?: Array<NavCardProps>
};

const FeatureHub: FC<FeatureHubComponentProps> = ({
  content, headerImageAltText, heroCards, navCards
}) => {
  const pageContent = content;
  const config = useConfig();

  const mediaSrc = getMediaUrl(config?.assetUrl, pageContent?.Icon);
  const isMobile = useIsMobile();

  if (heroCards && navCards) {
    throw Error("Check FeatureHub props, only heroCards OR navCards prop allowed");
  }

  return (
    <Page furtherResources={pageContent?.FurtherResources}>
      <div className="feature-hub-component">
        <div className="color section header">
          <div>
            { pageContent?.Title && (
              <h1>
                { pageContent?.Title }
              </h1>
            )}
            { pageContent?.Content && (
              <div className="lato" dangerouslySetInnerHTML={{
                  __html: decode(pageContent?.Content)
                }}
              />
            )}
          </div>
          { mediaSrc && (
            <div className="hub-image">
              <DefaultImage height={isMobile ? 180 : 300} width={isMobile ? 240 : 400} src={pageContent?.Icon ?? "https://picsum.photos/400/300"} alt={headerImageAltText} />
            </div>
          )}
        </div>
          <div className="white section">
            { heroCards && (
              <HeroCardGroup heroCards={heroCards} unwrap={false} />
            )}
            { navCards && (
              <NavCardGroup navCards={navCards} />
            )}
          </div>
      </div>
    </Page>
  );
};

export default FeatureHub;