import { LocationSearchContextActions, LocationSearchContextType } from "./LocationSearchContext";

export const locationSearchContextReducer = (state: LocationSearchContextType, action: LocationSearchContextActions): LocationSearchContextType => {
  switch (action.type) {

    case 'SET_LOADING':
      return { ...state, loading: action.payload.loading };

    case 'SET_TAGS':
      return { ...state, tags: action.payload.tags };

    case 'SET_TAG_FILTERS':
      return { ...state, tagFilters: action.payload.tagFilters };

    case 'SET_LOCATION_SEARCH':
      return { ...state, locationSearch: action.payload.locationSearch };

    case 'SET_COORDINATES':
      return { ...state, coordinates: action.payload.coordinates };

    case 'SET_MAP_REGION':
      return { ...state, mapRegion: action.payload.mapRegion };

    case 'SET_SERVICE_RESULTS':
      return { ...state, serviceResults: action.payload.serviceResults };

    case 'SET_SELECTED_LOCATION':
      return { ...state, selectedLocation: action.payload.selectedLocation };

    default:
      throw new Error(`Invalid action type ${JSON.stringify(state)} ${JSON.stringify(action)}`);
  }
}
