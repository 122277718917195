import { FC, useMemo } from 'react';
import { BoundButton } from './BoundButton';
import { TSkeleton } from './TranslationSkeleton';
import { GuideBindings, LinksUnion, TextBindings } from '../../compiler/enums';
import { decode } from 'html-entities';
import { StorageKeys } from '../../config/Storage';
import { useBinding, useBoundCardTitle, useLink } from '../utils/common';
import useContentContext from '../hooks/useContentContext';
import { LocalizedGuideContent } from '../../compiler/types';

export type NavCardProps = {
  titleBinding: TextBindings | string,
  textBinding: TextBindings,
  link: LinksUnion,
};

export const NavCard: FC<NavCardProps> = ({
  titleBinding, textBinding, link
}) => {
  const boundTitle = useBoundCardTitle(titleBinding);
  const boundText = useBinding(`${textBinding}.Text`);

  return (
    <div className="nav-card">
      <div className="card-title-and-body">
        <TSkeleton>
          <h2 className="lato">{decode(boundTitle)}</h2>
        </TSkeleton>
        <div tabIndex={0} className="card-body">
          <TSkeleton paragraph={{rows: 2}}>
            <span className="lato">{boundText}</span>
          </TSkeleton>
        </div>
      </div>
      <BoundButton 
        type="default"
        isCTA={true}
        text="Learn More" 
        binding={TextBindings.LabelLearnMore} 
        link={useLink(link)} 
      />
    </div>
  );
};
