import { FC, useMemo } from 'react';
import { Select, SelectProps } from 'antd';
import { decode } from 'html-entities';
import { ListBindings } from '../../compiler/enums';
import { getLangFromUrl } from '../../util/functions';
import useContentContext from '../hooks/useContentContext';
import { StorageKeys } from '../../config/Storage';
import { ListItem, LocalizedListContent } from '../../compiler/types';

export interface DefaultSelectProps<T> extends SelectProps {
  items?: Array<T>,
  width?: number,
  ariaLabel: string,
  noWidth?: boolean,
  name?: string,
  itemsBinding?: ListBindings,
  placeholder?: string,
}

export const DefaultSelect: FC<DefaultSelectProps<any>> = ({
  id, onChange, value, items = [], defaultValue, loading = false, size = 'medium', width, noWidth,
  allowClear = true, className, optionRender, labelRender, ariaLabel, variant, itemsBinding, placeholder
}) => {
  const l = getLangFromUrl();
  const { getContent } = useContentContext();

  const boundItems = useMemo(() => {
    if (itemsBinding) {
      const boundList: LocalizedListContent | null = getContent(StorageKeys.ListsKey, itemsBinding);
      return boundList?.Items;
    }
  }, [itemsBinding, getContent]);

  const { Option } = Select;
  let fixedWidth = 120;

  switch (size) {
    case 'small':
      fixedWidth = 120;
      break;
    case 'medium':
      fixedWidth = 200;
      break;
    case 'large':
      fixedWidth = 300;
      break;
  }

  fixedWidth = width == null ? fixedWidth : width;

  const mappedItems = useMemo(() => {
    if (boundItems && boundItems.length > 0 && items.length > 0) {
      throw Error(`Check DefaultSelect: Found conflicting listBinding & items definition`);
    }

    if (boundItems) {
      return boundItems.filter(x => x.Identifier !== undefined).map((v: ListItem, i) => {
        return (
          <Option key={i.toString()} value={v.Identifier}>
            {decode(v.Label || '')}
          </Option>
        );
      });
    }

    return items.filter(x => x.value !== undefined).map((v, i) => {
      return (
        <Option key={i.toString()} value={v.value}>
          {decode(v.label || '')}
        </Option>
      );
    });
  }, [Option, boundItems, items]);

  const currentPlaceholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    if (boundItems) {
      return boundItems.filter(x => x.Identifier === undefined)[0]?.Label;
    }

    return items.filter(x => x.value === undefined)[0]?.label;
  }, [boundItems, items, placeholder]);

  return (
    <Select
      id={id}
      className={`${className ? className + ' ' : ''}default-select`}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      style={{ width: noWidth === true ? "auto" : fixedWidth, marginBottom: 12 }}
      allowClear={allowClear}
      loading={loading}
      optionRender={optionRender}
      labelRender={labelRender}
      aria-label={ariaLabel}
      variant={variant}
      placeholder={currentPlaceholder}
      tabIndex={0}
    >
      {mappedItems}
    </Select>
  );
};
