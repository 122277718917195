/* eslint-disable react-hooks/rules-of-hooks */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import useConfig from '../config/hooks/useConfig';

const config = useConfig();
const browserHistory = createBrowserHistory({ basename: '' });
const appInsightsPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
		disableCookiesUsage: true,
        instrumentationKey: config?.appInsightsKey,
        extensions: [appInsightsPlugin],
        extensionConfig: {
            [appInsightsPlugin.identifier]: { history: browserHistory },
        },
    },
});
config && appInsights.loadAppInsights();
export { appInsightsPlugin, appInsights };
