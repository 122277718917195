/* eslint-disable react/prop-types */
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Col, Divider, Form, Row } from 'antd';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle, faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../../../common/hooks/useActiveCountry';
import { BoundButton, DefaultModal } from '../../../common/components';
import { PageBindings, TextBindings } from '../../../compiler/enums';
import { useBinding, useTheme } from '../../../common/utils/common';
import { UpdateLocationRequest } from '../types';
import contentService from '../../../services/content-service';
import { FormRow, FormRowType } from '../../../common/components/FormRow';

type UpdateDetailsModalProps = {
  located: "Support" | "Helplines",
}

export const UpdateDetailsModal: FC<UpdateDetailsModalProps> = ({ located }) => {
  const c = useActiveCountry();
  const { l } = useCountryTranslation(c?.Code.iv);
  const theme = useTheme();

  const [modalVisible, setModalVisible] = useState(false);
  const [screen, setScreen] = useState(1);

  const updateDetailsBtnBinding = located === "Support" ? TextBindings.TextUpdateDetailsSupportButton : TextBindings.TextUpdateDetailsHelplinesButton;

  const updateDetailsPromptBinding = located === "Support" ? TextBindings.TextUpdateDetailsSupportText : TextBindings.TextUpdateDetailsHelplinesText;
  const updateDetailsPromptText = useBinding(`${updateDetailsPromptBinding}.Text`);

  const modalText = useBinding(`${PageBindings.UpdateDetailsModal}.Title`);
  const modalBody = useBinding(`${PageBindings.UpdateDetailsModal}.Content`);

  const successTitle = useBinding(`${PageBindings.UpdateDetailsDone}.Title`);
  const successBody = useBinding(`${PageBindings.UpdateDetailsDone}.Content`);

  const updateDetailsWarning = useBinding(`${TextBindings.TextUpdateDetailsWarning}.Text`);

  const hideModal = resetForm => {
    setModalVisible(false);
    resetForm();
  };

  const showModal = () => {
    setScreen(1);
    setModalVisible(true);
  };

  const onModalSubmit = (values, formBag) => {
    if (!c) {
      return;
    }

    const newData: UpdateLocationRequest = {
      IsNationalHelpLine: {
        iv: located === 'Helplines',
      },
      IsNewEntry: values.AddOrUpdate === 'new',
      Country: {
        iv: c?.Code.iv,
      },
      Name: {
        [l]: values.LocationName,
      },
      Address: {
        [l]: values.LocationAddress,
      },
      Hours: {
        [l]: values.LocationHours,
      },
      Description: {
        [l]: values.LocationDescription,
      },
      Website: {
        iv: values.LocationWebsite,
      },
      EmailAddresses: {
        iv: [
          {
            IsPrimary: true,
            Email: values.LocationEmail,
          },
        ],
      },
      PhoneNumbers: {
        iv: [
          {
            IsPrimary: true,
            Number: values.LocationPhone,
          },
        ],
      },
      RequestorName: values.UserName,
      RequestorEmail: values.UserEmail,
      RequestorPhoneNumber: values.UserPhone,
      RequestorWorks: values.WorkLocation === 'yes',
    };

    return contentService.updateLocation(l, newData)
      .then(() => {
        console.log("sucessfully submitted", newData)
        setScreen(2);
        formBag.resetForm();
      })
      .catch(err => {
        console.error(err);
      });
  };

  const errorAddOrUpdateRequired = useBinding(`${TextBindings.ErrorAddOrUpdateDetailsRequired}.Text`) ?? undefined;
  const errorNameRequired = useBinding(`${TextBindings.ErrorNameRequired}.Text`) ?? undefined;
  const errorPhoneRequired = useBinding(`${TextBindings.ErrorPhoneRequired}.Text`) ?? undefined;
  const errorRequestorNameRequired = useBinding(`${TextBindings.ErrorRequestorNameRequired}.Text`) ?? undefined;
  const errorPhoneOrEmailRequired = useBinding(`${TextBindings.ErrorPhoneOrEmailRequired}.Text`) ?? undefined;
  const errorWorkForLocationRequired = useBinding(`${TextBindings.ErrorWorkForLocationRequired}.Text`) ?? undefined;
  const errorConsentRequired = useBinding(`${TextBindings.ErrorConsentRequired}.Text`) ?? undefined;

  const errorInvalidEmail = useBinding(`${TextBindings.ErrorInvalidEmail}.Text`) ?? undefined;
  const errorInvalidWebsite = useBinding(`${TextBindings.ErrorInvalidWebsite}.Text`) ?? undefined;

  const validationSchema = Yup.object().shape(
    {
      AddOrUpdate: Yup.string().required(errorAddOrUpdateRequired).oneOf(['new', 'update']),
      LocationName: Yup.string().required(errorNameRequired),
      LocationEmail: Yup.string().email(errorInvalidEmail),
      LocationPhone: Yup.string().required(errorPhoneRequired),
      LocationWebsite: Yup.string().url(errorInvalidWebsite).optional(),
      UserName: Yup.string().required(errorRequestorNameRequired),
      UserEmail: Yup.string().email(errorInvalidEmail)
        .when('UserPhone', {
          is: val => val || false,
          then: schema => schema.optional(),
          otherwise: schema => schema.required(errorPhoneOrEmailRequired),
      }),
      UserPhone: Yup.string()
        .when('UserEmail', {
          is: val => val || false,
          then: schema => schema.optional(),
          otherwise: schema => schema.required(errorPhoneOrEmailRequired),
      }),
      WorkLocation: Yup.string().required(errorWorkForLocationRequired).oneOf(['yes', 'no']),
      Consent: Yup.bool().required(errorConsentRequired).oneOf([true]),
    }, [["UserEmail", "UserPhone"], ["UserPhone", "UserEmail"]]
  );

  return (
    <div className="update-details-prompt-row">
      <Row justify="end">
        <Col className="update-details-prompt-group">
          <div>
            <FontAwesomeIcon icon={faInfoCircle} />
            <span>{updateDetailsPromptText}</span>
          </div>
          <BoundButton
            type="link"
            binding={updateDetailsBtnBinding}
            onClick={showModal}
          />
        </Col>
      </Row>

      <Formik
        initialValues={{
          AddOrUpdate: '',
          LocationName: '',
          LocationAddress: '',
          LocationEmail: '',
          LocationPhone: '',
          LocationWebsite: '',
          LocationHours: '',
          LocationDescription: '',
          UserName: '',
          UserEmail: '',
          UserPhone: '',
          WorkLocation: '',
          Consent: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onModalSubmit}
      >
        {({ values, touched, errors, handleChange, handleBlur, submitForm, setFieldValue, isSubmitting, resetForm }) => (
          <DefaultModal
            visible={modalVisible}
            title={modalText}
            onCancel={() => hideModal(resetForm)}
            className={"update-service-details-modal" + theme}
            width="90%"
            footer={ screen === 1
                ? [
                    <BoundButton 
                      key="back"
                      type="default" 
                      onClick={() => hideModal(resetForm)}
                      binding={TextBindings.LabelCancel}
                    />,
                    <BoundButton 
                      key="submit"
                      type="primary" 
                      onClick={submitForm}
                      loading={isSubmitting}
                      binding={TextBindings.ButtonSend}
                      iconRight={faPaperPlaneTop}
                    />,
                ]
                : [                                    
                      <BoundButton 
                        key="close"
                        type="primary" 
                        onClick={() => hideModal(resetForm)}
                        binding={TextBindings.ButtonDone}
                      />,
                ]
            }
          >
            { screen === 1 && (
              <div>
                { modalBody && (
                  <p dangerouslySetInnerHTML={{ __html: modalBody }} />
                )}
                <Form layout="vertical">
                  <FormRow
                    className="add-or-update-row"
                    name="AddOrUpdate"
                    type={FormRowType.Radio}
                    textBinding={TextBindings.TextUpdateDetailsAddUpdate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.AddOrUpdate}
                    touched={touched.AddOrUpdate}
                    error={errors.AddOrUpdate}
                    choices={[
                      { value: 'new', binding: TextBindings.TextUpdateDetailsRadioAdd },
                      { value: 'update', binding: TextBindings.TextUpdateDetailsRadioUpdate },
                    ]}
                    setFieldValue={setFieldValue}
                    required
                  />
                  <Divider />
                  <FormRow
                    name="LocationName"
                    type={FormRowType.TextBox}
                    textBinding={TextBindings.LabelName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.LocationName}
                    touched={touched.LocationName}
                    error={errors.LocationName}
                    required
                  />
                  { located !== 'Helplines' && (
                    <FormRow
                      name="LocationAddress"
                      type={FormRowType.TextBox}
                      textBinding={TextBindings.LabelAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.LocationAddress}
                      touched={touched.LocationAddress}
                      error={errors.LocationAddress}
                      extra={
                        <span>
                          <FontAwesomeIcon icon={faInfoCircle} />
                          <span style={{ marginLeft: 4 }}>{updateDetailsWarning}</span>
                        </span>
                      }
                    />
                  )}
                    <FormRow
                      name="LocationWebsite"
                      type={FormRowType.TextBox}
                      textBinding={TextBindings.LabelWebsite}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.LocationWebsite}
                      touched={touched.LocationWebsite}
                      error={errors.LocationWebsite}
                    />
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <FormRow
                          name="LocationEmail"
                          type={FormRowType.TextBox}
                          textBinding={TextBindings.LabelEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.LocationEmail}
                          touched={touched.LocationEmail}
                          error={errors.LocationEmail}
                        />
                      </Col>
                      <Col span={12}>
                        <FormRow
                          name="LocationPhone"
                          type={FormRowType.TextBox}
                          textBinding={TextBindings.LabelPhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.LocationPhone}
                          touched={touched.LocationPhone}
                          error={errors.LocationPhone}
                          required
                        />
                      </Col>
                    </Row>
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <FormRow
                          name="LocationHours"
                          type={FormRowType.TextBox}
                          textBinding={TextBindings.LabelHours}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.LocationHours}
                          touched={touched.LocationHours}
                          error={errors.LocationHours}
                        />
                      </Col>
                    </Row>
                    <FormRow
                      name="LocationDescription"
                      type={FormRowType.TextArea}
                      textBinding={TextBindings.LabelDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.LocationDescription}
                      touched={touched.LocationDescription}
                      error={errors.LocationDescription}
                    />
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <FormRow
                          name="UserName"
                          type={FormRowType.TextBox}
                          textBinding={TextBindings.LabelName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.UserName}
                          touched={touched.UserName}
                          error={errors.UserName}
                          required
                        />
                      </Col>
                    </Row>
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <FormRow
                          name="UserEmail"
                          type={FormRowType.TextBox}
                          textBinding={TextBindings.LabelEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.UserEmail}
                          touched={touched.UserEmail}
                          error={errors.UserEmail}
                          required
                        />
                      </Col>
                      <Col span={12}>
                        <FormRow
                          name="UserPhone"
                          type={FormRowType.TextBox}
                          textBinding={TextBindings.LabelPhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.UserPhone}
                          touched={touched.UserPhone}
                          error={errors.UserPhone}
                          required
                        />
                      </Col>
                    </Row>
                    <FormRow
                      name="WorkLocation"
                      type={FormRowType.Radio}
                      textBinding={TextBindings.TextUpdateDetailsWorkLocation}
                      onChange={handleChange}
                      value={values.WorkLocation}
                      touched={touched.WorkLocation}
                      error={errors.WorkLocation}
                      choices={[
                        { value: 'yes', binding: TextBindings.TextYes },
                        { value: 'no', binding: TextBindings.TextNo },
                      ]}
                      setFieldValue={setFieldValue}
                      required
                    />
                    <FormRow
                      className="consent-row"
                      name="Consent"
                      type={FormRowType.Checkbox}
                      textBinding={TextBindings.TextUpdateDetailsConsent}
                      onChange={handleChange}
                      value={values.Consent}
                      touched={touched.Consent}
                      error={errors.Consent}
                      setFieldValue={setFieldValue}
                      required
                    />
                  </Form>
                </div>
              )}
              { screen === 2 && (
                <div className="success-body">
                  <Row justify="center" style={{ marginTop: 16 }}>
                    <Col>
                      <FontAwesomeIcon icon={faCheckCircle} size="3x" />
                    </Col>
                  </Row>
                  <Row justify="center" style={{ marginTop: 16 }}>
                    <Col>
                      <h2>{successTitle}</h2>
                    </Col>
                  </Row>
                  { successBody && (
                    <Row justify="center">
                      <Col span={18} style={{ textAlign: 'center' }}>
                        <p dangerouslySetInnerHTML={{ __html: successBody }} />
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            </DefaultModal>
          )}
      </Formik>
    </div>
  );
};
