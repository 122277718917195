import React, { Dispatch, FC, createContext, useMemo, useReducer } from "react";
import { appSettingsContextReducer } from './reducers';
import { CommonActions } from "../../compiler/types";

export type AppSettings = {
  defaultLanguageCode?: string,
  exitUrl?: string,
  webHeroImage?: string,
  locationFiltering?: boolean,
  iosInstallUrl?: string,
  androidInstallUrl?: string,
  findHelpSearchEnabled?: boolean,
  additionalGuideEnabled?: boolean,
  newResourcesEnabled?: boolean,
  webTheme?: string | null,
  feedbackModalQuestion?: "EaseOfUseRating" | "UseAgainRating" | "HelpfulRating",
  submitServiceRequestEnabled?: boolean,
}

export interface AppSettingsContextType {
  loading: boolean,
  appSettings: AppSettings | null,
};

export type AppSettingsContextActions =
  CommonActions
  | { type: 'SET_APP_SETTINGS', payload: { appSettings: AppSettings; } }

export interface AppSettingsContextMemo {
  context: AppSettingsContextType;
  dispatch: Dispatch<AppSettingsContextActions>;
}

const defaultAppSettingsContext = {
  loading: false,
  appSettings: null,
};

export const AppSettingsContext = createContext<AppSettingsContextMemo>({
  context: defaultAppSettingsContext,
  dispatch: () => { }
});

export const AppSettingsContextProvider: FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [context, dispatch] = useReducer(appSettingsContextReducer, defaultAppSettingsContext)

  const memo = useMemo(() => {
    return { context, dispatch};
  }, [context, dispatch]);

  return (
    <AppSettingsContext.Provider value={memo}>
      {children}
    </AppSettingsContext.Provider>
  );
};
