import { FC } from 'react';
import useConfigContext from '../hooks/useConfigContext';
import { Space, ButtonProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { getFlagClassName } from './functions';
import { BoundButton } from './BoundButton';
import { TextBindings } from '../../compiler/enums';

export const CountryToggle: FC<ButtonProps> = ({ onClick }) => {
  const { country, hasConfirmedConfig, isChangingCountry } = useConfigContext();

  const ambiguousRender = !hasConfirmedConfig && !isChangingCountry;
  return (
    <BoundButton
      onClick={onClick}
      ghost
      className="country-toggle"
      ariaLabel={TextBindings.LabelSelectCountry}
      aria-label={"Select Country"}
    >
      <Space>
        { ambiguousRender && ( // for before any country has been selected
          <>
            <span className={`fi fi-xx fi`} />
            <span>
              {'XX'}
            </span>
          </>
        )}
        { !ambiguousRender && (
          <>
            <span className={`fi fi-${getFlagClassName(country?.Code.iv as string)} fi`} />
            <span>
              {country?.Code.iv}
            </span>
         </>
        )}
        <FontAwesomeIcon icon={faAngleDown} />
      </Space>
    </BoundButton>
  );
};

export default CountryToggle;
