import { forwardRef } from 'react';
import { Input, InputProps, InputRef } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { TextBindings } from '../../compiler/enums';
import { trimColon, useBinding } from '../utils/common';

type DefaultTextboxProps = {
  prefix?: IconDefinition,
  icon?: IconDefinition,
  placeholderBinding?: TextBindings,
}

export const DefaultTextbox = forwardRef<InputRef, Omit<InputProps, "prefix"> & DefaultTextboxProps>(({
  icon, placeholder, className, prefix, placeholderBinding, defaultValue, ...props
}, ref) => {
  const iconElement = icon ? <FontAwesomeIcon icon={icon} /> : null;
  const boundPlaceholder = trimColon(useBinding(`${placeholderBinding}.Text`)) ?? undefined;

  return (
    <Input
      ref={ref}
      style={{ position: 'inherit' }}
      className={`default-textbox ${className ?? ''}`}
      placeholder={placeholder ? placeholder : (placeholderBinding ? boundPlaceholder : undefined)}
      suffix={iconElement}
      prefix={prefix ? <FontAwesomeIcon icon={prefix} /> : null}
      {...props}
    />
  );
});
