import { FC } from 'react';
import { ServiceDTO } from '../types';
import { Col, Row, Space } from 'antd';
import MapMarker from './MapMarker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BoundButton } from '../../../common/components';
import { faEnvelope, faLink, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { TextBindings } from '../../../compiler/enums';
import { useBinding } from '../../../common/utils/common';
import { BoxTag } from './BoxTag';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../../../common/hooks/useActiveCountry';
import { compare } from './TagFilters';
import { IconTag } from './IconTag';
import { resolveIcon } from '../../../util/functions';

type ServiceResultCardProps = {
  isHelpline: boolean,
  service: ServiceDTO,
  index: number,
};

export const ServiceResultCard: FC<ServiceResultCardProps> = ({ isHelpline, service, index }) => {
  const c = useActiveCountry();
  const { l } = useCountryTranslation(c?.Code.iv);

  const hoursLabel = useBinding(`${TextBindings.LabelHours}.Text`);
  const phoneLabel = useBinding(`${TextBindings.LabelPhone}.Text`);
  const websiteLabel = useBinding(`${TextBindings.LabelWebsite}.Text`);
  const addressLabel = useBinding(`${TextBindings.LabelAddress}.Text`);
  const emailLabel = useBinding(`${TextBindings.LabelEmail}.Text`);
  const descriptionLabel = useBinding(`${TextBindings.LabelDescription}.Text`);
  const tagsLabel = useBinding(`${TextBindings.LabelTags}.Text`);
  const supportLabel = useBinding(`${TextBindings.LabelSupport}.Text`);

  const supportTags = service.Tags?.filter(t => t.Type === 'Type of Support').sort((a, b) => compare(a, b, l));
  const locationTags = service.Tags?.filter(t => t.Type === 'Location').sort((a, b) => compare(a, b, l));

  return (
    <div className="service-result-card">
      <div className="left">
        <Space className="lato title">
          <MapMarker text={index + 1} outline />
          <span className="service-name">{service.Name}</span>
        </Space>
        <div className="card-body lato">
          <Row className="row-1">
            { locationTags && locationTags.length > 0 && (
              <Col>
                <b>{tagsLabel}</b>
                <div className="tag-container">
                  { locationTags.map((tag) => {
                    return (
                      <IconTag text={tag.Title} icon={resolveIcon(tag.Icon)} />
                    );
                  })}
                </div>
              </Col>
            )}
            { service.Hours && (
              <Col>
                <b>{hoursLabel}</b>
                <div dangerouslySetInnerHTML={{ __html: service.Hours }} />
              </Col>
            )}
            { service.PrimaryPhoneNumber && (
              <Col>
                <b>{phoneLabel}</b>
                <div dangerouslySetInnerHTML={{ __html: service.PrimaryPhoneNumber }} />
              </Col>
            )}
            { service.Website && (
              <Col>
                <b>{websiteLabel}</b>
                <div dangerouslySetInnerHTML={{ __html: service.Website }} />
              </Col>
            )}
          </Row>
          { !isHelpline && (
            <Row className="row-2">
              { service.Address && (
                <Col>
                  <b>{addressLabel}</b>
                  <div dangerouslySetInnerHTML={{ __html: service.Address }} />
                </Col>
              )}
              { service.PrimaryEmailAddress && (
                <Col>
                  <b>{emailLabel}</b>
                  <div dangerouslySetInnerHTML={{ __html: service.PrimaryEmailAddress }} />
                </Col>
              )}
            </Row>
          )}
          <Row className="row-3">
            { service.Description && (
              <Col>
                <b>{descriptionLabel}</b>
                <div dangerouslySetInnerHTML={{ __html: service.Description }} />
              </Col>
            )}
          </Row>
          { supportTags && supportTags.length > 0 && (
            <Row className="tags-row">
              <b>{supportLabel}</b>
              <div className="tag-container">
                { supportTags.map((tag) => {
                  return (
                    <BoxTag text={tag.Title} color={tag.ColorCode} borderColor={tag.BorderColorCode} />
                  );
                })}
              </div>
            </Row>
          )}
        </div>
      </div>
      <div className="right">
        <Space size="middle">
        { service.PrimaryPhoneNumber && (
          <BoundButton
            type="primary"
            icon={<FontAwesomeIcon icon={faPhone} />}
            binding={TextBindings.ButtonCall}
            onClick={() => window.open(`tel:${service.PrimaryPhoneNumber.replace(/\s/g, '')}`)}
          />
        )}
        { service.Website && (
          <BoundButton
            type="default"
            icon={<FontAwesomeIcon icon={faLink} />}
            binding={TextBindings.LabelWebsite}
            trimColon
            onClick={() => service.Website && window.open(service.Website.trim())}
            popConfirmOnClick
            popConfirmBinding={TextBindings.TextOpenNewTab}
          />
        )}
        { service.PrimaryEmailAddress && (
          <BoundButton
            type="default"
            icon={<FontAwesomeIcon icon={faEnvelope} />}
            binding={TextBindings.LabelEmail}
            trimColon
            onClick={() => service.PrimaryEmailAddress && window.open(`mailto:${service.PrimaryEmailAddress.trim()}`)}
          />
        )}
      </Space>
      </div>
    </div>
  );
};
