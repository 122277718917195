import { FC } from "react";
import { Page } from "../../common/components/page-component/Page";

import { QuestionnaireBindings } from "../../compiler/enums";
import { decode } from 'html-entities';
import { LocalizedQuestionnaireContent } from "../../compiler/types";
import useConfig from "../../config/hooks/useConfig";
import { getMediaUrl, useIsMobile } from "../../common/utils/common";
import { BoundQuestionnaire } from "./components/BoundQuestionnaire";
import DefaultImage from "../../common/components/DefaultImage";

import "./QuestionnaireComponent.css";

type QuestionnaireComponentProps = {
  content?: LocalizedQuestionnaireContent,
  headerImageAltText: string,
  binding?: QuestionnaireBindings,
};

export const Questionnaire: FC<QuestionnaireComponentProps> = ({
  content, headerImageAltText, binding
}) => {
  const questionnaireContent = content;
  const config = useConfig();
  const isMobile = useIsMobile();
  //const mediaSrc = getMediaUrl(config?.assetUrl, questionnaireContent?.Icon);

  return (
    <Page furtherResources={questionnaireContent?.FurtherResources}>
      <div className="questionnaire-component">
        <div className="color section header">
           <div>
            { questionnaireContent?.Title && (
              <h1>
                { questionnaireContent?.Title }
              </h1>
            )}
            { questionnaireContent?.Description && (
              <div className="lato" dangerouslySetInnerHTML={{
                  __html: decode(questionnaireContent?.Description)
                }}
              />
            )}
          </div>
          <div className="questionnaire-image">
          {/*{ mediaSrc && (*/}
          <DefaultImage 
            height={isMobile ? 180 : 300} 
            width={isMobile ? 240 : 400} 
            src={"https://picsum.photos/400/300"} 
            alt={headerImageAltText}
          />
          {/*)}*/}
          </div>
        </div>
        <div className="white section">
          <BoundQuestionnaire
            content={content}
            binding={binding}
          />
        </div>
      </div>
    </Page>
  );
};