import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { GuideLinks, Links, PageLinks, QuestionnaireLinks } from '../compiler/enums';
import { StorageKeys } from '../config/Storage';

export const isEmptyObject = objectToTest => {
  return JSON.stringify(objectToTest) === '{}';
};

export const getCountrySubdomain = () => {
  const domainParts = window.location.host.split('.');
  const subdomain = domainParts.length === 3 ? domainParts[0] : null;

  const hasCountryInUrl =
    subdomain != null &&
    domainParts.length === 3 &&
    subdomain.toLowerCase() !== 'www' &&
    subdomain.toLowerCase() !== 'dev' &&
    subdomain.toLowerCase() !== 'qa';
  let sCountry = hasCountryInUrl ? subdomain : null;

  if (subdomain != null && hasCountryInUrl) {
    sCountry = subdomain?.indexOf('-') !== -1 ? subdomain.split('-')[1].toUpperCase() : subdomain.toUpperCase();
  }

  return sCountry;
};

export const hasCountryInDomain = () => {
  const domainParts = window.location.host.split('.');
  const subdomain = domainParts.length === 3 ? domainParts[0] : null;

  const hasCountryInUrl =
    subdomain != null &&
    domainParts.length === 3 &&
    subdomain.toLowerCase() !== 'www' &&
    subdomain.toLowerCase() !== 'dev' &&
    subdomain.toLowerCase() !== 'qa';

  return hasCountryInUrl;
};

export const getLangFromUrl = () => {
  let urlLang = window.location.pathname.split('/')[1];
  return urlLang ? urlLang : null;
};

export const getConsumerIdFromUrl = () => {
  let tok = window.location.pathname.split('/');
  return tok[2] === "home" ? tok[3] : null;
};

export const resolveIcon = (name, assetUri?) => {
  const customIconKey = '*UseAsset*';

  if (name && name !== customIconKey) {
    const fullIconName = `fa${name}`;
    const icon = Icons[fullIconName];
    return icon;
  }

  if (name && name === customIconKey && assetUri) {
    return {
      isCustom: true,
      uri: assetUri,
    };
  }

  return null;
};

export const contentExists = (country, contentName, content) => {
  if (!content || !contentName) {
    return false;
  }
  var boundContentName = country + "_" + contentName;
  return content.localeCompare(boundContentName) !== 0 && content.localeCompare("NA") !== 0;
};

export const getSimplePathname = (link?: string): string => {
  const url = link ?? window.location.pathname;
  const urlParts = url.split('/');
  const pathWithoutQueryString = urlParts[urlParts.length - 1].split('?')[0];
  return `/${pathWithoutQueryString}`;
} 

export const getPriorityContentKey = (): StorageKeys | null => {
  const pathname = getSimplePathname();

  if (Object.values<string>(GuideLinks).includes(pathname)) {
    return StorageKeys.GuideKey;
  }

  if (Object.values<string>(QuestionnaireLinks).includes(pathname)) {
    return StorageKeys.QuestionnaireContentKey;
  }

  return null;
} 

export const isLocalIPConnection = () => {
  const ipv4Regex = new RegExp('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
  const hostname = window.location.hostname;
  return ipv4Regex.test(hostname);
};