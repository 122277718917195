import { useCountryTranslation } from 'brightsky-3';
import { Form, Spin, Switch } from 'antd';
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import useActiveCountry from '../../../common/hooks/useActiveCountry';
import useWebAnalyticsContext from '../../../common/hooks/useWebAnalyticsContext';
import { BoundButton } from '../../../common/components';
import { TextBindings } from '../../../compiler/enums';
import { useBinding } from '../../../common/utils/common';
import journalService from '../../../services/journal-service';
import { JournalEntryFormData, JournalEntryRequest } from '../types';
import { Formik, FormikProps } from 'formik';
import { FormRow, FormRowType } from '../../../common/components/FormRow';
import dayjs from 'dayjs';

const JournalForm = () => {
  const c = useActiveCountry();
  const { l } = useCountryTranslation(c?.Code.iv);
  const [form] = Form.useForm();
  const { validateFields, getFieldsError } = form;
  const [showPrompts, setShowPrompts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>();
  const [error, setError] = useState<string | null>();
  const [usePrompts, setUsePrompts] = useState(false);
  const { logEvent } = useWebAnalyticsContext();
  const formRef = useRef<FormikProps<JournalEntryFormData>>(null);

  const labelPromptChoice = useBinding(`${TextBindings.LabelJournalPrompt}.Text`);

  const journalSubmitSuccess = useBinding(`${TextBindings.TextJournalSubmitSuccess}.Text`);
  const journalSubmitFailure = useBinding(`${TextBindings.ErrorJournalSubmitFailed}.Text`);

  const handleShowPromptsClick = () => {
    setShowPrompts(!showPrompts);
    setUsePrompts(promptOn => !promptOn);
  };

  const onFinish = (entry: JournalEntryFormData) => {
    validateFields();
    if (Events?.JournalSubmit) {
      logEvent(Events.JournalSubmit, Origins.Journal, Templates.journal.submit(usePrompts));
    }
    const validationErrors = Object.values(getFieldsError());
    if (!validationErrors.find(e => e === undefined)) {
      const journalEntry: JournalEntryRequest = {
        LanguageCode: l,
        Country: c?.Code.iv,
        ...entry 
      };

      journalService.createJournalEntry(journalEntry)
        .then(() => {
          setLoading(false);
          setSuccess(journalSubmitSuccess);
          setTimeout(() => {
            setSuccess('');
          }, 5000);

          // clear values
          formRef.current?.resetForm();
        },
        () => {
          setLoading(false);
          setError(journalSubmitFailure);
        }
      );
    }
  };

  const errorEmailRequired = useBinding(`${TextBindings.ErrorEmailRequired}.Text`) ?? undefined;
  const errorInvalidEmail = useBinding(`${TextBindings.ErrorInvalidEmail}.Text`) ?? undefined;

  const validationSchema = Yup.object().shape(
    {
      Email: Yup.string().required(errorEmailRequired).email(errorInvalidEmail),
      Message: Yup.string().nullable(),
      IncidentDate: Yup.date().nullable(),
      WhatHappened: Yup.string().nullable(),
      HowDidItMakeYouFeel: Yup.string().nullable(),
      Attachment: Yup.object().nullable(),
    }
  );

  return (
    <div className="journal-form">
      <Formik
        initialValues={{
          Email: null,
          Message: null,
          IncidentDate: dayjs(),
          WhatHappened: null,
          HowDidItMakeYouFeel: null,
          Attachment: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values: JournalEntryFormData) => onFinish(values)}
        innerRef={formRef}
      >
        {({ values, touched, errors, handleChange, handleBlur, submitForm, setFieldValue, isSubmitting, resetForm }) => (
          <Form form={form} layout="vertical">
            <Form.Item valuePropName="checked" name="usePrompts" label={labelPromptChoice}>
              <Switch value={usePrompts} onChange={handleShowPromptsClick} />
            </Form.Item>

            <FormRow
              name="Email"
              type={FormRowType.TextBox}
              textBinding={TextBindings.LabelEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Email}
              touched={touched.Email}
              error={errors.Email}
              placeholderBinding={TextBindings.PlaceholderSecureEmail}
              required
            />

            { !showPrompts && (
              <FormRow
                name="Message"
                type={FormRowType.TextArea}
                textBinding={TextBindings.LabelTypeAMessage}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Message}
                touched={touched.Message}
                error={errors.Message}
                placeholderBinding={TextBindings.PlaceholderTypeAMessage}
              />
            )}

            { showPrompts && (
              <>
                <FormRow
                  type={FormRowType.DatePicker}
                  defaultValue={values.IncidentDate}
                  value={values.IncidentDate}
                  name="IncidentDate"
                  onChange={(val) => setFieldValue("IncidentDate", val)}
                  textBinding={TextBindings.LabelIncidentDate}
                />
                <FormRow
                  name="WhatHappened"
                  type={FormRowType.TextArea}
                  textBinding={TextBindings.LabelWhatHappened}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.WhatHappened}
                  touched={touched.WhatHappened}
                  error={errors.WhatHappened}
                  placeholderBinding={TextBindings.PlaceholderWhatHappened}
                />
                <FormRow
                  name="HowDidItMakeYouFeel"
                  type={FormRowType.TextArea}
                  textBinding={TextBindings.LabelHowDidItMakeYouFeel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.HowDidItMakeYouFeel}
                  touched={touched.HowDidItMakeYouFeel}
                  error={errors.HowDidItMakeYouFeel}
                  placeholderBinding={TextBindings.PlaceholderHowDidItMakeYouFeel}
                />
              </>
            )}
            <Form.Item>
              { success != null && <h3>{success}</h3>}
              { error != null && <div style={{ color: 'red' }}>{error}</div>}
              { !loading && (
                <BoundButton
                  type="primary"
                  binding={TextBindings.ButtonSubmitEntry}
                  tooltipBinding="Web_Tooltip_SubmitEntry.Text"
                  htmlType="submit"
                  onClick={submitForm}
                />
              )}
              { loading && <Spin />}
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default JournalForm;
