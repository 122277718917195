import { AppSettingsContextType, AppSettingsContextActions } from "./AppSettingsContext";
import { ConfigContextType, ConfigContextActions } from "./ConfigContext";
import { ContentContextType, ContentContextActions } from "./ContentContext";
import { WebAnalyticsContextType, WebAnalyticsContextActions } from "./WebAnalyticsContext";

export const configContextReducer = (state: ConfigContextType, action: ConfigContextActions): ConfigContextType => {
  switch (action.type) {

    case 'SET_LOADING':
      return { ...state, loading: action.payload.loading };

    case 'SET_SELECTED_COUNTRY':
      return { ...state, country: action.payload.country };

    case 'SET_SAVED_COUNTRY':
      return { ...state, savedCountry: action.payload.country };

    case 'SET_SELECTED_LANGUAGE':
      return { ...state, language: action.payload.language };

    case 'SET_SAVED_LANGUAGE':
      return { ...state, savedLanguage: action.payload.language };

    case 'SET_LANGUAGES':
      return { ...state, languages: action.payload.languages };

    case 'SET_HAS_CONFIRMED_CONFIG':
      return { ...state, hasConfirmedConfig: action.payload.hasConfirmedConfig };

    case 'SET_HAS_CONFIRMED_EXIT_SAFELY':
      return { ...state, hasConfirmedExitSafely: action.payload.hasConfirmedExitSafely };

    case 'SET_IS_CHANGING_COUNTRY':
      return { ...state, isChangingCountry: action.payload.isChangingCountry };

    case 'SET_TRANSLATIONS_LOADING':
      return { ...state, translationsLoading: action.payload.translationsLoading };

    case 'SET_CONFIG_TRANSLATIONS_LOADING':
      return { ...state, configTranslationsLoading: action.payload.configTranslationsLoading };

    case 'SET_CURRENT_THEME':
      return { ...state, currentTheme: action.payload.currentTheme };

    default:
      throw new Error(`Invalid action type ${JSON.stringify(state)} ${JSON.stringify(action)}`);
  }
}

export const appSettingsContextReducer = (state: AppSettingsContextType, action: AppSettingsContextActions): AppSettingsContextType => {
  switch (action.type) {

    case 'SET_LOADING':
      return { ...state, loading: action.payload.loading };

    case 'SET_APP_SETTINGS':
      return { ...state, appSettings: action.payload.appSettings };

    default:
      throw new Error(`Invalid action type ${JSON.stringify(state)} ${JSON.stringify(action)}`);
  }
}

export const contentContextReducer = (state: ContentContextType, action: ContentContextActions): ContentContextType => {
  var workingContent = { ...state.content };
  var workingLoading = { ...state.loading };
  switch (action.type) {

    case 'SET_LOADING':
      workingLoading[action.payload.key] = action.payload.loading;
      return { ...state, loading: workingLoading };

    case 'SET_CONTENT':
      workingContent[action.payload.key] = action.payload.content;
      return { ...state, content: workingContent };

    case 'SET_ALL_CONTENT':
      return { ...state, content: action.payload.content };

    default:
      throw new Error(`Invalid action type ${JSON.stringify(state)} ${JSON.stringify(action)}`);
  }
}

export const webAnalyticsContextReducer = (state: WebAnalyticsContextType, action: WebAnalyticsContextActions): WebAnalyticsContextType => {
  switch (action.type) {

    case 'SET_LOADING':
      return { ...state, loading: action.payload.loading };

    case 'SET_CONSUMER_ID':
      return { ...state, consumerId: action.payload.consumerId };

    default:
      throw new Error(`Invalid action type ${JSON.stringify(state)} ${JSON.stringify(action)}`);
  }
}
