import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Page } from "../../common/components/page-component/Page";

import { decode } from 'html-entities';
import { useCountryTranslation } from 'brightsky-3';
import { trimColon, useBinding } from "../../common/utils/common";
import { SearchableSiteMap, TextBindings } from "../../compiler/enums";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../../common/components/Loading";
import { Divider, Row } from "antd";
import useActiveCountry from "../../common/hooks/useActiveCountry";
import { BoundButton } from "../../common/components";
import contentService from "../../services/content-service";
import { GuideContent, PageContent, QuestionnaireContent } from "../../compiler/types";

import "./SiteSearchResults.css";

type LocalizedSiteSearchResult = {
  Title: string,
  Body: string,
  Preview: string | null,
  Link: string,
  TitleRelevance: number,
  BodyRelevance: number,
};

const SiteSearchResults: FC = () => {
  const c = useActiveCountry();
  const { ct, l } = useCountryTranslation(c?.Code.iv);
  const title = trimColon(useBinding(`${TextBindings.TextSearchResults}.Text`));

  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Array<LocalizedSiteSearchResult> | null>(null);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('query');

  const showingResultsLabel = useMemo(() => {
    var resultsLabel = decode(ct(`${TextBindings.TextResults}.Text`));
    resultsLabel = resultsLabel.trim();

    if (resultsLabel.at(resultsLabel.length - 1) === ':') {
      resultsLabel = resultsLabel.substring(0, resultsLabel.length - 1);
    }

    return `${decode(ct(`${TextBindings.LabelShowingResultsFor}.Text`))} "${searchQuery}" (${results?.length} ${resultsLabel})`;
  }, [ct, results?.length, searchQuery]);

  const queryRegex = useMemo(() => {
    if (!searchQuery) { return null; }
    let queryWords = searchQuery.split(" ");

    // in english, single letter words should be an exact match ("a", "I") and not a substring match
    if (l === "en") {
      queryWords = queryWords.map(x => ["a", "i"].includes(x.toLowerCase()) ? `${x} ` : x);
    }
    return new RegExp(`(${queryWords.join("|")})`, "ig");
  }, [l, searchQuery]);

  // hook to post-process HTML and bold references to the search query in results
  useEffect(() => {
    const bolderize = (el) => {
      if (!el) {
        return;
      }
      el.innerHTML = el.innerHTML.replace(queryRegex, "<strong>$1</strong>")
    }

    const allSearchResults = Array.from(document.getElementsByClassName("bolderize"));
    allSearchResults.forEach((result) => {
      bolderize(result);
    });
  }, [results, queryRegex]);

  const getSiteLink = (binding: string) => {
    return Object.keys(SearchableSiteMap).find(key => SearchableSiteMap[key] === binding);
  };

  const extractContent = (s: string) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const shorten = (str, maxLen, separator = ' ') => {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
  }

  // filters out any html element matches, classname matches, etc.
  // calculates & assigns preview from queryRegex & body
  const processResult = useCallback((result: LocalizedSiteSearchResult): LocalizedSiteSearchResult | null => {
    if (!queryRegex) {
      return null;
    }
    
    let rawTitle = extractContent(result.Title);
    let rawBody = extractContent(result.Body);

    let titleMatches = rawTitle.match(queryRegex) || [];
    let bodyMatches = rawBody.match(queryRegex) || [];

    // filter out false matches
    if (!titleMatches.length && !bodyMatches.length) {
      return null;
    }

    return {
      ...result,
      Preview: shorten(rawBody, 200) + "...",
      TitleRelevance: titleMatches.length,
      BodyRelevance: bodyMatches.length,
    };
  }, [queryRegex]);

  const formatResults = useCallback((results: Array<PageContent | QuestionnaireContent | GuideContent> | null)
    : Array<LocalizedSiteSearchResult> | null => {
    if (!results) {
      return null;
    }

    let processedResults = results.map((result) => {
      let link = getSiteLink(result.Binding["iv"]);

      if (!link) {
        return null;
      }

      return processResult({
        Title: decode(result.Title[l]),
        Body: decode(result["Description"]?.[l] ?? result["Content"]?.[l]),
        Preview: null,
        Link: link,
        TitleRelevance: 0,
        BodyRelevance: 0,
      });
    }).filter(x => !!x) as Array<LocalizedSiteSearchResult>;

    return processedResults.sort((a, b) => { 
      return b?.TitleRelevance - a?.TitleRelevance || b?.BodyRelevance - a?.BodyRelevance;
    });
  }, [l, processResult]);

  useEffect(() => {
    if (searchQuery && c) {
      setLoading(true);
      contentService.searchContent(c.Code.iv, searchQuery)
        .then((results) => {
          setResults(formatResults(results));
          setLoading(false);
        });
    }
  }, [c, formatResults, searchQuery]);
  
  const hasResults = results && results.length > 0;
  const boundNoResults = useBinding(`${TextBindings.TextNoResults}.Text`);

  return (
    <Page>
      <div className="site-search-results">
        <div className="color section header">
          <h1>
            { title }
          </h1>
        </div>
        <div className="white section search-results">
          { loading && (
            <Loading />
          )}
          { !loading && (
            <>
              { hasResults && (
                <div className="search-results-container">
                  <Row className="result-counter">
                    {showingResultsLabel}
                    <Divider />
                  </Row>
                  
                  { results.map((result) => {
                    return (
                      <>
                        <div className="search-result">
                          <div className="lato left">
                            <span className="result-title bolderize">{result.Title}</span>
                            <span className="bolderize">{result.Preview}</span>
                          </div>
                          <BoundButton
                            link={`/${l}${result.Link}`}
                            text="View"
                            type="default"
                          />
                        </div>
                        <Divider />
                      </>
                    );
                  })}
                </div>
              )}
              { !hasResults && (
                <Row justify="center">
                  {boundNoResults}
                </Row>
              )}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default SiteSearchResults;