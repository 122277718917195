import { FC, useMemo } from 'react';
import { TextBindings } from '../../compiler/enums';
import { Space } from 'antd';
import useActiveCountry from '../hooks/useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';

type DefaultBannerProps = {
  binding?: TextBindings,
  text?: string, // TODO: remove fallback text after development?
  className?: string,
  ctaButton?: JSX.Element,
};

export const DefaultBanner: FC<DefaultBannerProps> = ({ binding, ctaButton, text, className }) => {
  const c = useActiveCountry();
  const { ct } = useCountryTranslation(c?.Code.iv);

  const bannerText = useMemo(() => {
    const textBinding = `${binding}.Text`;
    var bt = binding != null ? decode(ct(textBinding)) : text;
    // falls back to "text" if provided & binding is not resolved; may comment out after all content has been added?
    if (bt === `${c?.Code.iv}_${textBinding}`) {
      return text;
    }

    return bt;
  }, [binding, c?.Code.iv, ct, text]);

  return (
    <div className={`default-banner${className ? ` ${className}` : ''}`}>
      <div className="default-banner-content-container">
        <div className="lato text-container">
          <div className="banner-text">{bannerText}</div>
        </div>
        {ctaButton}
      </div>
    </div>
  );
};

export default DefaultBanner;
