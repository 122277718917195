import { FeedbackSubmissionRequest } from "../features/feedback/types";
import api from "../util/api";

const submitFeedback = (feedbackSubmission: FeedbackSubmissionRequest) => {
  return api().post(`Feedback`, feedbackSubmission)
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

const feedbackService = {
  submitFeedback,
};

export default feedbackService;