import { useCountryTranslation } from 'brightsky-3';
import { Checkbox, Form } from 'antd';
import useActiveCountry from '../../../common/hooks/useActiveCountry';
import useLocationSearchContext from '../hooks/useLocationSearchContext';
import { FC } from 'react';
import { BoundButton, DefaultModal } from '../../../common/components';
import { BoxTag } from './BoxTag';
import { IconTag } from './IconTag';
import { resolveIcon } from '../../../util/functions';
import { TextBindings } from '../../../compiler/enums';
import { useBinding, useTheme } from '../../../common/utils/common';

type TagFiltersProps = {
  onUpdated: Function,
  onClose: Function,
  visible: boolean,
  filters: Array<string>,
};

export const compare = (a, b, l) => {
  const titleA = a?.Title[l]?.toUpperCase();
  const titleB = b?.Title[l]?.toUpperCase();

  let comparison = 0;
  if (titleA > titleB) {
    comparison = 1;
  } else if (titleA < titleB) {
    comparison = -1;
  }
  return comparison;
};

export const TagFilters: FC<TagFiltersProps> = ({ onUpdated, onClose, visible, filters = [] }) => {
  const c = useActiveCountry();
  const { ct, l } = useCountryTranslation(c?.Code.iv);
  const [form] = Form.useForm();
  const { tags } = useLocationSearchContext();
  const theme = useTheme();

  const filteredTags = tags.filter(tc => tc.SupportedCountries.iv.some(sc => sc?.Data?.Code.toString() === c?.Code.iv));
  const supportTags = filteredTags?.filter(t => t.Type.iv === 'Type of Support').sort((a, b) => compare(a, b, l));
  const locationTags = filteredTags?.filter(t => t.Type.iv === 'Location').sort((a, b) => compare(a, b, l));

  // Setting the initial field values on load
  let fieldsValue = {};
  if (filteredTags != null && filteredTags?.length > 0) {
    for (var index in filters) {
      fieldsValue[filters[index]] = true;
    }
  }
  form.setFieldsValue(fieldsValue);

  const handleFilter = () => {
    const formFields = form.getFieldsValue();
    let selectedTags: Array<string> = [];

    for (var key of Object.keys(formFields)) {
      const val = formFields[key];
      if (val === true) {
        selectedTags.push(key);
      }
    }

    onUpdated(selectedTags);
    onClose();
  };

  const filterResultsTitle = useBinding(`${TextBindings.TextFilterResults}.Text`);
  const typesOfSupportLabel = useBinding(`${TextBindings.LabelTypesOfSupport}.Text`);
  const locationFiltersLabel = useBinding(`${TextBindings.LabelLocationFilters}.Text`);

  return (
    <DefaultModal 
      visible={visible} 
      title={filterResultsTitle} 
      onCancel={() => onClose()} 
      onOk={handleFilter}
      className={theme + " filter-service-results-modal"}
      footer={[
        <BoundButton 
          key="back"
          type="default" 
          onClick={() => onClose()}
          binding={TextBindings.LabelCancel}
        />,
        <BoundButton 
          key="submit"
          type="primary" 
          onClick={() => handleFilter()}
          binding={TextBindings.ButtonTextApply}
        />,
      ]}
    >
      <Form form={form} layout="vertical">
        <div className="support-filters">
          <h4>{typesOfSupportLabel}</h4>
          <div className="support-filter-list">
            { supportTags.map((t, i) => {
              return (
                <Form.Item name={t.Id} valuePropName="checked">
                  <Checkbox key={i.toString()}>
                    <BoxTag key={i.toString()} text={t.Title[l]} color={t.ColorCode?.iv} borderColor={t.BorderColorCode?.iv} />
                  </Checkbox>
                </Form.Item>
              );
            })}
          </div>
        </div>
        <div className="location-filters">
          <h4>{locationFiltersLabel}</h4>
          <div className="location-filter-list">
            { locationTags.map((t, i) => {
              return (
                <Form.Item name={t.Id} valuePropName="checked">
                  <Checkbox key={i.toString()}>
                    <IconTag key={i} icon={resolveIcon(t.Icon?.iv)} text={t.Title[l]} />
                  </Checkbox>
                </Form.Item>
              );
            })}
          </div>
        </div>
      </Form>
    </DefaultModal>
  );
};
