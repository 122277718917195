import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import useActiveCountry from '../hooks/useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';
import { AssetDTO } from '../../compiler/types';
import useConfig from '../../config/hooks/useConfig';
import assetService from '../../services/asset-service';
import { Skeleton } from 'antd';

type DefaultImageProps = {
  assetId?: string,
  asset?: AssetDTO,
  height?: number | string,
  width?: number | string,
  skeletonWidth?: number | string, // used to set width of skeleton when defining width of image will mess up formatting
  skeletonHeight?: number | string, // used to set height of skeleton when defining height of image will mess up formatting
  style?: CSSProperties,
  className?: string,
  id?: string,
  alt?: string, // to silence accessibility errors for now, TODO: remove after dev
  src?: string, // to allow placeholders through for now, TODO: remove
};

export const DefaultImage: FC<DefaultImageProps> = ({
  assetId, asset, alt, src, width, skeletonWidth, height, skeletonHeight, style, className, id 
}) => {
  const c = useActiveCountry();
  const { l } = useCountryTranslation(c?.Code.iv);
  const config = useConfig();
  const [assetObj, setAssetObj] = useState<AssetDTO | null>(asset ?? null);
  const [loading, setLoading] = useState<boolean>(true);

  const mediaSrc = useMemo(() => {
    if (src) {
      return src;
    }

    if (asset && asset.id) {
      return `${config?.assetUrl}${asset.id}`;
    } else if (assetId) {
      return `${config?.assetUrl}${assetId}`;
    }
    return null;
  }, [asset, assetId, config?.assetUrl, src]);

  useEffect(() => {
    if (!asset && assetId) {
      assetService.getAsset(assetId)
        .then((data) => {
          setAssetObj(data);
        })
        .catch((err) => { console.warn("Error loading asset:", assetId, "--", err) });
    }
  }, [assetId, asset]);


  if (!mediaSrc) {
    return null;
  }

  return (
    <div 
      style={{ 
        height: skeletonHeight ?? height,
        width: skeletonWidth ?? width, 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center" 
      }}
    >
      { loading && (
        <Skeleton.Image 
          className={className} 
          active={true} 
          style={{ height: skeletonHeight ?? height, width: skeletonWidth ?? width }}
        />
      )}
      <img 
        id={id}
        className={className}
        src={mediaSrc} 
        alt={assetObj?.metadata[`alt-${l}`] ?? alt} 
        onLoad={() => setLoading(false)}
        style={{ display: loading ? "none" : "flex", width: width, height: height, ...style }}
      />
    </div>
  );
};

export default DefaultImage;
