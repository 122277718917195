import { FC } from 'react';
import { useIsMobile } from '../utils/common';
import { ShortcutCard } from './ShortcutCard';
import { Carousel } from 'antd';
import { NavCardProps } from './NavCard';

export type ShortcutCardGroupProps = {
  shortcutCards: Array<NavCardProps>,
};

export const ShortcutCardGroup: FC<ShortcutCardGroupProps> = ({ shortcutCards }) => {

  const isMobile = useIsMobile();

  const cardElements = shortcutCards && shortcutCards.map((shortcutCard: NavCardProps, index: number) => {
    return <ShortcutCard key={index} {...shortcutCard} />
  });

  return (
    <div className="shortcut-cards">
       { isMobile && (
        <Carousel arrows dotPosition={"bottom"} draggable infinite>
          {cardElements}
        </Carousel>
      )}
       { !isMobile && (
        <>
          {cardElements}
        </>
      )}
    </div>
  );
};
