import { FC } from "react";
import DefaultBanner from "../DefaultBanner";
import { Links, TextBindings } from "../../../compiler/enums";
import { BoundButton } from "../BoundButton";
import { useNavigate } from "react-router-dom";
import { scrollToTop, useBinding, useLink } from "../../utils/common";

import "./ExploreFurtherResources.css";
import { decode } from "html-entities";
import { Row, Space } from "antd";
import { FurtherResourceButton } from "./FurtherResourceButton";
import { FurtherResource } from "../../../compiler/types";

export type ExploreFurtherResourcesComponentProps = {
  furtherResources?: Array<FurtherResource>,
};

export const ExploreFurtherResources: FC<ExploreFurtherResourcesComponentProps> = ({ furtherResources }) => {
  const navigate = useNavigate();
  const ctaLink = useLink(Links.SupportServices);
  const exploreFurtherResourcesTitle = useBinding(TextBindings.TextExploreFurtherResources);

  return (
    <div className="explore-further-resources">
      <DefaultBanner 
        className="find-support"
        text={"Find support services near you or explore national helplines for wider support."}
        binding={TextBindings.BannerFindSupport}
        ctaButton={
          <BoundButton
            onClick={() => {
              navigate(ctaLink);
              scrollToTop();
            }}
            binding={TextBindings.ButtonLocateSupport}
          />
        }
      />
      <div className="further-resources-link-container">
        <Row>
          <h1>{exploreFurtherResourcesTitle}</h1>
        </Row>
        { furtherResources && furtherResources.length > 0 && (
          <Row className="resource-buttons">
            <Space size="large">
              { furtherResources.map((furtherResource: FurtherResource, index: number) => {
                return (
                  <FurtherResourceButton key={index} furtherResource={furtherResource} />
                );
              })}
            </Space>
          </Row>
        )}
      </div>
    </div>
  );
};