import { HeroCardProps } from "../common/components/HeroCard";
import { NavCardProps } from "../common/components/NavCard";

export enum GuideTemplate {
  TemplateOne = 1,
  TemplateTwo = 2,
}

export enum PageLinks {
  Feedback = "/feedback",
  About = "/about",
  PrivacyPolicy = "/privacy-policy",
  Journal = "/my-journal",
  Resources = "/resources",

  // Services
  NationalHelplines = "/national-helplines",
  SupportServices = "/find-support",
}

export enum GuideLinks {
  OnlineSafety = "/online-safety-guide",
  FormsOfAbuse = "/forms-of-abuse",
  SexualConsent = "/sexual-consent",
  Stalking = "/stalking",
  Leaving = "/leaving-an-abusive-relationship",
  SafetyGuidance = "/safety-guidance",
  HowCanIHelp = "/how-can-i-help",
  TypesOfSupport = "/types-of-support",
  EmployersPage = "/how-employers-can-help",
  AdditionalGuide = "/additional-guide",
}

export enum QuestionnaireLinks {
  AmIAtRisk = "/am-i-at-risk",
  FriendFamilyAtRisk = "/friend-or-family-at-risk",
  DispellingMyths = "/dispelling-myths",
}

export enum GeneralLinks {
  Home = "/home",
  NotFound = "/not-found",
  
  // Root Nav Hubs
  RecogniseAbuse = "/recognise-abuse",
  UnderstandAbuse = "/understand-abuse",
  SeekingSupport = "/seeking-support",
  ProvidingSupport = "/providing-support",

  // Site Search Results Page
  SearchResults = "/search-results"
};

export const Links = {
  ...PageLinks,
  ...GuideLinks,
  ...QuestionnaireLinks,
  ...GeneralLinks,
};

export type LinksUnion =
  | PageLinks
  | GuideLinks
  | QuestionnaireLinks
  | GeneralLinks;

// classnames of all themes as const for standardization
export enum Themes {
  Plain = "plain-theme",
  Blue = "blue-theme",
  Purple = "purple-theme",
  Green = "green-theme",
  Dark = "dark-theme",
};

export enum AccordionBindings {
  UsingBrightSky = "Accordion_UsingBrightSky",
};

export enum PageBindings {
  PrivacyPolicy = "Page_Web_PrivacyPolicy",
  About = "Page_About",
  Helplines = "Page_Helplines",
  Journal = "Page_Journal",
  LocateSupport = "Page_Web_LocateSupport",
  LocateSupportResults = "Page_LocateSupportResults",
  Resources = "Page_Resources",
  UpdateDetailsModal = "Page_UpdateDetails_Modal",
  UpdateDetailsDone = "Page_UpdateDetails_Done",
  Feedback = "Page_Web_Feedback",
  RecogniseAbuse = "Page_Web_RecogniseAbuse",
  UnderstandAbuse = "Page_Web_UnderstandAbuse",
  SeekingSupport = "Page_Web_SeekingSupport",
  ProvidingSupport = "Page_Web_ProvidingSupport",
  SafetyGuidance = "Page_Web_SafetyGuidance",
  BrowseSafelyOnline = "Page_Web_BrowseSafelyOnline",
};

export enum QuestionnaireBindings {
  AmIAtRisk = "Questionnaire_AmIAtRisk",
  FriendFamilyAtRisk = "Questionnaire_FriendFamilyAtRisk",
  DispellingMyths = "Questionnaire_DispellingMyths",
};

export enum GuideBindings {
  OnlineSafety = "Guide_OnlineSafety",
  FormsOfAbuse = "Guide_FormsOfAbuse",
  Additional = "Guide_Additional",
  HowCanIHelp = "Guide_HowCanIHelp",
  Leaving = "Guide_Leaving",
  SexualConsent = "Guide_SexualConsent",
  StalkingHarassment = "Guide_StalkingHarassment",
  TypesOfSupport = "Guide_TypesOfSupport",
  SafetyGuidance = "Guide_Web_SafetyGuidance",
  EmployersPage = "Guide_Web_HowCanEmployersHelp",
};

export enum ResourceBindings {
  Links = "Links_Resources"
}

export enum ListBindings {
  DropdownAgeRange = "Dropdown_AgeRange",
  DropdownEthnicityArabian = "Dropdown_Ethnicity_Arabian",
  DropdownEthnicityAsian = "Dropdown_Ethnicity_Asian",
  DropdownEthnicityBlack = "Dropdown_Ethnicity_Black",
  DropdownEthnicityGeneral = "Dropdown_Ethnicity_General",
  DropdownEthnicityMixed = "Dropdown_Ethnicity_Mixed",
  DropdownEthnicityWhite = "Dropdown_Ethnicity_White",
  DropdownGender = "Dropdown_Gender",
}

export enum TextBindings {
  // web banners
  BannerEmergency = "Web_Banner_Emergency",
  BannerFindSupport = "Web_Banner_FindSupport",
  
  BrowsingThisSiteSafelyPoint1 = "Web_BrowsingThisSiteSafely_Point1",
  BrowsingThisSiteSafelyPoint2 = "Web_BrowsingThisSiteSafely_Point2",
  BrowsingThisSiteSafelyPoint3 = "Web_BrowsingThisSiteSafely_Point3",
  BrowsingThisSiteSafelyTitle = "Web_BrowsingThisSiteSafely_Title",

  ButtonCall = "Button_Call",
  ButtonDone = "Button_Done",
  ButtonGetStarted = "Button_GetStarted",
  ButtonLocateSupport = "Button_LocateSupport",
  ButtonSend = "Button_Send",
  ButtonStartOver = "Button_StartOver",
  ButtonSubmitEntry = "Button_SubmitEntry",
  ButtonSubmitFeedback = "Button_SubmitFeedback",
  ButtonTextApply = "Button_Text_Apply",
  ButtonVisitMyJournal = "Button_VisitMyJournal",

  // web buttons
  ButtonAllFormsOfAbuse = "Web_Button_AllFormsOfAbuse",
  ButtonAmIAtRisk = "Web_Button_AmIAtRisk",
  ButtonBackToTop = "Web_Button_BackToTop",
  ButtonExitSite = "Web_Button_ExitSite",
  ButtonGoToOnlineSafety = "Web_Button_GoToOnlineSafety",
  ButtonGoToWebsite = "Web_Button_GoToWebsite",
  ButtonReturnToWebsite = "Web_Button_ReturnToWebsite",
  ButtonSubmit = "Web_Button_Submit",
  ButtonWriteJournal = "Web_Button_WriteJournal",
  ButtonIUnderstand = "Web_Button_IUnderstand",
  ButtonVisitSite = "Web_Button_VisitSite",
  
  ErrorAddOrUpdateDetailsRequired = "Error_AddOrUpdateDetails_Required",
  ErrorConsentRequired = "Error_Consent_Required",
  ErrorEmailRequired = "Error_EmailRequired",
  ErrorFeedbackFailed = "Error_FeedbackFailed",
  ErrorFormInvalid = "Error_FormInvalid",
  ErrorHowFeelRequired = "Error_HowFeelRequired",
  ErrorInvalidEmail = "Error_InvalidEmail",
  ErrorInvalidWebsite = "Error_InvalidWebsite",
  ErrorJournalSubmitFailed = "Error_JournalSubmitFailed",
  ErrorMessageRequired = "Error_MessageRequired",
  ErrorNameRequired = "Error_Name_Required",
  ErrorNoEmail = "Error_NoEmail",
  ErrorPhoneRequired = "Error_Phone_Required",
  ErrorPhoneOrEmailRequired = "Error_PhoneOrEmail_Required",
  ErrorRequestorNameRequired = "Error_RequestorName_Required",
  ErrorWhatHappenedRequired = "Error_WhatHappenedRequired",
  ErrorWorkForLocationRequired = "Error_WorkForLocation_Required",

  FeedbackContent1 = "Web_Feedback_Content_1",
  FeedbackContent2 = "Web_Feedback_Content_2",
  FeedbackContent3 = "Web_Feedback_Content_3",

  FieldLabelCurrentLocation = "Field_Label_CurrentLocation",
  FieldLabelLanguage = "Field_Label_Language",
  FieldLabelUseLocation = "Field_Label_UseLocation",

  // web header
  HeaderHomeDomesticAbuseFault = "Web_Header_Home_DomesticAbuseFault",

  LabelAddress = "Label_Address",
  LabelAttachAPhotoOrVideo = "Label_AttachAPhotoOrVideo",
  LabelAttached = "Label_Attached",
  LabelBackToFreeText = "Label_BackToFreeText",
  LabelCancel = "Label_Cancel",
  LabelChooseAgeRange = "Label_ChooseAgeRange",
  LabelChooseEthnicity = "Label_ChooseEthnicity",
  LabelChooseGender = "Label_ChooseGender",
  LabelDescription = "Label_Description",
  LabelEmail = "Label_Email",
  LabelEnterLocation = "Label_EnterLocation",
  LabelHours = "Label_Hours",
  LabelHowDidItMakeYouFeel = "Label_HowDidItMakeYouFeel",
  LabelIncidentDate = "Label_IncidentDate",
  LabelJournalPrompt = "Label_JournalPrompt",
  LabelLastVerified = "Label_LastVerified",
  LabelLearnMore = "Label_LearnMore",
  LabelLocationFilters = "Label_LocationFilters",
  LabelName = "Label_Name",
  LabelNationalHelpline = "Label_NationalHelpLine",
  LabelPhone = "Label_Phone",
  LabelSecureEmail = "Label_SecureEmail",
  LabelShowingResultsFor = "Label_ShowingResultsFor",
  LabelSupport = "Label_Support",
  LabelTags = "Label_Tags",
  LabelTypeAMessage = "Label_TypeAMessage",
  LabelTypesOfSupport = "Label_TypesOfSupport",
  LabelUpdateResults = "Label_UpdateResults",
  LabelWebsite = "Label_Website",
  LabelWhatHappened = "Label_WhatHappened",
  LabelUnderstandThisQuestion = "Text_Label_UnderstandThisQuestion",

  // web labels
  LabelSelectCountry = "Web_Label_SelectCountry",
  LabelSelectLanguage = "Web_Label_SelectLanguage",

  LinkCallEmergencyServices = "Link_CallEmergencyServices",

  // web links
  LinkAbout = "Web_Link_About",
  LinkFeedback = "Web_Link_Feedback",
  LinkPrivacyPolicy = "Web_Link_PrivacyPolicy",

  // web modal
  ModalExitSiteSafelyTitle = "Web_Modal_ExitSiteSafely_Title",
  ModalExitSiteSafelyText = "Web_Modal_ExitSiteSafely_Text",

  // web nav
  NavIsItAbuse = "Web_Nav_IsItAbuse",

  NavAbuseAdviceFamilyOrFriend = "Nav_AbuseAdvice_FamilyOrFriend",
  NavAbuseAdviceHelplines = "Nav_AbuseAdvice_Helplines",
  NavAbuseAdviceLeaving = "Nav_AbuseAdvice_Leaving",
  NavAbuseAdviceTypesOfSupport = "Nav_AbuseAdvice_TypesOfSupport",

  NavHomeAbuseAdvice = "Nav_Home_AbuseAdvice",
  NavHomeFormsOfAbuse = "Nav_Home_FormsOfAbuse",
  NavHomeIsItAbuse = "Nav_Home_IsItAbuse",
  NavHomeOnlineSafety = "Nav_Home_Web_OnlineSafety",
  NavHomeStories = "Nav_Home_Stories",
  
  NavStoriesSexualConsent = "Nav_Stories_SexualConsent",
  NavStoriesStalkingHarassment = "Nav_Stories_StalkingAndHarassment",

  // widget nav
  NavTakeSurvey = "Nav_Widget_TakeSurvey",

  NavTabCall = "Nav_Tab_Call",
  NavTabHome = "Nav_Tab_Home",
  NavTabJournal = "Nav_Tab_Journal",

  // web page title
  PageTitleAbout = "Web_Page_Title_About",
  PageTitleEducationResources = "Web_Page_Title_EducationResources",
  PageTitleFeedback = "Web_Page_Title_Feedback",
  PageTitlePrivacyPolicy = "Web_Page_Title_PrivacyPolicy",

  PlaceholderHowDidItMakeYouFeel = "Placeholder_HowDidItMakeYouFeel",
  PlaceholderSearchByLocation = "Placeholder_SearchByLocation",
  PlaceholderSecureEmail = "Placeholder_SecureEmail",
  PlaceholderTypeAMessage = "Placeholder_TypeAMessage",
  PlaceholderWhatHappened = "Placeholder_WhatHappened",

  // web placeholders
  PlaceholderSiteSearch = "Placeholder_Web_SiteSearch",

  // web shortcut text
  ShortcutAbuseAdviceLeavingIntro = "Web_Shortcut_AbuseAdvice_Leaving_Intro",
  ShortcutAdditionalGuideIntro = "Web_Shortcut_AdditionalGuide_Intro",
  ShortcutEmployersPageIntro = "Web_Shortcut_EmployersPage_Intro",
  ShortcutFormsOfAbuseIntro = "Web_Shortcut_FormsOfAbuse_Intro",
  ShortcutHowCanIHelpIntro = "Web_Shortcut_HowCanIHelp_Intro",
  ShortcutOnlineSafetyIntro = "Web_Shortcut_OnlineSafety_Intro",
  ShortcutResourcesIntro = "Web_Shortcut_Resources_Intro",
  ShortcutSexualConsentIntro = "Web_Shortcut_SexualConsent_Intro",
  ShortcutStalkingAndHarassmentIntro = "Web_Shortcut_StalkingAndHarassment_Intro",
  ShortcutDispellingMythsIntro = "Web_Shortcut_DispellingMyths_Intro",
  ShortcutTypesOfSupportIntro = "Web_Shortcut_TypesOfSupport_Intro",
  ShortcutJournalIntro = "Web_Shortcut_Journal_Intro",
  ShortcutAmIAtRiskIntro = "Web_Shortcut_AmIAtRisk_Intro",
  ShortcutFindSupportIntro = "Web_Shortcut_FindSupport_Intro",
  ShortcutFriendOrFamilyAtRiskIntro = "Web_Shortcut_FriendOrFamilyAtRisk_Intro",
  ShortcutHelplinesIntro = "Web_Shortcut_Helplines_Intro",
  ShortcutsOverviewTitle = "Web_Shortcuts_Overview_Title",

  SuccessSubmittedFeedback = "Success_SubmittedFeedback",

  TextBack = "Text_Back",
  TextBrowser = "Text_Browser",
  TextChangeLocation = "Text_ChangeLocation",
  TextClearFilters = "Text_ClearFilters",
  TextComplete = "Text_Complete",
  TextCurrentLocation = "Text_CurrentLocation",
  TextDefaultResults = "Text_DefaultResults",
  TextExit = "Text_Exit",
  TextExploreFurtherResources = "Text_ExploreFurtherResources",
  TextFeedbackEaseOfUse = "Text_Feedback_EaseOfUse",
  TextFeedbackHelpful = "Text_Feedback_Helpful",
  TextFeedbackUseAgain = "Text_Feedback_UseAgain",
  TextFeedbackSubmitSuccess = "Text_FeedbackSubmitSuccess",
  TextFilterResults = "Text_FilterResults",
  TextGotIt = "Text_GotIt",
  TextJournalSubmitSuccess = "Text_JournalSubmitSuccess",
  TextKilometersAway = "Text_KilometersAway",
  TextUnderstandThisQuestion = "Text_Label_UnderstandThisQuestion",
  TextLearnMoreAboutEach = "Text_LearnMoreAboutEach",
  TextNext = "Text_Next",
  TextNotFound = "Text_NotFound",
  TextNo = "Text_No",
  TextNoLocationSelected = "Text_NoLocationSelected",
  TextNoResults = "Text_NoResults",
  TextOf = "Text_Of",
  TextOff = "Text_Off",
  TextOn = "Text_On",
  TextOr = "Text_Or",
  TextPhoto = "Text_Photo",
  TextPrevious = "Text_Previous",
  TextResults = "Text_Results",
  TextSearch = "Text_Search",
  TextSearchResults = "Text_SearchResults",
  TextSelectOption = "Text_SelectOption",
  TextShowLess = "Text_ShowLess",
  TextShowMore = "Text_ShowMore",
  TextVideo = "Text_Video",
  TextViewDetails = "Text_ViewDetails",
  TextWhatIsHappening = "Text_WhatIsHappening",
  TextYes = "Text_Yes",

  // web text
  TextFeedback = "Web_Text_Feedback",
  TextMoreOn = "Web_Text_MoreOn",
  TextOpenNewTab = "Web_Text_OpenNewTab",
  TextStartSimple = "Web_Text_StartSimple",

  TextUpdateDetailsAddUpdate = "Text_UpdateDetails_AddUpdate",
  TextUpdateDetailsConsent = "Text_UpdateDetails_Consent",
  TextUpdateDetailsHelplinesButton = "Text_UpdateDetails_Helplines_Button",
  TextUpdateDetailsHelplinesText = "Text_UpdateDetails_Helplines_Text",
  TextUpdateDetailsRadioAdd = "Text_UpdateDetails_RadioAdd",
  TextUpdateDetailsRadioUpdate = "Text_UpdateDetails_RadioUpdate",
  TextUpdateDetailsSupportButton = "Text_UpdateDetails_Support_Button",
  TextUpdateDetailsSupportText = "Text_UpdateDetails_Support_Text",
  TextUpdateDetailsWarning = "Text_UpdateDetails_Warning",
  TextUpdateDetailsWorkLocation = "Text_UpdateDetails_WorkLocation",
  TextUpdateDetailsYourEmail = "Text_UpdateDetails_YourEmail",
  TextUpdateDetailsYourName = "Text_UpdateDetails_YourName",
  TextUpdateDetailsYourPhone = "Text_UpdateDetails_YourPhone",

  TitleAbuseAdvice = "Title_AbuseAdvice",
  TitleSummary = "Title_Summary",
  TitleUsingBrightSky = "Title_UsingBrightSky",
  TitleYouAreNotAlone = "Title_YouAreNotAlone",

  // web titles
  TitleOnlineSafety = "Web_Title_OnlineSafety",

  TooltipJournalEntry = "Tooltip_JournalEntry",
}

export const AllBindings = {
  ...AccordionBindings, 
  ...GuideBindings,
  ...ListBindings,
  ...PageBindings,
  ...QuestionnaireBindings,
  ...ResourceBindings,
  ...TextBindings,
};

export type Bindings = typeof AllBindings;

export type BindingsUnion =
  AccordionBindings
  | GuideBindings
  | ListBindings
  | PageBindings
  | QuestionnaireBindings
  | ResourceBindings
  | TextBindings;

export type SearchableLinks = 
  | PageLinks
  | QuestionnaireLinks
  | GuideLinks;

export type SearchableBindings = 
  | PageBindings
  | QuestionnaireBindings
  | GuideBindings;

export const SearchableSiteMap: Record<SearchableLinks, SearchableBindings> = {
  [QuestionnaireLinks.AmIAtRisk]: QuestionnaireBindings.AmIAtRisk,
  [QuestionnaireLinks.FriendFamilyAtRisk]: QuestionnaireBindings.FriendFamilyAtRisk,
  [QuestionnaireLinks.DispellingMyths]: QuestionnaireBindings.DispellingMyths,

  [PageLinks.About]: PageBindings.About,
  [PageLinks.PrivacyPolicy]: PageBindings.PrivacyPolicy,
  [PageLinks.Feedback]: PageBindings.Feedback,
  [PageLinks.Journal]: PageBindings.Journal,
  [PageLinks.Resources]: PageBindings.Resources,
  [PageLinks.NationalHelplines]: PageBindings.Helplines,
  [PageLinks.SupportServices]: PageBindings.LocateSupport,

  [GuideLinks.EmployersPage]: GuideBindings.EmployersPage,
  [GuideLinks.OnlineSafety]: GuideBindings.OnlineSafety,
  [GuideLinks.Stalking]: GuideBindings.StalkingHarassment,
  [GuideLinks.SexualConsent]: GuideBindings.SexualConsent,
  [GuideLinks.Leaving]: GuideBindings.Leaving,
  [GuideLinks.FormsOfAbuse]: GuideBindings.FormsOfAbuse,
  [GuideLinks.SafetyGuidance]: GuideBindings.SafetyGuidance,
  [GuideLinks.HowCanIHelp]: GuideBindings.HowCanIHelp,
  [GuideLinks.TypesOfSupport]: GuideBindings.TypesOfSupport,
  [GuideLinks.AdditionalGuide]: GuideBindings.Additional,
};

//export type SiteMapType = {
//  mappings: Record<LinksUnion, BindingsUnion>;
//};

//export const SiteMap: SiteMapType = {
//  mappings: {
//    ...SearchableSiteMap,
//    [.AmIAtRisk]: QuestionnaireBindings.AmIAtRisk,
//    [QuestionnaireLinks.FriendFamilyAtRisk]: QuestionnaireBindings.FriendFamilyAtRisk,
//    [QuestionnaireLinks.DispellingMyths]: QuestionnaireBindings.DispellingMyths,
//  },
//};

export type FurtherResourceLink = "AmIAtRisk" | "FriendOrFamilyAtRisk" | "UnderstandingAbuse" | "ProvidingSupport" 
  | "RecogniseAbuse" | "SeekingSupport" | "FindHelp" | "TypesOfSupport" | "FormsOfAbuse" | "Home";

// defines the links (& optionally bindings) to use for further resources
export const FurtherResourceMapping: Record<FurtherResourceLink, { link: LinksUnion, binding?: TextBindings | string }> = {
  AmIAtRisk: {
    link: Links.AmIAtRisk
  },
  FriendOrFamilyAtRisk: {
    link: Links.FriendFamilyAtRisk
  },
  UnderstandingAbuse: {
    link: Links.UnderstandAbuse,
  },
  ProvidingSupport: {
    link: Links.ProvidingSupport,
  },
  RecogniseAbuse: {
    link: Links.RecogniseAbuse,
  },
  SeekingSupport: {
    link: Links.SeekingSupport,
  },
  FindHelp: {
    link: Links.SupportServices,
  },
  TypesOfSupport: {
    link: Links.TypesOfSupport,
  },  
  FormsOfAbuse: {
    link: Links.FormsOfAbuse,
  },
  Home: {
    link: Links.Home,
    binding: TextBindings.NavTabHome,
  },
}

export type NavCardKeys = "FormsOfAbuse" | "AmIAtRisk" | "HowCanIHelp" | "OnlineSafety"
  | "SexualConsent" | "StalkingHarassment" | "DispellingMyths" | "FriendOrFamilyAtRisk"
  | "TypesOfSupport" | "Resources" | "Leaving" | "AdditionalGuide" | "SupportServices"
  | "NationalHelplines" | "EmployersPage";
  //| "StalkingHarassment" | "DispellingMyths" | "Resources" | "Leaving" | "TypesOfSupport" | "AdditionalGuide"
  //| "HowCanIHelp" | "EmployersPage" | "SupportServices" | "NationalHelplines";

export const NavCards: Record<NavCardKeys, Omit<NavCardProps, "title" | "text">> = {
  FormsOfAbuse: {
    titleBinding: TextBindings.NavHomeFormsOfAbuse,
    textBinding: TextBindings.ShortcutFormsOfAbuseIntro,
    link: Links.FormsOfAbuse,
  },
  AmIAtRisk: {              
    titleBinding: `${QuestionnaireBindings.AmIAtRisk}.Title`, // use title of Am I at Risk guide
    textBinding: TextBindings.ShortcutAmIAtRiskIntro,
    link: Links.AmIAtRisk,
  },  
  FriendOrFamilyAtRisk: {             
    titleBinding: `${QuestionnaireBindings.FriendFamilyAtRisk}.Title`, // use title of Friend or Family questionnaire
    textBinding: TextBindings.ShortcutFriendOrFamilyAtRiskIntro,
    link: Links.FriendFamilyAtRisk,
  },
  HowCanIHelp: {
    titleBinding: TextBindings.NavAbuseAdviceFamilyOrFriend,
    textBinding: TextBindings.ShortcutHowCanIHelpIntro,
    link: Links.HowCanIHelp,
  },
  OnlineSafety: {
    titleBinding: TextBindings.TitleOnlineSafety,
    textBinding: TextBindings.ShortcutOnlineSafetyIntro,
    link: Links.OnlineSafety,
  },
  SexualConsent: {
    titleBinding: TextBindings.NavStoriesSexualConsent,
    textBinding: TextBindings.ShortcutSexualConsentIntro,
    link: Links.SexualConsent,
  },
  StalkingHarassment: {
    titleBinding: TextBindings.NavStoriesStalkingHarassment,
    textBinding: TextBindings.ShortcutStalkingAndHarassmentIntro,
    link: Links.Stalking,
  },
  DispellingMyths: {
    titleBinding: `${QuestionnaireBindings.DispellingMyths}.Title`, // use title of Dispelling Myths questionnaire
    textBinding: TextBindings.ShortcutDispellingMythsIntro,
    link: Links.DispellingMyths,
  },
  TypesOfSupport: {
    titleBinding: TextBindings.LabelTypesOfSupport,
    textBinding: TextBindings.ShortcutTypesOfSupportIntro,
    link: Links.TypesOfSupport,
  },
  Resources: {
    titleBinding: `${PageBindings.Resources}.Title`, // use title of resources page
    textBinding: TextBindings.ShortcutResourcesIntro,
    link: Links.Resources,
  },
  Leaving: {
    titleBinding: TextBindings.NavAbuseAdviceLeaving,
    textBinding: TextBindings.ShortcutAbuseAdviceLeavingIntro,
    link: Links.Leaving,
  },
  AdditionalGuide: {
    titleBinding: `${GuideBindings.Additional}.Title`, // use title of additional guide
    textBinding: TextBindings.ShortcutAdditionalGuideIntro,
    link: Links.AdditionalGuide,
  },
  SupportServices: {
    titleBinding: `${PageBindings.LocateSupport}.Title`, // use title of locate support page
    textBinding: TextBindings.ShortcutFindSupportIntro,
    link: Links.SupportServices,
  },
  NationalHelplines: {
    titleBinding: TextBindings.NavAbuseAdviceHelplines,
    textBinding: TextBindings.ShortcutHelplinesIntro,
    link: Links.NationalHelplines,
  },
  EmployersPage: {
    titleBinding: `${GuideBindings.EmployersPage}.Title`, // use title of employer's guide
    textBinding: TextBindings.ShortcutEmployersPageIntro,
    link: Links.EmployersPage,
  }
};

type HeroCardKeys = "AmIAtRisk" | "FriendOrFamilyAtRisk" | "TypesOfSupport" | "Journal";

export const HeroCards: Record<HeroCardKeys, HeroCardProps> = {
  AmIAtRisk: {
    ...NavCards.AmIAtRisk,
    imageSrc: "https://picsum.photos/400/160",
    alt: "TODO",
    linkBinding: TextBindings.NavTakeSurvey,
  },
  FriendOrFamilyAtRisk: {
    ...NavCards.FriendOrFamilyAtRisk,
    imageSrc: "https://picsum.photos/400/160",
    alt: "TODO",
    linkBinding: TextBindings.NavTakeSurvey,
  },
  Journal: {
    titleBinding: TextBindings.NavTabJournal,
    textBinding: TextBindings.ShortcutJournalIntro,
    link: Links.Journal,
    imageSrc: "https://picsum.photos/400/160",
    alt: "TODO",
    linkBinding: TextBindings.ButtonVisitMyJournal,
    linkText: "Go to Journal",
  },
  TypesOfSupport: {
    ...NavCards.TypesOfSupport,
    imageSrc: "https://picsum.photos/400/160",
    alt: "TODO",
    linkBinding: TextBindings.LabelLearnMore,
  }
};