import useContentContext from '../../common/hooks/useContentContext';
import { StorageKeys } from "../../config/Storage";
import { useMemo, FC } from 'react';
import { DefaultModal } from '../../common/components/DefaultModal';
import { useIsMobile, useIsSmallestMobile, useTheme } from '../../common/utils/common';
import { LocalizedPageContent } from '../../compiler/types';
import { PageBindings, TextBindings } from '../../compiler/enums';
import { decode } from 'html-entities';
import { Col, Row } from 'antd';
import { BoundButton } from '../../common/components';

import "./BrowseSafelyModal.css";

export const BrowseSafelyModal: FC<{ visible?: boolean, className?: string, onCancel: () => void }> = ({
  visible = true, onCancel
}) => {
  const theme = useTheme();
  const { getContent } = useContentContext();
  const isMobile = useIsMobile();
  const isSmallestMobile = useIsSmallestMobile();

  const browseSafelyPageContent = useMemo((): LocalizedPageContent | undefined => {
    const content: LocalizedPageContent | null = getContent(StorageKeys.PageKey, PageBindings.BrowseSafelyOnline);
    return content ?? undefined;
  }, [getContent]);

  if (!browseSafelyPageContent) {
    return null;
  }

  const footer = (
    <Row>
      <Col style={{ textAlign: 'right' }} span={24}>
        <BoundButton 
          onClick={() => onCancel()} 
          binding={TextBindings.ButtonDone}
        />
      </Col>
    </Row>
  );

  return (
    <DefaultModal
      visible={visible}
      width={900}
      closable={true}
      maskClosable={true}
      className={"browse-safely-modal " + theme + (isMobile ? " mobile" : "")}
      styles={{
        mask: { top: isSmallestMobile ? "110px" : (isMobile ? "70px" : "140px") },
        content: { top: "50px" }
      }}
      wrapClassName={"browse-safely-modal-wrapper" + (isMobile ? " mobile" : "")}
      title={browseSafelyPageContent.Title}
      onCancel={() => onCancel()}
      footer={footer}
    >
      { browseSafelyPageContent.Content && (
        <div className="lato" dangerouslySetInnerHTML={{
            __html: decode(browseSafelyPageContent?.Content)
          }}
        />
      )}
    </DefaultModal>
  );
};

export default BrowseSafelyModal;