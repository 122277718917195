import { AssetDTO, AssetsDTO } from "../compiler/types";
import api from "../util/api";

const getAsset = (id: string): Promise<AssetDTO> => {
  return api().get(`Asset/Get${id}`)
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

const getAssets = (ids: Array<string>): Promise<AssetsDTO> => {
  return api().post(`Asset/GetAll`, ids)
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

const assetService = {
  getAsset,
  getAssets,
};

export default assetService;