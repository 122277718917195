import { FC } from 'react';
import { Spin } from 'antd';
import { useIsMobile, useTheme } from '../utils/common';
import { Page } from './page-component/Page';

type LoadingProps = {
  className?: string;
  fullscreen?: boolean;
  page?: boolean;
};

export const Loading: FC<LoadingProps> = ({ className, fullscreen, page }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const loadingElement = (
    <div 
      style={{ 
        width: fullscreen && !isMobile ? '100vw' : '100%', 
        height: fullscreen && !isMobile ? '100vh' : '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}
      className={className + theme + " loading-wrapper"}
    >
      <Spin size="large" />
    </div>
  );

  if (page) {
    return (
      <Page>
        { loadingElement }
      </Page>
    );
  }

  return loadingElement;
};
