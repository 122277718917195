import { ConfigProvider, DatePicker } from 'antd';
import locale_ar from 'antd/locale/ar_EG';
import locale_cs from 'antd/locale/cs_CZ';
import locale_fr from 'antd/locale/fr_FR';
import locale_en from 'antd/locale/en_US';
import locale_es from 'antd/locale/es_ES';
import locale_hu from 'antd/locale/hu_HU';
import locale_it from 'antd/locale/it_IT';
import locale_pl from 'antd/locale/pl_PL';
import locale_pt from 'antd/locale/pt_PT';
import locale_ro from 'antd/locale/ro_RO';
import locale_ru from 'antd/locale/ru_RU';
import locale_ur from 'antd/locale/ur_PK';
import locale_el from 'antd/locale/el_GR';
import locale_sr from 'antd/locale/sr_RS';
import locale_uk from 'antd/locale/uk_UA';
import locale_zh from 'antd/locale/zh_CN';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../hooks/useActiveCountry';
import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

type DefaultDatePickerProps = {
  onChange?: (e: any) => void,
  placeholder?: string,
  value: any,
  defaultValue?: any,
  disabled?: boolean,
  name?: string,
  className?: string,
}

export const DefaultDatePicker: FC<DefaultDatePickerProps> = ({ onChange, value, placeholder, defaultValue, disabled = false, name, className }) => {
  const c = useActiveCountry();
  const { l } = useCountryTranslation(c?.Code.iv);
  const formatDate = c?.Code.iv === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY';

  const configLocale = useMemo(() => {
    // convert to appropriate locale
    switch (l) {
      case "ar":
        dayjs.locale("ar"); return locale_ar;
      case "cs":
        dayjs.locale("cs"); return locale_cs;
      case "cy": 
        dayjs.locale("cy"); break; // no antd support - 8/12/2024
      case "es-ES":
        dayjs.locale("es"); return locale_es;
      case "fr-FR":
        dayjs.locale("fr"); return locale_fr;
      case "hu-HU":
        dayjs.locale("hu"); return locale_hu;
      case "it-IT":
        dayjs.locale("it"); return locale_it;
      case "lb":
        dayjs.locale("lb"); break; // no antd support - 8/12/2024
      case "pa":
        dayjs.locale("pa-in"); break; // no antd support - 8/12/2024
      case "pl-PL":
        dayjs.locale("pl"); return locale_pl;
      case "pt-PT":
        dayjs.locale("pt"); return locale_pt;
      case "ro-RO":
        dayjs.locale("ro"); return locale_ro;
      case "ru":
        dayjs.locale("ru"); return locale_ru;
      case "sq":
        dayjs.locale("sq"); break; // no antd support - 8/12/2024
      case "ur-PK":
        dayjs.locale("ur"); return locale_ur;
      case "el":
        dayjs.locale("el"); return locale_el;
      case "mi-NZ":
        dayjs.locale("mi"); break; // no antd support - 8/12/2024
      case "sr":
        dayjs.locale("sr"); return locale_sr;
      case "uk":
        dayjs.locale("uk"); return locale_uk;
      case "zh":
        dayjs.locale("zh"); return locale_zh;
      case "st-ZA": // no antd/dayjs support - 8/12/2024
      case "zu": // no antd/dayjs support - 8/12/2024
      default:
        dayjs.locale("en"); break;
    }
    return locale_en;
  }, [l]);

  return (
    <ConfigProvider locale={configLocale}>
      <DatePicker
        format={formatDate}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        name={name}
        className={className}
      />
    </ConfigProvider>
  );
};
