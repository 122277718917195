import { faMapMarker as faMapMarkerSolid } from '@fortawesome/pro-solid-svg-icons';
import { faMapMarker as faMapMarkerRegular } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

type MapMarkerProps = {
  text: number,
  lat?: number | null,
  lng?: number | null,
  outline?: boolean;
};

const MapMarker: FC<MapMarkerProps> = ({ text, outline }) => {
  return (
    <div className={`map-marker ${outline ? "outline" : ''}`}>
      <div>
        <span>{text}</span>
      </div>
      <FontAwesomeIcon className="google-map-marker-icon" icon={outline ? faMapMarkerRegular : faMapMarkerSolid} />
    </div>
  );
};

export default MapMarker;
