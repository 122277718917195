import { useEffect, useState } from 'react';
import { Layout, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useCountryTranslation } from 'brightsky-3';
import { Events, Templates } from 'brightsky-3/constants/Logging';
import useActiveCountry from '../../../common/hooks/useActiveCountry';
import { DefaultSelect } from '../../../common/components/DefaultSelect';
import CountrySelect from '../../../common/components/CountrySelect';
import useConfigContext from '../../../common/hooks/useConfigContext';
import useConfig from '../../../config/hooks/useConfig';
import useWebAnalyticsContext from '../../../common/hooks/useWebAnalyticsContext';
import useAppSettingsContext from '../../../common/hooks/useAppSettingsContext';
import { contentExists, isLocalIPConnection } from '../../../util/functions';
import bsLogoWhite from "../../../assets/white-bs-logo.svg";
import androidInstallLogo from "../../../assets/android-install-img.png";
import iOSInstallLogo from "../../../assets/ios-install-img.svg";

import { Language } from '../../../compiler/types';
import { TSkeleton } from '../../components/TranslationSkeleton';
import { useBinding, useIsMobile, useLink } from '../../utils/common';
import { Links, PageBindings, TextBindings } from '../../../compiler/enums';
import DefaultImage from '../../components/DefaultImage';
import i18n from '../../../util/i18n';

import './Footer.css';
import DefaultLink from '../../components/DefaultLink';

const { Footer: AntdFooter } = Layout;

function Footer () {
  const env = useConfig();
  const c = useActiveCountry();
  const { appSettings } = useAppSettingsContext();
  const { languages, language, setLanguage } = useConfigContext();
  const { l } = useCountryTranslation(c?.Code.iv);
  const { logEvent, consumerId } = useWebAnalyticsContext();
  const [selectedCountry, setSelectedCountry] = useState(c);
  const [cachedLanguages, setCachedLanguages] = useState<Language[]>([]);
  const iosInstallLink = appSettings?.iosInstallUrl;
  const androidInstallLink = appSettings?.androidInstallUrl;
  
  const isMobile = useIsMobile();

  useEffect(() => {
    if (languages != null) {
      setCachedLanguages(languages);
    }
  }, [languages]);

  const onCountrySelect = (newCountry) => {
    if (Events?.SettingsChange) {
      logEvent(Events.SettingsChange, 'Footer_Web', Templates.settings.change('country', newCountry));
    }

    // update web redirect
    var workingRedirect = {
      isWebRedirect: newCountry.WebRedirect?.iv ?? false,
      WebRedirectURL: newCountry.RedirectURL?.iv ?? null,
    };

    if (workingRedirect.isWebRedirect && workingRedirect.WebRedirectURL) {
      window.location.href = workingRedirect.WebRedirectURL;
    } else {
      if (env && newCountry && language) {
        const localIPConnection = isLocalIPConnection();
        
        const baseCountryUri = localIPConnection ? env.baseCountryUri.replace('{localDomain}', window.location.hostname) : env.baseCountryUri.replace('{localDomain}', env.localDomain);
        const countryUrl = baseCountryUri.replace('{c}', newCountry.toLowerCase()).replace('{l}', language.value);
        window.location.href = `${countryUrl}/${consumerId}`;
      }
    }
    
    setSelectedCountry(newCountry);
  };

  const onLanguageSelect = l => {
    if (!selectedCountry) {
      return;
    }

    if (Events?.SettingsChange) {
      logEvent(Events.SettingsChange, 'Footer_Web', Templates.settings.change('language', l));
    }

    // changing language should not need to reload the site
    //const countryUrl = config?.baseCountryChangeRedirectUrl.replace('{c}', selectedCountry.Code.iv.toLowerCase()).replace('{l}', l);
    //if (countryUrl) {
    //  window.location.href = countryUrl;
    //}

    i18n.changeLanguage(l);
    setLanguage(l);
  };

  const safetyGuidanceLink = useLink(Links.SafetyGuidance);
  const safetyGuidanceTitleBinding = PageBindings.SafetyGuidance;
  const safetyGuidanceTitle = useBinding(`${safetyGuidanceTitleBinding}.Title`);

  return (
    <AntdFooter className="footer">
      <nav className="menu" aria-label="Footer Navigation">
        <Space className="nav-icons left">
          <DefaultLink className="bs-nav-link" to={`/${l}/home`}>
            <DefaultImage height={isMobile ? 50 : 40} src={bsLogoWhite} alt="white bright sky cloud logo" /> {/* TODO: alt binding */}
          </DefaultLink>
          { iosInstallLink && (
            <a aria-label="Install on iOS" className="ios-install" target="_blank" rel="noreferrer" href={iosInstallLink}>
              <DefaultImage height={40} width={120} src={iOSInstallLogo} alt="iOS App Store logo" /> {/* TODO: alt binding */}
            </a>
          )}
          { androidInstallLink && (
            <a aria-label="Install on Android" className="android-install" target="_blank" rel="noreferrer" href={androidInstallLink}>
              <DefaultImage height={40} width={130} skeletonWidth={120} src={androidInstallLogo} alt="Google Play Android App Store logo" /> {/* TODO: alt binding */}
            </a>
          )}
        </Space>
        <div className="main-menu right">
          <div className="country-select footer-menu">
            <CountrySelect
              variant="borderless"
              ariaLabel="Select Country"
              allowClear={false}
              onChange={onCountrySelect}
              value={c?.Code.iv}
              width={180}
            />
          </div>
          <div className="language-select footer-menu">
            <DefaultSelect
              ariaLabel={'Select Language'}
              variant="borderless"
              allowClear={false}
              onChange={onLanguageSelect}
              items={cachedLanguages}
              value={language}
              width={150}
            />
          </div>
          <TSkeleton>
            <div className="footer-menu nav-link">
              <DefaultLink className="feedback-link" to={useLink(Links.Feedback)}>
                {useBinding(`${TextBindings.LinkFeedback}.Text`)}
              </DefaultLink>
            </div>
            <div className="footer-menu nav-link">
              <DefaultLink className="about-link" to={useLink(Links.About)}>
                {useBinding(`${TextBindings.LinkAbout}.Text`)}
              </DefaultLink>
            </div>
            {contentExists(c, safetyGuidanceTitleBinding, safetyGuidanceTitle) && (
              <div className="footer-menu nav-link">
                <DefaultLink className="safety-guidance-link" to={safetyGuidanceLink}>
                  {safetyGuidanceTitle}
                </DefaultLink>
              </div>
            )}
            <div className="footer-menu nav-link">
              <DefaultLink className="privacy-link" to={useLink(Links.PrivacyPolicy)}>
                {useBinding(`${TextBindings.LinkPrivacyPolicy}.Text`)}
              </DefaultLink>
            </div>
          </TSkeleton>
        </div>
      </nav>
    </AntdFooter>
  );
}

export default Footer;
