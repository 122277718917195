import { useState } from "react";
import { Space, Row } from "antd";
import { Page } from "../../common/components/page-component/Page";
import { BoundButton } from "../../common/components";

import { useBinding, useIsMobile } from "../../common/utils/common";
import { HeroCards, NavCards, PageBindings, TextBindings } from "../../compiler/enums";
import { faShieldCheck } from "@fortawesome/pro-regular-svg-icons";
import { DefaultSimpleList } from "../../common/components/DefaultSimpleList";
import FindSupportSearch from "../../common/components/FindSupportSearch";
import { TSkeleton } from "../../common/components/TranslationSkeleton";
import BrowseSafelyModal from "./BrowseSafelyModal";
import DefaultImage from "../../common/components/DefaultImage";
import { NavCardGroup } from "../../common/components/NavCardGroup";
import { HeroCardGroup } from "../../common/components/HeroCardGroup";
import { ShortcutCardGroup } from "../../common/components/ShortcutCardGroup";

import "./LandingPage.css";

const LandingPage = () => {
  const [browseSafelyVisible, setBrowseSafelyVisible] = useState<boolean>(false);

  const isMobile = useIsMobile();
  const boundLocateSupportContent = useBinding(`${PageBindings.LocateSupport}.Content`);
  return (
    <Page>
      <div id="landing-page">
        <div className="color section header">
          <div className="left">
            <Space className="site-greeting" size="large">
              <TSkeleton siteHeader>
                <h1 className="title">{useBinding(`${TextBindings.HeaderHomeDomesticAbuseFault}.Text`)}</h1>
                { boundLocateSupportContent && (
                  <div className="lato sub-text" 
                    dangerouslySetInnerHTML={{
                      __html: boundLocateSupportContent
                    }}
                  />
                )}
              </TSkeleton>
              <FindSupportSearch noDisabled />
            </Space>
          </div>
          <div className="right">
            <DefaultImage height={isMobile ? 149 : 350} width={isMobile ? 200 : 470} src="https://placehold.co/470x350" alt="TODO"/>
          </div>
        </div>
        <div className="split section">
          <div className="color" />
          <div className="white" />
          <NavCardGroup 
            navCards={[
              {...NavCards.AmIAtRisk},
              {...NavCards.FriendOrFamilyAtRisk},
              {...NavCards.Leaving},
              {...NavCards.NationalHelplines}
            ]} 
          />
        </div>
        <div className="white section">
          <Row className="browse-safely">
            <div className="left">
              <DefaultImage height={250} width={250} src="https://placehold.co/250x250" alt="TODO"/>
            </div>
            <div className="right">
              <DefaultSimpleList
                title={useBinding(`${TextBindings.BrowsingThisSiteSafelyTitle}.Text`)}
                listItems={[
                  useBinding(`${TextBindings.BrowsingThisSiteSafelyPoint1}.Text`),
                  useBinding(`${TextBindings.BrowsingThisSiteSafelyPoint2}.Text`),
                  useBinding(`${TextBindings.BrowsingThisSiteSafelyPoint3}.Text`),
                ]}
                bulletIcon={faShieldCheck}
              />
              <BoundButton 
                type="primary" 
                isCTA={true} 
                text="Learn More" 
                binding={TextBindings.LabelLearnMore} 
                onClick={() => {setBrowseSafelyVisible(prevState => !prevState)}}
              />
              <BrowseSafelyModal visible={browseSafelyVisible} onCancel={() => setBrowseSafelyVisible(false)} />
            </div>
          </Row>
        </div>
        <div className="color section">
          <div className="start-simple">
            <h1>
              {useBinding(`${TextBindings.TextStartSimple}.Text`)}
            </h1>
            <HeroCardGroup 
              unwrap
              heroCards={[
                {...HeroCards.TypesOfSupport},
                {...HeroCards.Journal}
              ]} 
            />
          </div>
        </div>
        <div className="white section">
          <Row justify="center">
            <h1>
              {useBinding(`${TextBindings.ShortcutsOverviewTitle}.Text`)}
            </h1>
          </Row>
          <div className="shortcuts">
            <ShortcutCardGroup 
              shortcutCards={[
                {...NavCards.FormsOfAbuse},
                {...NavCards.HowCanIHelp},
                {...NavCards.OnlineSafety},
                {...NavCards.SexualConsent},
                {...NavCards.StalkingHarassment},
                {...NavCards.DispellingMyths}
              ]} 
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default LandingPage;