import { FC, useMemo } from 'react';
import { Collapse, CollapseProps } from 'antd';
import { Guide } from '../../../compiler/types';
import { Loading } from '../../../common/components/Loading';
import { getMediaUrl, useIsSecondSmallestMobile, useIsVerticalGuideLayout } from '../../../common/utils/common';
import useConfig from '../../../config/hooks/useConfig';
import { decode } from 'html-entities';
import { ShowMoreShowLess } from '../../../common/components/ShowMoreShowLess';
import { GuideTemplate, TextBindings } from '../../../compiler/enums';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import DefaultImage from '../../../common/components/DefaultImage';

export type GuideAccordionProps = {
  guides: Array<Guide> | undefined,
  template: GuideTemplate,
  moreBinding?: TextBindings,
}

export const GuideAccordion: FC<GuideAccordionProps> = ({
  guides, template, moreBinding
}) => {
  const config = useConfig();
  const isVerticalGuideLayout = useIsVerticalGuideLayout();
  const isSecondSmallestMobile = useIsSecondSmallestMobile();

  const items: CollapseProps['items'] = useMemo(() => {
    return guides?.map((guide, index) => {
      const hasLearnMore = guide.LearnMore != null && guide.LearnMore !== '';
      const learnMoreContent = <p dangerouslySetInnerHTML={{ __html: decode(guide.LearnMore) }} />;
      const isSvg = guide.MediaType && guide.MediaType === "SVG";
      const isVideo = guide.MediaType && guide.MediaType === "Video";
      const mediaSrc = getMediaUrl(config?.assetUrl, guide);
      const svgBg = 'brightness(0%)'; // isSvg ? 'brightness(0%)' : '';
      const guideTitle = decode(guide.Title);

      const panelElement = (
        <>
          { guide && (
            <div className="custom-panel">
              { mediaSrc && template === GuideTemplate.TemplateOne && (
                <>
                  {isSvg && guide.SVGData && (
                    <div
                      className="panel-svg"
                      dangerouslySetInnerHTML={{
                        __html: decode(guide.SVGData[0])
                      }}
                    />
                  )}
                  {(!guide.SVGData || guide.SVGData.length === 0) && (
                    <DefaultImage className="panel-img" style={{ filter: isSvg ? svgBg : undefined }} src={mediaSrc} alt={guideTitle} />
                  )}
                </>
              )}
              <span className="lato">{guideTitle}</span>
            </div>
          )}
        </>
      );

      const templateOneContentElement = (
        <div className="template-one-content">
          <div
            className="lato"
            dangerouslySetInnerHTML={{
              __html: decode(guide.Content)
            }}
          />
          { hasLearnMore && (
            <ShowMoreShowLess limit={0} moreBinding={moreBinding}>
              {learnMoreContent}
            </ShowMoreShowLess>
          )}
        </div>
      );

      const templateTwoContentElement = (
        <div className="template-two-content">
          { mediaSrc && (
            <div className={`media-container ${isVideo ? "video" : ""}`}>
              { isVideo && (
                <ReactPlayer 
                  url={mediaSrc} 
                  playIcon={
                    <div className="video-play-mask-container">
                      <video src={mediaSrc}></video>
                      <div className="video-play-mask">
                        <div className="play-icon-container">
                          <FontAwesomeIcon icon={faCircle} className="play-bg" />
                          <FontAwesomeIcon icon={faCirclePlay} className="play-icon" />
                        </div>
                      </div>
                    </div>
                  } 
                  light={true}
                  controls={true} 
                  className="react-player"
                  playing={true}
  	              width='100%'
    	          height='100%'
                />
              )}
              { !isVideo && (
                <DefaultImage 
                  className="content-img" 
                  width={isVerticalGuideLayout && !isSecondSmallestMobile ? '15em' : 140} 
                  //height={isVerticalGuideLayout && !isSecondSmallestMobile ? undefined : 150} 
                  skeletonHeight={isVerticalGuideLayout && !isSecondSmallestMobile ? '18em' : '15em'} 
                  src={mediaSrc} 
                  alt={guideTitle} 
                />
              )}
            </div>
          )}
          <div className="text-container">
            <div
              className="lato"
              dangerouslySetInnerHTML={{
                __html: decode(guide.Content)
              }}
            />
            { hasLearnMore && (
              <ShowMoreShowLess limit={0} moreBinding={moreBinding}>
                {learnMoreContent}
              </ShowMoreShowLess>
            )}
          </div>
        </div>
      );

      var content = template === GuideTemplate.TemplateOne ? templateOneContentElement : (template === GuideTemplate.TemplateTwo ? templateTwoContentElement : null);
      return (
        {
          key: `guide-${guideTitle}-${index}`,
          label: panelElement,
          children: content,
        }
      );
    });
  }, [config?.assetUrl, guides, isVerticalGuideLayout, moreBinding, template, isSecondSmallestMobile]);

  if (!guides) {
    return (<Loading />);
  }

  return (
    <Collapse 
      items={items} 
      defaultActiveKey={['1']}
      expandIconPosition={"end"}
    />
  );
};
