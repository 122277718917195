import { Loader } from './Loader';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsightsPlugin } from './util/appInsights';
import { ConfigContextProvider } from './common/contexts/ConfigContext';
import { ContentContextProvider } from './common/contexts/ContentContext';
import { AppSettingsContextProvider } from './common/contexts/AppSettingsContext';
import { WebAnalyticsContextProvider } from './common/contexts/WebAnalyticsContext';
import { LocationSearchContextProvider } from './features/find-support/contexts/LocationSearchContext';

import './App.css';

function App() {
  return (
    <ConfigContextProvider>
      <ContentContextProvider>
        <AppSettingsContextProvider>
          <WebAnalyticsContextProvider>
            <LocationSearchContextProvider>
              <Loader />
            </LocationSearchContextProvider>
          </WebAnalyticsContextProvider>
        </AppSettingsContextProvider>
      </ContentContextProvider>
    </ConfigContextProvider>   
  );
}

export default withAITracking(appInsightsPlugin, App);