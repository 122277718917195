import { GuideBindings, BindingsUnion, QuestionnaireBindings, ResourceBindings, ListBindings, FurtherResourceLink, PageBindings } from "./enums";

export const HTMLLanguageCodeLiterals = [
  "ar",
  "cs",
  "cy",
  "el",
  "en",
  "es-ES",
  "fr-FR",
  "hu-HU",
  "it-IT",
  "lb",
  "mi-NZ",
  "pl-PL",
  "pt-PT",
  "ro-RO",
  "ru",
  "sm",
  "sq",
  "sr",
  "st-ZA",
  "uk",
  "ur-PK",
  "zh",
  "zu"
];

export type HTMLLanguageCodes = typeof HTMLLanguageCodeLiterals[number];

export interface Language {
  value: string;
  label: string;
}

export interface Country {
  Name: Record<HTMLLanguageCodes, string>,
  Code: {
    iv: string,
  },
  WebEnabled: {
    iv: boolean,
  },
  WebRedirect: {
    iv: boolean,
  },
  RedirectURL: {
    iv: string,
  },
  Languages: {
    iv: Array<Language>,
  }
}

export type CommonActions =
  | { type: 'SET_LOADING', payload: { loading: boolean; } }

export interface ConsumerDataRequest {
  countryCode: string,
  deviceType: string,
  applicationVersion: string,
  coverMode: string,
  languageCode: string,
}

export interface EventDataRequest {
  consumerId: string,
  type: string,
  subType: string,
  origin: string,
  market: string,
  log: string,
};

export interface ContentItem {
  Binding: BindingsUnion,
  Country: string,
};

// squidex further-resource component
export interface FurtherResource {
  Label?: string,
  Link?: FurtherResourceLink,
  ExternalLink?: string,
}

export interface Guide {
  Title: string,
  Content: string,
  LearnMore: string,
  Media: Array<string>,
  MediaType: string,
  Icon: string | null,
  SVGData?: Array<string>,
};

// guide-content schema
export interface GuideContent extends ContentItem {
  Binding: GuideBindings,
  Country: string,
  Title: Record<HTMLLanguageCodes, string>,
  Description: Record<HTMLLanguageCodes, string>,
  Guides: Record<HTMLLanguageCodes, Guide>,
  FurtherResources: Record<HTMLLanguageCodes, Array<FurtherResource>>,
};

export interface LocalizedGuideContent extends ContentItem {
  Binding: GuideBindings,
  Country: string,
  Title: string,
  Description: string,
  Guides: Array<Guide>,
  FurtherResources: Array<FurtherResource>,
};

// page-content schema
export interface PageContent extends ContentItem {
  Binding: PageBindings,
  Country: string,
  Title: Record<HTMLLanguageCodes, string>,
  Icon: string,
  Content: Record<HTMLLanguageCodes, string>,
  LearnMore: Record<HTMLLanguageCodes, string>,
  AdditionalContent: Record<HTMLLanguageCodes, string>,
  FurtherResources: Record<HTMLLanguageCodes, Array<FurtherResource>>,
};

export interface LocalizedPageContent extends ContentItem {
  Binding: PageBindings,
  Country: string,
  Title: string,
  Icon: string,
  Content: string,
  LearnMore: string,
  AdditionalContent: string,
  FurtherResources: Array<FurtherResource>,
};

// questionnaire-content schema
export type PossibleAnswer = {
    Title: string,
    Value: number
}

export type Question = {
    Question: string,
    Answer: number,
    LearnMore: string,
    CorrectNotification: string,
    IncorrectNotification: string,
}

export type QuestionnaireResult = {
    WebResultContent: string,
    ResultLearnMore?: string,
    ResultThreshold: number,
}

export interface QuestionnaireContent extends ContentItem {
    Binding: QuestionnaireBindings,
    Country: string,
    Title: Record<HTMLLanguageCodes, string>,
    Order: number,
    ListIcon: string,
    CallToAction: Record<HTMLLanguageCodes, string>,
    Description: Record<HTMLLanguageCodes, string>,
    PossibleAnswers: Record<HTMLLanguageCodes, Array<PossibleAnswer>>,
    Questions: Record<HTMLLanguageCodes, Array<Question>>,
    Results: Record<HTMLLanguageCodes, Array<QuestionnaireResult>>,
    FurtherResources: Record<HTMLLanguageCodes, Array<FurtherResource>>,
};

export interface LocalizedQuestionnaireContent extends ContentItem {
    Binding: QuestionnaireBindings,
    Country: string,
    Title: string,
    Order: number,
    ListIcon: string,
    CallToAction: string,
    Description: string,
    PossibleAnswers: Array<PossibleAnswer>,
    Questions: Array<Question>,
    Results: Array<QuestionnaireResult>,
    FurtherResources: Array<FurtherResource>,
};

// resource-content schema
export interface Resource {
    Id: string,
    Title: string, 
    ResourceUrl: string,
    Content: string,
};

export interface ResourceContent extends ContentItem {
    Binding: ResourceBindings,
    Country: string,
    Title: Record<HTMLLanguageCodes, string>,
    Description: Record<HTMLLanguageCodes, string>,
    Resources: Record<HTMLLanguageCodes, Array<Resource>>,
    FurtherResources: Record<HTMLLanguageCodes, Array<FurtherResource>>,
};

export interface LocalizedResourceContent extends ContentItem {
    Binding: ResourceBindings,
    Country: string,
    Title: string,
    Description: string,
    Resources: Array<Resource>,
    FurtherResources: Array<FurtherResource>,
};

// list-content schema
export interface ListItem {
    Label: string,
    Identifier: string
};

export interface ListContent extends ContentItem {
    Binding: ListBindings,
    Country: string,
    Items: Record<HTMLLanguageCodes, Array<ListItem>>,
};

export interface LocalizedListContent extends ContentItem {
    Binding: ListBindings,
    Country: string,
    Items: Array<ListItem>,
};

export interface AssetDTO {
    id: string,
    parentId: string,
    fileName: string,
    fileHash: string,
    isProtected: boolean,
    slug: string,
    mimeType: string,
    fileType: string,
    metadataText: string,
    metadata: Record<any, any>,
    tags: Array<any>,
    fileSize: number,
    fileVersion: number,
    type: string,
    createdBy: string,
    lastModifiedBy: string,
    created: string,
    lastModified: string,
    version: number,
    isImage: boolean,
}

export interface AssetsDTO {
    total: number,
    items: Array<AssetDTO>,
}