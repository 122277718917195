import { FC } from 'react';
import { BoundButton } from './BoundButton';
import { TextBindings } from '../../compiler/enums';
import { decode } from 'html-entities';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { Resource } from '../../compiler/types';

export const ResourceCard: FC<Resource> = ({
  Title, Content, ResourceUrl,
}) => {

  return (
    <div className="nav-card">
      <h2 className="lato">{decode(Title)}</h2>
      <div className="card-body">
        <div className="lato" dangerouslySetInnerHTML={{
            __html: decode(Content)
          }}
        />
      </div>
      <BoundButton 
        type="default" 
        isCTA={true} 
        text="Visit Site" 
        binding={TextBindings.ButtonVisitSite} 
        popConfirmOnClick
        popConfirmBinding={TextBindings.TextOpenNewTab}
        onClick={() => window.open(ResourceUrl)} 
        iconRight={faArrowUpRightFromSquare}
      />
    </div>
  );
};
