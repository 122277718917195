import { BoundButton } from '../../../common/components';
import DefaultImage from '../../../common/components/DefaultImage';
import { Page } from '../../../common/components/page-component/Page';
import { useBinding, useIsMobile, useLink } from '../../../common/utils/common';
import { Links, TextBindings } from '../../../compiler/enums';

import './NotFound.css';

const NotFound = () => {
  const homeLink = useLink(Links.Home);
  const isMobile = useIsMobile();

  return (
    <Page>
      <div id="not-found" className="lato">
        <DefaultImage height={isMobile ? 160 : 200} width={isMobile ? 200 : 250} src="https://placehold.co/470x350" alt="TODO"/>
        <span>{useBinding(TextBindings.TextNotFound)}</span>
        <BoundButton
          binding={TextBindings.ButtonReturnToWebsite}
          text="Return to homepage"
          type="link"
          link={homeLink}
        />
      </div>
    </Page>
  );
};

export default NotFound;