import { FC, PropsWithChildren, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { TextBindings } from '../../compiler/enums';
import { BoundButton } from './BoundButton';

type ShowMoreShowLessProps = {
  limit: number,
  moreBinding?: TextBindings,
  lessBinding?: TextBindings,
  icon?: ReactNode,
  className?: string,
  showMoreState?: boolean, // custom show more state from outside component
  setShowMoreState?: (value: SetStateAction<boolean>) => void, // use for setting state imperatively / outside of component
};

export const ShowMoreShowLess: FC<PropsWithChildren<ShowMoreShowLessProps>> = ({ 
  children, limit, moreBinding, lessBinding, icon, className, showMoreState, setShowMoreState
}) => {
  const showMore = moreBinding ?? TextBindings.TextShowMore;
  const showLess = lessBinding ?? TextBindings.TextShowLess;
  const [isShowMore, setShowMore] = useState(false);
  const toggleLink = () => {
    setShowMore(prevState => !prevState);
  };

  const text = useMemo(() => {
    const showMore = showMoreState !== undefined ? showMoreState : isShowMore;
    return showMore ? children : children?.toString().substring(0, limit);
  }, [children, isShowMore, limit, showMoreState]);

  return (
    <div>
      <BoundButton 
        icon={icon}
        onClick={() => setShowMoreState ? setShowMoreState(!showMoreState) : toggleLink()} 
        type="link" 
        className={"show-more-show-less " + className}
        binding={!isShowMore ? showMore : showLess}
      />
      {text}
    </div>
  );
};
