import { useReducer, useMemo, createContext, Dispatch, FC, useEffect } from 'react';
import { StorageKeys } from "../../config/Storage";
import { contentContextReducer } from './reducers';

export interface ContentContextType {
  loading: Record<StorageKeys, boolean> | {},
  content: Record<StorageKeys, any> | {},
};

export type ContentContextActions =
  | { type: 'SET_LOADING', payload: { key: StorageKeys, loading: boolean; } }
  | { type: 'SET_CONTENT', payload: { key: StorageKeys, content: object; } }
  | { type: 'SET_ALL_CONTENT', payload: { content: Record<StorageKeys, object>; } }

export interface ContentContextMemo {
  context: ContentContextType;
  dispatch: Dispatch<ContentContextActions>;
}

const defaultContentContext = {
  loading: {
    [StorageKeys.QuestionnaireContentKey]: true,
    [StorageKeys.AccordionContentKey]: true,
    [StorageKeys.GuideKey]: true,
    [StorageKeys.MenuKey]: true,
    [StorageKeys.LanguagesKey]: true,
    [StorageKeys.TagsKey]: true,
    [StorageKeys.SettingsKey]: true,
    [StorageKeys.ListsKey]: true,
    [StorageKeys.AnalyticsKey]: true,
    [StorageKeys.CountryStore]: true,
    [StorageKeys.ResourceKey]: true,
    [StorageKeys.PageKey]: true,
  },
  content: {},
};

export const ContentContext = createContext<ContentContextMemo>({
  context: defaultContentContext,
  dispatch: () => { }
});

export const ContentContextProvider: FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [context, dispatch] = useReducer(contentContextReducer, defaultContentContext);

  const contentContextMemo = useMemo(() => {
    return { context, dispatch };
  }, [context, dispatch]);

  useEffect(()=> {console.log(context)}, [context]);

  return (
    <ContentContext.Provider value={contentContextMemo}>
      {children}
    </ContentContext.Provider>
  );
};