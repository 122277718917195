import { useState, useEffect, useCallback, useMemo } from "react";
import { decode } from "html-entities";
import { Events, Templates, Origins } from 'brightsky-3/constants/Logging';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWebAnalyticsContext from "../../../common/hooks/useWebAnalyticsContext";
import { ShowMoreShowLess } from "../../../common/components/ShowMoreShowLess";
import { TextBindings } from "../../../compiler/enums";
import { BoundButton } from "../../../common/components";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { LocalizedQuestionnaireContent, PossibleAnswer, QuestionnaireResult } from "../../../compiler/types";
import { AnswerButtons } from "./AnswerButtons";
import { Row, Space } from "antd";
import { faChevronRight, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { useBinding } from "../../../common/utils/common";
import { Loading } from "../../../common/components/Loading";

export const BoundQuestionnaire = ({ content, binding }) => {
  const { logEvent } = useWebAnalyticsContext();
  
  const currentQuestionnaire: LocalizedQuestionnaireContent = content;
  const currentQuestions = currentQuestionnaire.Questions;

  const [answers, setAnswers] = useState<Array<PossibleAnswer>>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [closestResult, setClosestResult] = useState<QuestionnaireResult | null>(null);

  const isComplete = useMemo(() => {
    if (currentQuestionIndex === currentQuestions?.length) {
      return true;
    }
    return false;
  }, [currentQuestionIndex, currentQuestions?.length]);

  const answerIncorrect = useMemo(() => {
    if (!currentQuestions[currentQuestionIndex] 
      || !currentQuestions[currentQuestionIndex].IncorrectNotification 
      || currentQuestions[currentQuestionIndex].IncorrectNotification === ""
      || !answers[currentQuestionIndex]) {
      return false;
    }
    return answers[currentQuestionIndex].Value !== currentQuestions[currentQuestionIndex].Answer;
  }, [currentQuestionIndex, currentQuestions, answers]);

  const answerCorrect = useMemo(() => {
    if (!currentQuestions[currentQuestionIndex] 
      || !currentQuestions[currentQuestionIndex].CorrectNotification 
      || currentQuestions[currentQuestionIndex].CorrectNotification === ""
      || !answers[currentQuestionIndex]) {
      return false;
    }
    return answers[currentQuestionIndex].Value === currentQuestions[currentQuestionIndex].Answer;
  }, [answers, currentQuestionIndex, currentQuestions]);

  const hasResults = useMemo(() => {
    return currentQuestionnaire?.Results != null && currentQuestionnaire?.Results?.length > 0;
  }, [currentQuestionnaire?.Results]);

  const scoreQuestionnaire = useCallback(() => {
    setResultsLoading(true);
    let correctAnswerCount = 0;
    answers?.forEach((a, i) => {
      const result = a.Value === currentQuestions[i]?.Answer;
      if (result) {
        correctAnswerCount += 1;
      }
    });

    if (isComplete && hasResults) {
      const results = currentQuestionnaire.Results;
      const correctPercent = Math.round((correctAnswerCount / answers.length) * 100);

      if (results != null && results.length > 0) {
        setClosestResult(
          results.reduce((result1, result2) =>
            Math.abs(result2.ResultThreshold - correctPercent) <
            Math.abs(result1.ResultThreshold - correctPercent)
                ? result2
                : result1
          ));
      }

      logEvent(
        Events.QuestionnaireCompleteEvent,
        Origins.Questionnaire,
        Templates.questionnaire.complete(binding)
      );
    }
    
    setResultsLoading(false);
  }, [answers, binding, currentQuestionnaire.Results, currentQuestions, hasResults, isComplete, logEvent]);

  useEffect(() => {
    if (isComplete) {
      scoreQuestionnaire();
    }
  }, [isComplete, scoreQuestionnaire]);

  const reset = () => {
    setCurrentQuestionIndex(0);
    setAnswers([]);
  };

  const showResults = useMemo(() => {
    return isComplete &&
           currentQuestionnaire?.Results != null &&
           hasResults &&
           closestResult !== null;
  }, [closestResult, currentQuestionnaire?.Results, hasResults, isComplete]);

  const summaryTitle = useBinding(`${TextBindings.TitleSummary}.Text`);

  return (
    <>
      <div className="questionnaire-container">
        { currentQuestions[currentQuestionIndex] && !showResults && (
          <>
            <span className="question-counter">{`${currentQuestionIndex + 1} / ${currentQuestions.length}`}</span>
            <div className="question-text lato" dangerouslySetInnerHTML={{ __html: decode(currentQuestions[currentQuestionIndex].Question) }} />
            { currentQuestions[currentQuestionIndex].LearnMore && (
              <ShowMoreShowLess
                moreBinding={TextBindings.LabelUnderstandThisQuestion}
                lessBinding={TextBindings.LabelUnderstandThisQuestion}
                limit={0}
                icon={<FontAwesomeIcon icon={faQuestionCircle} />}
                className="question-learn-more-toggle"
                showMoreState={showLearnMore}
                setShowMoreState={setShowLearnMore}
              >
                <div className="question-learn-more-text" dangerouslySetInnerHTML={{ __html: decode(currentQuestions[currentQuestionIndex].LearnMore) }} />
              </ShowMoreShowLess>
            )}
            { answerIncorrect && (
              <div 
                className="incorrect-notification" 
                dangerouslySetInnerHTML={{ __html: decode(currentQuestions[currentQuestionIndex].IncorrectNotification) }} 
              />
            )}
            { answerCorrect && (
              <div 
                className="correct-notification" 
                dangerouslySetInnerHTML={{ __html: decode(currentQuestions[currentQuestionIndex].CorrectNotification) }} 
              />
            )}
            <AnswerButtons
              currentQuestionIndex={currentQuestionIndex}
              setAnswers={setAnswers}
              qIndex={currentQuestionIndex}
              possibleAnswers={currentQuestionnaire?.PossibleAnswers}
              answers={answers}
              binding={binding}
            />
          </>
        )}
        { showResults && resultsLoading && (
          <Loading />
        )}
        { showResults && !resultsLoading && closestResult && (
          <>
            <h2 className="questionnaire-summary-title lato">
              {summaryTitle}
            </h2>
            <p
              className="questionnaire-summary-description lato"
              dangerouslySetInnerHTML={{
                __html: decode(closestResult.WebResultContent),
              }}
            />
            { closestResult.ResultLearnMore && closestResult.ResultLearnMore !== "" && (
              <ShowMoreShowLess
                limit={0}
                className="results-learn-more-toggle"
              >
                <div className="results-learn-more-text" dangerouslySetInnerHTML={{ __html: decode(closestResult.ResultLearnMore) }} />
              </ShowMoreShowLess>
            )}
            <BoundButton
              className="questionnaire-restart-button"
              type="primary"
              onClick={() => reset()}
              binding={TextBindings.ButtonStartOver}
            />
          </>
        )}
      </div>
      {!showResults && (
        <Row className="questionnaire-nav-row">
          <Space size="large">
            <BoundButton
              className="questionnaire-nav-button"
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              type="light-default"
              onClick={() => {
                setCurrentQuestionIndex(currentQuestionIndex - 1);
                setShowLearnMore(false);
              }}
              disabled={currentQuestionIndex === 0}
              ariaLabel={TextBindings.TextBack}
            />
            <BoundButton
              className="questionnaire-nav-button"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              type="light-default"
              onClick={() => {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                setShowLearnMore(false);
              }}
              disabled={!answers[currentQuestionIndex]}
              ariaLabel={TextBindings.TextNext}
            />
          </Space>
        </Row>
      )}
    </>
  );
};
