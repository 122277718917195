import { FC } from 'react';
import { useIsMobile } from '../utils/common';
import { ResourceCard } from './ResourceCard';
import { Carousel } from 'antd';
import { Resource } from '../../compiler/types';

export type ResourceCardGroupProps = {
  resourceCards: Array<Resource>,
};

export const ResourceCardGroup: FC<ResourceCardGroupProps> = ({ resourceCards }) => {

  const isMobile = useIsMobile();

  const cardElements = resourceCards && resourceCards.map((resourceCard: Resource, index: number) => {
    return <ResourceCard key={index} {...resourceCard} />
  });

  return (
    <div className="resource-cards">
       { isMobile && (
        <Carousel arrows dotPosition={"bottom"} draggable infinite>
          {cardElements}
        </Carousel>
      )}
       { !isMobile && (
        <>
          {cardElements}
        </>
      )}
    </div>
  );
};
