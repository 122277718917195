import { Form, Spin } from 'antd';
import { FC, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import { Formik, FormikProps } from 'formik';
import { FeedbackFormData, FeedbackSubmissionRequest } from '../types';
import feedbackService from '../../../services/feedback-service';
import useWebAnalyticsContext from '../../../common/hooks/useWebAnalyticsContext';
import { useBinding } from '../../../common/utils/common';
import { ListBindings, TextBindings } from '../../../compiler/enums';
import { FormRow, FormRowType } from '../../../common/components/FormRow';
import { BoundButton } from '../../../common/components';
import { ListItem, LocalizedListContent, LocalizedPageContent } from '../../../compiler/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { decode } from 'html-entities';
import useContentContext from '../../../common/hooks/useContentContext';
import { StorageKeys } from '../../../config/Storage';

type FeedbackFormProps = {
  content?: LocalizedPageContent,
};

const FeedbackForm: FC<FeedbackFormProps> = ({ content }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>();
  const [error, setError] = useState<string | null>();
  const [specificEthnicityList, setSpecificEthnicityList] = useState<Array<{ label: string, value: string | undefined }> | null>(null);
  const { logEvent } = useWebAnalyticsContext();
  const formRef = useRef<FormikProps<FeedbackFormData>>(null);
  const { getContent } = useContentContext();

  const feedbackSubmitSuccess = useBinding(`${TextBindings.SuccessSubmittedFeedback}.Text`);
  const feedbackSubmitFailure = useBinding(`${TextBindings.ErrorFeedbackFailed}.Text`);

  const getMappedList = (boundList: LocalizedListContent | null): Array<{ label: string, value: string | undefined }> | null => {
    if (!boundList) {
      return null;
    }

    return boundList?.Items.map((x: ListItem) => { 
      return { 
        label: x.Label, 
        value: x.Identifier === null || x.Identifier === "" ? undefined : x.Identifier, // cast to undefined so that placeholders can be parsed out
      } 
    });
  }

  const getSpecificEthnicityList = (val: string): Array<{ label: string, value: string | undefined }> | null => {
    var binding: ListBindings | null = null;
    switch (val) {
      case 'white': binding = ListBindings.DropdownEthnicityWhite; break;
      case 'mixed': binding = ListBindings.DropdownEthnicityMixed; break;
      case 'asian': binding = ListBindings.DropdownEthnicityAsian; break;
      case 'black': binding = ListBindings.DropdownEthnicityBlack; break;
      default: return null;
    }
    
    const boundList: LocalizedListContent | null = getContent(StorageKeys.ListsKey, binding);
    return getMappedList(boundList);
  };

  const ageRangeList = useMemo((): Array<{ label: string, value: string | undefined }> | null => {
    const boundList: LocalizedListContent | null = getContent(StorageKeys.ListsKey, ListBindings.DropdownAgeRange);
    return getMappedList(boundList);
  }, [getContent]);

  const genderList = useMemo((): Array<{ label: string, value: string | undefined }> | null => {
    const boundList: LocalizedListContent | null = getContent(StorageKeys.ListsKey, ListBindings.DropdownGender);
    return getMappedList(boundList);
  }, [getContent]);

  const generalEthnicityList = useMemo((): Array<{ label: string, value: string | undefined }> | null => {
    const boundList: LocalizedListContent | null = getContent(StorageKeys.ListsKey, ListBindings.DropdownEthnicityGeneral);
    return getMappedList(boundList);
  }, [getContent]);

  const onFinish = (formData: FeedbackFormData) => {
    const { EthnicityGeneral, EthnicitySpecific, ...formDataWithoutEthnicity } = formData;
    var submission: FeedbackSubmissionRequest = {
      ...formDataWithoutEthnicity,
      Ethnicity: formData.EthnicitySpecific ?? formData.EthnicityGeneral,
    };

    if (Events?.FeedbackSubmit) {
      logEvent(Events.FeedbackSubmit, Origins.Feedback, Templates.feedback.submit(submission));
    }
    const validationErrors = formRef.current?.errors;
    if (validationErrors && Object.keys(validationErrors).length === 0) {
      feedbackService.submitFeedback(submission)
        .then(() => {
          setLoading(false);
          setSuccess(feedbackSubmitSuccess);
          setTimeout(() => {
            setSuccess('');
          }, 5000);

          // clear values
          formRef.current?.resetForm();
        },
        () => {
          setLoading(false);
          setError(feedbackSubmitFailure);
        }
      );
    }
  };

  const initialValues = {
    HelpfulRating: null,
    EaseOfUseRating: null,
    UseAgainRating: null,
    AgeRange: null,
    Gender: null,
    EthnicityGeneral: null,
    EthnicitySpecific: null,
  };

  const validationSchema = Yup.object().shape(
    {
      HelpfulRating: Yup.number().when(["EaseOfUseRating", "UseAgainRating"], {
        is: (EaseOfUseRating, UseAgainRating) => EaseOfUseRating === null && UseAgainRating === null,
        then: (schema) => schema.required(""),
        otherwise: (schema) => schema.notRequired(),
      }),
      EaseOfUseRating: Yup.number().when(["HelpfulRating", "UseAgainRating"], {
        is: (HelpfulRating, UseAgainRating) => HelpfulRating === null && UseAgainRating === null,
        then: (schema) => schema.required(""),
        otherwise: (schema) => schema.notRequired(),
      }),
      UseAgainRating: Yup.number().when(["HelpfulRating", "EaseOfUseRating"], {
        is: (HelpfulRating, EaseOfUseRating) => HelpfulRating === null && EaseOfUseRating === null,
        then: (schema) => schema.required(""),
        otherwise: (schema) => schema.notRequired(),
      }),
      AgeRange: Yup.string().nullable(),
      Gender: Yup.string().nullable(),
      EthnicityGeneral: Yup.string().nullable(),
      EthnicitySpecific: Yup.string().nullable(),
    }, [["EaseOfUseRating", "UseAgainRating"], ["HelpfulRating", "UseAgainRating"], ["HelpfulRating", "EaseOfUseRating"]]
  );

  const radioRatingChoices: Array<{ value: number, binding: string }> = [
    { value: 1, binding: "1"},
    { value: 2, binding: "2"},
    { value: 3, binding: "3"},
    { value: 4, binding: "4"},
    { value: 5, binding: "5"},
  ];
  
  return (
    <div className="feedback-form lato">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: FeedbackFormData) => onFinish(values)}
        innerRef={formRef}
      >
        {({ values, touched, errors, handleChange, handleBlur, submitForm, setFieldValue, isSubmitting, resetForm }) => (
          <Form form={form} layout="vertical">
            <FormRow
              name="HelpfulRating"
              type={FormRowType.Radio}
              textBinding={TextBindings.TextFeedbackHelpful}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values.HelpfulRating}
              touched={touched.HelpfulRating}
              error={errors.HelpfulRating}
              choices={radioRatingChoices}
              direction={"horizontal"}
            />
            <FormRow
              name="EaseOfUseRating"
              type={FormRowType.Radio}
              textBinding={TextBindings.TextFeedbackEaseOfUse}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values.EaseOfUseRating}
              touched={touched.EaseOfUseRating}
              error={errors.EaseOfUseRating}
              choices={radioRatingChoices}
              direction={"horizontal"}
            />
            <FormRow
              name="UseAgainRating"
              type={FormRowType.Radio}
              textBinding={TextBindings.TextFeedbackUseAgain}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values.UseAgainRating}
              touched={touched.UseAgainRating}
              error={errors.UseAgainRating}
              choices={radioRatingChoices}
              direction={"horizontal"}
            />
            { ageRangeList && (
              <FormRow
                name="AgeRange"
                type={FormRowType.Select}
                textBinding={TextBindings.LabelChooseAgeRange}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={values.AgeRange}
                touched={touched.AgeRange}
                error={errors.AgeRange}
                items={ageRangeList}
              />
            )}
            { genderList && (
              <FormRow
                name="Gender"
                type={FormRowType.Select}
                textBinding={TextBindings.LabelChooseGender}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={values.Gender}
                touched={touched.Gender}
                error={errors.Gender}
                items={genderList}
              />
            )}
            { generalEthnicityList && (
              <FormRow
                name="EthnicityGeneral"
                type={FormRowType.Select}
                textBinding={TextBindings.LabelChooseEthnicity}
                onChange={(val) => {
                  handleChange(val);
                  setSpecificEthnicityList(getSpecificEthnicityList(val));
                }}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={values.EthnicityGeneral}
                touched={touched.EthnicityGeneral}
                error={errors.EthnicityGeneral}
                items={generalEthnicityList}
              />
            )}
            { specificEthnicityList && (
              <FormRow
                name="EthnicitySpecific"
                type={FormRowType.Select}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={values.EthnicitySpecific}
                touched={touched.EthnicitySpecific}
                error={errors.EthnicitySpecific}
                items={specificEthnicityList}
              />
            )}
            { content && content.AdditionalContent && (
              <div className="feedback-form-additional-content">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div dangerouslySetInnerHTML={{
                    __html: decode(content.AdditionalContent)
                  }}
                />
              </div>
            )}
            <Form.Item>
              { success != null && <h3>{success}</h3>}
              { error != null && <div style={{ color: 'red' }}>{error}</div>}
              { !loading && (
                <BoundButton
                  type="primary"
                  binding={TextBindings.ButtonSubmitFeedback}
                  tooltipBinding="Web_Tooltip_SubmitEntry.Text"
                  htmlType="submit"
                  onClick={submitForm}
                  disabled={values.HelpfulRating === null && values.EaseOfUseRating === null && values.UseAgainRating === null}
                />
              )}
              { loading && <Spin />}
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FeedbackForm;
