import { FC, useEffect, useMemo, useState } from 'react';
import { decode } from 'html-entities';
import useContentContext from '../hooks/useContentContext';
import { DefaultSelect, DefaultSelectProps } from './DefaultSelect';
import { Country } from '../../compiler/types';
import { Button, Space } from 'antd';
import { getFlagClassName } from './functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import useActiveCountry from '../hooks/useActiveCountry';
import { useBinding } from '../utils/common';
import { TextBindings } from '../../compiler/enums';
import useConfigContext from '../hooks/useConfigContext';

export const CountrySelect: FC<DefaultSelectProps<Country> & { showFlag?: boolean, grid?: boolean, ignoreContext?: boolean }> = ({
  onChange, size, width, className, value, allowClear = true, showFlag = true, ariaLabel, variant, grid, ignoreContext, ...props
}) => {
  const c = useActiveCountry();
  const { getCountries } = useContentContext();
  const { translationsLoading } = useConfigContext();
  const [items, setItems] = useState<{ value: string, label: string }[]>([]);
  const [gridCountrySelected, setGridCountrySelected] = useState<string | null>(c && !ignoreContext ? c.Code?.iv : null);

  const countrySelectBinding = `${TextBindings.LabelSelectCountry}.Text`;
  const boundCountrySelect = useBinding(countrySelectBinding);

  const countrySelectLabel = useMemo(() => {
    if (!translationsLoading &&  (boundCountrySelect === `${c?.Code.iv}_${countrySelectBinding}` || boundCountrySelect === "NA")) {
      return null;
    }
    return boundCountrySelect;
  }, [boundCountrySelect, c?.Code.iv, countrySelectBinding, translationsLoading]);

  const countries = useMemo(() => {
    return getCountries();
  }, [getCountries])

  useEffect(() => {
    if (!countries || countries.length === 0) {
      setItems([]);
    }
    const countryList = countries
      .filter(c => c?.WebEnabled?.iv === true)
      .sort((a, b) => {
        const ta = a.Name.en.toLowerCase();
        const tb = b.Name.en.toLowerCase();
        return ta < tb ? -1 : ta > tb ? 1 : 0;
      })
      .map(c => {
        return {
          value: c?.Code?.iv,
          label: decode(c?.Name?.en),
        };
      });
    setItems(countryList);
  }, [countries]);

  const loading = items == null || items.length === 0;

  if (grid) {
    return (
      <div className="country-grid-container">
        <Button.Group>
          { items.map((country, index) => {
            return (
              <Button
                key={index}
                onClick={() => {
                  setGridCountrySelected(country.value);
                  onChange && onChange(country.value, countries.filter(x => x.Code.iv === country.value)[0]);
                }} 
                className={`country-grid-option ${gridCountrySelected === country.value ? "selected" : ""}`}
              >
                { showFlag && <span className={`fi fi-${getFlagClassName(country.value as string)} fi`}></span> }
                <span className="label">
                  {country.label}
                </span>
                { gridCountrySelected === country.value && (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </Button>
            );
          })}
        </Button.Group>
      </div>
    );
  }

  return (
    <DefaultSelect
      size={size}
      width={width}
      value={value}
      allowClear={allowClear}
      className={className}
      items={items}
      loading={loading}
      labelRender={(selected) => (
        <Space>
          { showFlag && <span className={`fi fi-${getFlagClassName(selected.value as string)} fi`}></span> }
          <span>
            {selected.label}
          </span>
        </Space>
      )}
      onChange={(val: string) => {
        var country = countries.filter(x => x.Code.iv === val)[0];
        onChange && onChange(val, country);
      }}
      optionRender={(option) => (
        <Space>
          { showFlag && <span className={`fi fi-${getFlagClassName(option.value as string)} fi`}></span> }
          <span>
            {option.label}
          </span>
        </Space>
      )}
      ariaLabel={props["aria-label"] ?? (ariaLabel ?? countrySelectLabel)}
      variant={variant}
    />
  );
};

export default CountrySelect;
