import { useContext } from 'react';
import { WebAnalyticsContext } from '../contexts/WebAnalyticsContext';
import { useCountryTranslation } from 'brightsky-3';
import { Events, Origins } from 'brightsky-3/constants/Logging';
import useActiveCountry from '../hooks/useActiveCountry';
import { setConsumerId } from "../contexts/actions";
import { ConsumerDataRequest, EventDataRequest } from '../../compiler/types';
import analyticsService from '../../services/analytics-service';

const useWebAnalyticsContext = () => {
  const { context, dispatch } = useContext(WebAnalyticsContext);
  if (!context) {
    throw new Error('useWebAnalyticsContext must be used within a WebAnalyticsContextProvider');
  }

  const { consumerId } = context;
  const { l } = useCountryTranslation();
  const c = useActiveCountry();

  const registerConsumer = (urlCountry?: string, urlConsumer?: string | null) => {
    if (urlConsumer) {
      dispatch(setConsumerId(urlConsumer));
      if (!urlCountry) {
        throw Error("Impossible WebAnalytics state: Consumer present and no country detected.");
      }

      if (Events?.AppOpen) {
        logEventManual(urlCountry, urlConsumer, Events.AppOpen, Origins.App, { EnterWebsite: urlCountry });
      }
    } else {
      let countryOrVf = urlCountry ? urlCountry : 'VF';
      const consumerData: ConsumerDataRequest = {
        countryCode: countryOrVf,
        deviceType: 'Web',
        applicationVersion: 'Web',
        coverMode: 'NA',
        languageCode: l,
      };

      analyticsService.registerConsumer(consumerData)
        .then((data) => {
          dispatch(setConsumerId(data.Id));

          if (Events?.AppOpen) {
            logEventManual(countryOrVf, data.Id, Events.AppOpen, Origins.App, { EnterWebsite: countryOrVf });
          }

        })
        .catch((err) => {
          console.error('Unable to register WebAnalytics: ', err);
        });
    }
  };

  const logEventManual = (country, consumer, event, origin, log) => {
    const stringLog = log ? JSON.stringify(log) : log;
    const eventData: EventDataRequest = {
      consumerId: consumer,
      type: event.type,
      subType: event.subType,
      origin,
      market: country,
      log: stringLog,
    };

    analyticsService.logEvent(eventData);
  }

  const logEvent = (event, origin, log) => {
    if (!consumerId || !c) {
      return;
    }

    const stringLog = log ? JSON.stringify(log) : log;
    const eventData: EventDataRequest = {
      consumerId,
      type: event.type,
      subType: event.subType,
      origin,
      market: c.Code.iv,
      log: stringLog,
    };
    
    analyticsService.logEvent(eventData);
  };

  return {
    registerConsumer,
    logEventManual,
    logEvent,
    ...context,
  };
};

export default useWebAnalyticsContext;
