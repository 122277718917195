import { createContext, Dispatch, FC, useMemo, useReducer } from 'react';
import { Country, Language, CommonActions } from '../../compiler/types';
import { configContextReducer } from './reducers';

export interface ConfigContextType {
  country: Country | null,
  savedCountry: Country | null, // to store/lock last active country when configModal is opened
  language: Language | null,
  savedLanguage: Language | null,// to store/lock last active language when configModal is opened
  languages: Array<Language>,
  hasConfirmedConfig: boolean,
  hasConfirmedExitSafely: boolean,
  isChangingCountry: boolean, // to indicate config change after site had been loaded to a country once / not initial config
  loading: boolean,
  translationsLoading: boolean;
  configTranslationsLoading: boolean;
  currentTheme: string | null;
};

export type ConfigContextActions =
  CommonActions
  | { type: 'SET_SELECTED_COUNTRY', payload: { country: Country | null } }
  | { type: 'SET_SAVED_COUNTRY', payload: { country: Country | null } }
  | { type: 'SET_SELECTED_LANGUAGE', payload: { language: Language | null } }
  | { type: 'SET_SAVED_LANGUAGE', payload: { language: Language | null } }
  | { type: 'SET_LANGUAGES', payload: { languages: Array<Language> } }
  | { type: 'SET_HAS_CONFIRMED_CONFIG', payload: { hasConfirmedConfig: boolean } }
  | { type: 'SET_HAS_CONFIRMED_EXIT_SAFELY', payload: { hasConfirmedExitSafely: boolean } }
  | { type: 'SET_IS_CHANGING_COUNTRY', payload: { isChangingCountry: boolean } }
  | { type: 'SET_TRANSLATIONS_LOADING', payload: { translationsLoading: boolean } }
  | { type: 'SET_CONFIG_TRANSLATIONS_LOADING', payload: { configTranslationsLoading: boolean } }
  | { type: 'SET_CURRENT_THEME', payload: { currentTheme: string } }

export interface ConfigContextMemo {
  configContext: ConfigContextType;
  configDispatch: Dispatch<ConfigContextActions>;
}

const defaultConfigContext = {
  country: null,
  savedCountry: null,
  language: null,
  savedLanguage: null,
  languages: [],
  hasConfirmedConfig: false,
  hasConfirmedExitSafely: false,
  isChangingCountry: false,
  loading: true,
  translationsLoading: true,
  configTranslationsLoading: false,
  currentTheme: null,
};

export const ConfigContext = createContext<ConfigContextMemo>({
  configContext: defaultConfigContext,
  configDispatch: () => { }
});

export const ConfigContextProvider: FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [configContext, configDispatch] = useReducer(configContextReducer, defaultConfigContext);

  const configContextMemo = useMemo(() => {
    console.log(configContext);
    return { configContext, configDispatch };
  }, [configContext, configDispatch]);
  
  return (
    <ConfigContext.Provider value={configContextMemo}>
      {children}
    </ConfigContext.Provider>
  );
};