import { FC } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TSkeleton } from './TranslationSkeleton';
import { useIsMobile } from '../utils/common';

export interface DefaultSimpleListProps {
  id?: string;
  className?: string;
  title?: string | null;
  listItems: Array<string | null>;
  bulletIcon?: IconDefinition;
}

export const DefaultSimpleList: FC<DefaultSimpleListProps> = ({
  listItems, className, id, title, bulletIcon,
}) => {

  const isMobile = useIsMobile();

  return (
    <>
      { title && (
        <h1 style={{marginTop: 0}}>{title}</h1>
      )}
      <ul
        id={id}
        className={`default-simple-list ${className ? className : ""}`}
        style={{paddingLeft: 0, margin: 0}}
      >
        { listItems.map((listItem, index) => {
          if (!listItem) {
            return null;
          }

          return (
            <li key={index} className="lato">
              { bulletIcon && (
                <FontAwesomeIcon icon={bulletIcon} />
              )}
              <TSkeleton width={isMobile ? "100%" : 400}>
                <div>{listItem}</div>
              </TSkeleton>
            </li>
          );
        })}
      </ul>
    </>
  );
};
