import { FC } from "react";

import { LocalizedPageContent } from "../../compiler/types";

import FeedbackForm from "./components/FeedbackForm";
import { Divider } from "antd/lib";

import "./FeedbackPage.css";

type FeedbackPageProps = {
  content?: LocalizedPageContent,
};

export const FeedbackPage: FC<FeedbackPageProps> = ({
  content
}) => {
  const feedbackContent = content;

  return (
    <div id="feedback-page">
      <Divider />
      <FeedbackForm content={feedbackContent} />
    </div>
  );
};