import { initReactI18next } from "react-i18next";
import i18n from "../util/i18n";
import api from "../util/api";
import { HTMLLanguageCodeLiterals } from "../compiler/types";
import { StorageKeys } from "../config/Storage";
import contentService from "./content-service";

// to add resources to i18n service
export const AddTranslations = async (resources) => {
  Object.keys(resources).forEach((language) => {
    if (!HTMLLanguageCodeLiterals.includes(language)) {
      return;
    }
    i18n.addResourceBundle(language, "translation", resources[language]?.translation);
  });
};

export const ConfigureTranslation = async (resources, baseLanguage, targetI18n: typeof i18n) => {
  const defaultLanguage = 'en';
  let currentLanguage = defaultLanguage;
  if (baseLanguage) {
    currentLanguage = baseLanguage;
  }

  targetI18n.use(initReactI18next)
    .init({
      resources,
      lng: currentLanguage,
      fallbackLng: defaultLanguage,
      debug: false,
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    });
};

const MergeTranslations = (translationsToMerge, allTranslations) => {
  const keyIndex = 0;
  const translationKey = 'translation';

  Object.entries(translationsToMerge).forEach(text => {
    const rootLanguangeFieldName = text[keyIndex];
    if (!allTranslations[rootLanguangeFieldName]) {
      allTranslations[rootLanguangeFieldName] = {};
    }
    allTranslations[rootLanguangeFieldName][translationKey] = {
      ...translationsToMerge[rootLanguangeFieldName][translationKey],
      ...allTranslations[rootLanguangeFieldName][translationKey],
    };
  });

  return allTranslations;
};

const ConvertTranslations = (resources) => {
  const keyIndex = 0;
  const translationKey = 'translation';
  const translations = {};

  Object.entries(resources).forEach(resource => {
    const rootLanguangeFieldName = resource[keyIndex];
    if (!translations[rootLanguangeFieldName]) {
      translations[rootLanguangeFieldName] = {};
    }
    translations[rootLanguangeFieldName][translationKey] = resources[rootLanguangeFieldName][translationKey];
  });

  return translations;
};

const GetTranslations = async (
  countryCode,
  baseLanguage,
  handleTranslationsRetrieved,
  targetI18n,
  textOnly,
) => {
  let allTranslations = {};
  let content = {};
  let getPageResources = new Promise(function () {});
  let getQuestionnaireResources = new Promise(function () {});
  let getGuideResources = new Promise(function () {});

  if (!textOnly) {
    getPageResources = contentService.getCountrySpecificPageContent(countryCode)
     .then(pagesResult => {
       content[StorageKeys.PageKey] = pagesResult;
       return ConvertTranslations(pagesResult);
     })
     .catch(error => {
       console.warn(error);
     });

    getQuestionnaireResources = contentService.getCountrySpecificQuestionnaireContent(countryCode)
     .then(questionnairesResult => {
       content[StorageKeys.QuestionnaireContentKey] = questionnairesResult;
       return ConvertTranslations(questionnairesResult);
     })
     .catch(error => {
       console.warn(error);
     });

    var querySvg = false; // set to storageKey.name === "Guides" if we wanted to use this;
    getGuideResources = contentService.getCountrySpecificGuideContent(countryCode, querySvg)
     .then(guidesResult => {
       content[StorageKeys.GuideKey] = guidesResult;
       return ConvertTranslations(guidesResult);
     })
     .catch(error => {
       console.warn(error);
     });
  }

  const getTextResources = api().get(`Content/Text?country=${countryCode}`)
    .then(textResult => {
      return ConvertTranslations(textResult.data);
    })
    .catch(error => {
      console.warn(error);
    });

  const allProms = textOnly ? [getTextResources] : [getTextResources, getPageResources, getQuestionnaireResources, getGuideResources];
  Promise.allSettled(allProms).then((translations) => {
    translations.forEach((translationResult) => {
      if (!translationResult["value"]) {
        return;
      }
      allTranslations = MergeTranslations(translationResult["value"], {...allTranslations});
    });
    
    if (handleTranslationsRetrieved) handleTranslationsRetrieved(allTranslations, content);
    ConfigureTranslation(allTranslations, baseLanguage, targetI18n);

    return allTranslations;
  });
};

const GetAllTranslations = (
  countryCode,
  baseLanguage,
  handleTranslationsRetrieved,
  targetI18n,
  textOnly,
) => {
  GetTranslations(
    countryCode,
    baseLanguage,
    handleTranslationsRetrieved,
    targetI18n,
    textOnly,
  );
};

const LoadWebTranslations = async (
  countryCode,
  baseLanguage,
  handleTranslationsRetrieved, // Executes after all translations have been aggregated, passes translations back
  targetI18n, // can pass custom instance here
  textOnly = false,
) => {
  GetAllTranslations(
    countryCode,
    baseLanguage,
    handleTranslationsRetrieved,
    targetI18n ?? i18n,
    textOnly,
  );
};

const i18nService = {
  LoadWebTranslations,
  AddTranslations,
};

export default i18nService;