import { FC, PropsWithChildren, ReactElement } from "react";
import { FurtherResource, LocalizedPageContent } from "../../../compiler/types";
import { decode } from "html-entities";
import { ExploreFurtherResources } from "../explore-further-resources-component/ExploreFurtherResources";
import React from "react";
import { PageBindings } from "../../../compiler/enums";

import "./Page.css";

type PageComponentProps = {
  content?: LocalizedPageContent,
  binding?: PageBindings,
  furtherResources?: Array<FurtherResource>,
};

export const Page: FC<PropsWithChildren<PageComponentProps>> = ({ children, content, furtherResources, binding }) => {
  const pageContent = content;

  // if rendered from DynamicRoute
  if (pageContent) {
    return (
      <div className="page-outline">
        <div className="color section header">
          <h1>{decode(pageContent.Title)}</h1>
        </div>
        <div className={"white section"}>
          <div className="lato" dangerouslySetInnerHTML={{
              __html: decode(pageContent.Content)
            }}
          />
          { children && React.cloneElement((children as ReactElement), { content, binding }) }
        </div>
        { pageContent.FurtherResources && (
          <ExploreFurtherResources furtherResources={pageContent.FurtherResources} />
        )}
      </div>
    );
  }

  // if used as a formatting wrapper
  return (
    <div className="page-outline">
      {children}
      { furtherResources && (
        <ExploreFurtherResources furtherResources={furtherResources} />
      )}
    </div>
  );
};