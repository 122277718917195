import { FC, useEffect, useState } from 'react';
import { Route, useLocation, Routes } from 'react-router-dom';
import { ConfigPage } from '../home/ConfigPage';
import LandingPage from '../home/LandingPage';
import useConfigContext from '../../common/hooks/useConfigContext';
import { AppLayout as Layout } from '../../common/layout/Layout';
import { GuideBindings, HeroCards, Links, NavCards, PageBindings, QuestionnaireBindings, ResourceBindings, TextBindings } from '../../compiler/enums';
import Guide from '../guide/GuideComponent';
import { BoundButton } from '../../common/components';
import { DynamicRoute } from './components/DynamicRoute';
import NotFound from './components/NotFound';
import { Page } from '../../common/components/page-component/Page';
import { StorageKeys } from '../../config/Storage';
import ServiceDirectory from '../find-support/ServiceDirectory';
import FeatureHub from '../feature-hub/FeatureHubComponent';
import { Questionnaire } from '../questionnaire/QuestionnaireComponent';
import { ResourcePage } from '../resources/ResourcePage';
import { JournalPage } from '../journal/JournalPage';
import { FeedbackPage } from '../feedback/FeedbackPage';
import SiteSearchResults from '../site-search/SiteSearchResults';
import useActiveCountry from '../../common/hooks/useActiveCountry';
import BrowseSafelyModal from '../home/BrowseSafelyModal';

const SiteManifest: FC<{ className?: string }> = ({ className }) => {
  const c = useActiveCountry();
  const { hasConfirmedConfig, isChangingCountry } = useConfigContext();
  const [browseSafelyVisible, setBrowseSafelyVisible] = useState<boolean>(false);

  return (
    <Layout className={className}>
      <Routes>
        { !hasConfirmedConfig && !isChangingCountry &&
         <Route path="/" element={<ConfigPage />} />
        }
        { (hasConfirmedConfig || isChangingCountry) && // revert before commit
         <>
           <Route path="/:lang" element={<LandingPage />} />
           <Route path={`/:lang${Links.Home}`} element={<LandingPage />} />
           <Route path={`/:lang${Links.Home}/:consumerId`} element={<LandingPage />} />
           <Route path={`/:lang${Links.NotFound}`} element={<NotFound />} />
           <Route path={`/:lang${Links.SearchResults}`} element={<SiteSearchResults />} />
           {/* Guides */}
           <Route
             path={`/:lang${Links.EmployersPage}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.EmployersPage} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={1} 
                     headerImageAltText={"TODO"}
                   />
                 }
               />
             }
           />
           <Route
             path={`/:lang${Links.OnlineSafety}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.OnlineSafety} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={1} 
                     headerImageAltText={"TODO"}                 
                     introCta={
                       <>
                         <BoundButton 
                           type="primary" 
                           isCTA={true} 
                           text="Learn More" 
                           binding={TextBindings.LabelLearnMore} 
                           onClick={() => {setBrowseSafelyVisible(prevState => !prevState)}}
                         />
                         <BrowseSafelyModal visible={browseSafelyVisible} onCancel={() => setBrowseSafelyVisible(false)} />
                       </>
                     }
                   />
                 }
               />
             }
           />
           <Route
             path={`/:lang${Links.SexualConsent}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.SexualConsent} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={2} 
                     headerImageAltText={"TODO"}
                     moreBinding={TextBindings.TextWhatIsHappening}
                   />
                 }
               />
             }
           />
           <Route
             path={`/:lang${Links.Stalking}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.StalkingHarassment} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={2} 
                     headerImageAltText={"TODO"}
                     moreBinding={TextBindings.TextWhatIsHappening}
                   />
                 }
               />
             }
           />
           <Route
             path={`/:lang${Links.TypesOfSupport}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.TypesOfSupport} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={c?.Code?.iv === "US" ? 1 : 2}
                     headerImageAltText={"TODO"}
                   />
                 }
               />
             }
           />
           <Route
             path={`/:lang${Links.FormsOfAbuse}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.FormsOfAbuse} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={1}
                     headerImageAltText={"TODO"}
                   />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.HowCanIHelp}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.HowCanIHelp} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={1}
                     headerImageAltText={"TODO"}
                   />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.Leaving}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.Leaving} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={1}
                     headerImageAltText={"TODO"}
                   />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.SafetyGuidance}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.SafetyGuidance} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={1}
                     headerImageAltText={"TODO"}
                   />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.AdditionalGuide}`}
             element={
               <DynamicRoute
                 binding={GuideBindings.Additional} 
                 bindingStoreKey={StorageKeys.GuideKey}
                 component={
                   <Guide
                     template={c?.Code?.iv === "US" ? 2 : 1}
                     headerImageAltText={"TODO"}
                   />
                 }
               />
             }
           />
           {/* End Guides */}
           {/* Pages */}
           <Route 
             path={`/:lang${Links.PrivacyPolicy}`}
             element={
               <DynamicRoute
                 binding={PageBindings.PrivacyPolicy} 
                 bindingStoreKey={StorageKeys.PageKey}
                 component={<Page/>}
               />
             }
           />
           <Route 
             path={`/:lang${Links.About}`}
             element={
               <DynamicRoute
                 binding={PageBindings.About} 
                 bindingStoreKey={StorageKeys.PageKey}
                 component={<Page/>}
               />
             }
           />
           <Route 
             path={`/:lang${Links.SupportServices}`}
             element={
               <DynamicRoute
                 binding={PageBindings.LocateSupport} 
                 bindingStoreKey={StorageKeys.PageKey}
                 component={
                   <ServiceDirectory isHelplines={false} />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.NationalHelplines}`}
             element={
               <DynamicRoute
                 binding={PageBindings.Helplines} 
                 bindingStoreKey={StorageKeys.PageKey}
                 component={
                   <ServiceDirectory isHelplines={true} />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.Journal}`}
             element={
               <DynamicRoute
                 binding={PageBindings.Journal} 
                 bindingStoreKey={StorageKeys.PageKey}
                 component={
                   <JournalPage />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.Resources}`}
             element={
               <DynamicRoute
                 binding={ResourceBindings.Links} 
                 bindingStoreKey={StorageKeys.ResourceKey}
                 component={
                   <ResourcePage />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.Feedback}`}
             element={
               <DynamicRoute
                 binding={PageBindings.Feedback} 
                 bindingStoreKey={StorageKeys.PageKey}
                 component={
                   <Page>
                     <FeedbackPage />
                   </Page>
                 }
               />
             }
           />
           {/* End Pages */}
           {/* Hubs */}
           <Route 
             path={`/:lang${Links.RecogniseAbuse}`}
             element={
               <DynamicRoute
                 override
                 binding={PageBindings.RecogniseAbuse}
                 bindingStoreKey={StorageKeys.PageKey}
                 component={
                   <FeatureHub headerImageAltText="TODO"
                     heroCards={[
                        HeroCards.AmIAtRisk,
                        HeroCards.FriendOrFamilyAtRisk,
                        HeroCards.Journal
                     ]}
                   />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.UnderstandAbuse}`}
             element={
               <DynamicRoute
                 override
                 binding={PageBindings.UnderstandAbuse}
                 bindingStoreKey={StorageKeys.PageKey}
                 component={
                   <FeatureHub headerImageAltText="TODO"
                     navCards={[
                        NavCards.FormsOfAbuse,
                        NavCards.SexualConsent,
                        NavCards.StalkingHarassment,
                        NavCards.DispellingMyths,
                        NavCards.Resources,
                     ]}
                   />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.SeekingSupport}`}
             element={
               <DynamicRoute
                 override
                 binding={PageBindings.SeekingSupport}
                 bindingStoreKey={StorageKeys.PageKey}
                 component={
                   <FeatureHub headerImageAltText="TODO"
                     navCards={[
                        NavCards.Leaving,
                        NavCards.TypesOfSupport,
                        NavCards.AdditionalGuide,
                        NavCards.SupportServices,
                        NavCards.NationalHelplines,
                        NavCards.Resources,
                     ]}
                   />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.ProvidingSupport}`}
             element={
               <DynamicRoute
                 override
                 binding={PageBindings.ProvidingSupport}
                 bindingStoreKey={StorageKeys.PageKey}
                 component={
                   <FeatureHub headerImageAltText="TODO"
                     navCards={[
                        NavCards.HowCanIHelp,
                        NavCards.TypesOfSupport,
                        NavCards.Leaving,
                        NavCards.AdditionalGuide,
                        NavCards.SupportServices,
                        NavCards.NationalHelplines,
                        NavCards.EmployersPage,
                        NavCards.Resources,
                     ]}
                   />
                 }
               />
             }
           />
           {/* End Hubs */}
           {/* Questionnaires */}
           <Route 
             path={`/:lang${Links.AmIAtRisk}`}
             element={
               <DynamicRoute
                 binding={QuestionnaireBindings.AmIAtRisk} 
                 bindingStoreKey={StorageKeys.QuestionnaireContentKey}
                 component={
                   <Questionnaire headerImageAltText="TODO" />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.FriendFamilyAtRisk}`}
             element={
               <DynamicRoute
                 binding={QuestionnaireBindings.FriendFamilyAtRisk} 
                 bindingStoreKey={StorageKeys.QuestionnaireContentKey}
                 component={
                   <Questionnaire headerImageAltText="TODO" />
                 }
               />
             }
           />
           <Route 
             path={`/:lang${Links.DispellingMyths}`}
             element={
               <DynamicRoute
                 binding={QuestionnaireBindings.DispellingMyths} 
                 bindingStoreKey={StorageKeys.QuestionnaireContentKey}
                 component={
                   <Questionnaire headerImageAltText="TODO" />
                 }
               />
             }
           />
           {/* End Questionnaires */}
         </>
        }
      </Routes>
    </Layout>
  );
};

export default SiteManifest;
