import { FC, useMemo } from "react";
import { FurtherResourceMapping, LinksUnion, SearchableSiteMap, TextBindings } from "../../../compiler/enums";
import { BoundButton } from "../BoundButton";
import { useLink } from "../../utils/common";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { FurtherResource } from "../../../compiler/types";
import useContentContext from "../../hooks/useContentContext";
import { decode } from "html-entities";
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from "../../hooks/useActiveCountry";

import "./ExploreFurtherResources.css";

export type FurtherResourceButtonProps = {
  furtherResource: FurtherResource
};

export const FurtherResourceButton: FC<FurtherResourceButtonProps> = ({ furtherResource }) => {
  const c = useActiveCountry();
  const { ct } = useCountryTranslation(c?.Code.iv);
  const link = furtherResource?.Link ? FurtherResourceMapping[furtherResource.Link]?.link : null;
  const contentBinding = link ? SearchableSiteMap[link] : null;
  const { getContentFromBinding } = useContentContext();
  
  const content = useMemo(() => {
    if (!contentBinding) {
      return null;
    }

    return getContentFromBinding(contentBinding);
  }, [contentBinding, getContentFromBinding]);

  const internalLink = useLink(link as LinksUnion, true);

  const label = useMemo(() => {
    let label = link ? link.toString() : furtherResource.ExternalLink; // as a fallback, render the plain link

    const labelLength = furtherResource.Label?.trim().length;
    const hasLabel = labelLength && labelLength > 0;

    const labelBinding = furtherResource.Link ? FurtherResourceMapping[furtherResource.Link].binding : null;
    if (furtherResource.ExternalLink) {
      label = hasLabel ? furtherResource.Label : label;
    }
    else if (labelBinding) {
      label = decode(ct(`${labelBinding}.Text`));
    }
    else if (internalLink && content) {
      label = decode(content["Title"]) ?? label;
      label = hasLabel ? furtherResource.Label : label;
    }
    return label;
  }, [content, ct, furtherResource.ExternalLink, furtherResource.Label, furtherResource.Link, internalLink, link]);
  
  if (!internalLink && !furtherResource.ExternalLink) {
    return null;
  }

  const externalCTA = !!furtherResource.ExternalLink;

  return (
    <BoundButton 
      className="further-resources-button"
      type="primary" 
      isCTA={true} 
      text={label}
      onClick={() => externalCTA ? window.open(furtherResource.ExternalLink) : undefined} 
      popConfirmOnClick={externalCTA}
      popConfirmBinding={TextBindings.TextOpenNewTab}
      iconRight={externalCTA ? faArrowUpRightFromSquare : undefined}
      link={externalCTA ? undefined : internalLink}
    />
  );
};