import { JournalEntryRequest } from "../features/journal/types";
import api from "../util/api";

const createJournalEntry = (journalEntry: JournalEntryRequest) => {
  return api().post(`Journal`, journalEntry)
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

const journalService = {
  createJournalEntry,
};

export default journalService;