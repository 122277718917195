import { ContentStorageKeys, StorageKeys } from '../../config/Storage';
import useContentContext from './useContentContext';
import useAppSettingsContext from './useAppSettingsContext';
import contentService from '../../services/content-service';
import { Language } from '../../compiler/types';
import useConfigContext from './useConfigContext';
import { Themes } from '../../compiler/enums';
import i18n from '../../util/i18n';
import i18nService from '../../services/i18n-service';

const useLoader = () => {
  const { setTranslationsLoading } = useConfigContext();
  const { content, setContentLoading, setContent } = useContentContext();
  const { setAppSettings, setAppSettingsLoading } = useAppSettingsContext();

  const fetchCountries = async () => {
    let data = await contentService.getCountries()
      .catch((err) => {
        console.error('useLoader: Unable to retrieve Countries', err.status);
        return null;
      });
      
    setContent(StorageKeys.CountryStore, data);
    setContentLoading(StorageKeys.CountryStore, false); 
    return { ...content, [StorageKeys.CountryStore]: data };
  };

  const fetchCountrySpecificContent = async (country, content_store?, priorityContentKey?: null | StorageKeys) => {
    let ctx = {};
    if (content_store) {
      ctx = { ...content_store };
    } else {
      ctx = {...content};
    }
    console.log('fetching country-specific content', country);
    // merge "Resources" from local keys
    // ** review ** -- to refactor
    // Skip loading app settings as they're stored in appSettings context already
    // Skip loading countries, pages, guides and questionnaires as they're loaded prior
    // Skip accordions & menus as they're not used in web
    var workingStorageKeys: Array<{key: StorageKeys, name: string}> = 
      ContentStorageKeys.filter(x => x.name !== "AppSettings" && x.name !== "Countries"  && x.name !== "Pages"
        && x.name !== "Accordions" && x.name !== "Menus" && x.name !== "Questionnaires" && x.name !== "Guides")

    // if priorityContentKey is set, sort so that content is lazy-loaded based on site location
    if (priorityContentKey) {
      workingStorageKeys.sort(function (a, b) {
        if (priorityContentKey && a.key === priorityContentKey && b.key !== priorityContentKey)
          return -1;
        if (priorityContentKey && a.key !== priorityContentKey && b.key === priorityContentKey)
          return 1;
        return 0;
      });
    }

    workingStorageKeys.map(async (storageKey) => {
      return contentService.getCountrySpecificContent(storageKey.name, country)
        .then((content) => {
          ctx[storageKey.key] = content;
          setContent(storageKey.key, content);
          setContentLoading(storageKey.key, false); 
        })
        .catch((err) => {
          console.error(`ContentContext: Unable to retrieve ${storageKey} data`, err.status, err.message);
          return null;
        });
    });
  };

  const getLanguagesByCountry = (country_store, country): Language[] => {
    const selectedCountry = country_store.filter(c => c.Code.iv === country);
    if (selectedCountry.length > 0) {
      const mappedLanguages = selectedCountry[0].Languages.iv.map(l => {
        return {
          value: l.Code,
          label: l.Language,
        };
      });
      return mappedLanguages;
    }
    return [];
  };

  const setTranslations = async (country, lang, targetI18n?, textOnly?, setLoading?) => {
    setLoading ? setLoading(true) : setTranslationsLoading(true);
    return new Promise<void>(async (resolve, reject) => {
      await i18nService.LoadWebTranslations(country, lang, (translations, content) => {
        // pre-load any content into contentContext
        const contentKeys: StorageKeys[] = Object.keys(content) as StorageKeys[];
        if (contentKeys.length > 0) {
          contentKeys.forEach((contentKey) => {
            setContent(contentKey, content[contentKey]);
            setContentLoading(contentKey, false); 
          });
        }

        setLoading ? setLoading(false) : setTranslationsLoading(false);
        resolve();
      }, targetI18n, textOnly);
    });
  };

  const setNullTranslation = async () => {
    console.log('** set null translation **');
    i18n.changeLanguage("VF");
    document.documentElement.setAttribute('lang', 'en');
  };

  const getWebTheme = (theme: string) => {
    if (!theme) {
      return null;
    }

    theme = theme.toLowerCase() + "-theme";
    if (!Object.values<string>(Themes).includes(theme)) { // string-based enum requires type assertion
      throw Error(`Mismatched theme value: ${theme}`);
    }

    return theme;
  };

  const fetchAppSettings = async (country, customSetAppSettings?, customSetLoading?) => {
    customSetLoading ? customSetLoading(true) : setAppSettingsLoading(true);

    let result = await contentService.getAppSettings(country)
      .catch((err) => {
        console.error(`useLoader: Unable to retrieve AppSettings`, err.status, err.message);
        return null;
      });

    const asContent = result;
    const mappedAppSettings = {
      defaultLanguageCode: asContent?.DefaultLanguageCode?.iv,
      exitUrl: asContent?.WebsiteExitUrl?.iv,
      webHeroImage: asContent?.WebHeroImage?.iv,
      locationFiltering: asContent?.LocationFilteringEnabled?.iv,
      iosInstallUrl: asContent?.IosInstallUrl?.iv,
      androidInstallUrl: asContent?.AndroidInstallUrl?.iv,
      findHelpSearchEnabled: asContent?.FindHelpSearchEnabled?.iv,
      additionalGuideEnabled: asContent?.AdditionalGuideEnabled?.iv,
      newResourcesEnabled: asContent?.NewResourcesEnabled?.iv,
      webTheme: getWebTheme(asContent?.WebsiteTheme?.iv),
      feedbackModalQuestion: asContent?.FeedbackModalQuestion?.iv,
      submitServiceRequestEnabled: asContent?.SubmitServiceRequestEnabled?.iv,
    };

    customSetAppSettings ? customSetAppSettings(mappedAppSettings) : setAppSettings(mappedAppSettings);
    customSetLoading ? customSetLoading(false) : setAppSettingsLoading(false);

    return mappedAppSettings;
  };

  return { getLanguagesByCountry, setTranslations, setNullTranslation, fetchAppSettings, fetchCountries, fetchCountrySpecificContent };
};

export default useLoader;
