import { FC } from "react";
import { Page } from "../../common/components/page-component/Page";

import { decode } from 'html-entities';
import { LocalizedPageContent } from "../../compiler/types";

import useConfig from "../../config/hooks/useConfig";
import { getMediaUrl, useIsMobile } from "../../common/utils/common";
import JournalForm from "./components/JournalForm";

import "./JournalPage.css";
import DefaultImage from "../../common/components/DefaultImage";

type JournalPageProps = {
  content?: LocalizedPageContent,
  headerImageAltText?: string,
};

export const JournalPage: FC<JournalPageProps> = ({
  content, headerImageAltText
}) => {
  const journalContent = content;
  const config = useConfig();
  const isMobile = useIsMobile();
  const mediaSrc = getMediaUrl(config?.assetUrl, journalContent?.Icon);

  console.log('journalContent', journalContent);
  return (
    <Page furtherResources={journalContent?.FurtherResources}>
      <div id="journal-page">
        <div className="color section header">
          <div>
            { journalContent?.Title && (
              <h1>
                { decode(journalContent?.Title) }
              </h1>
            )}
            { journalContent?.Content && (
              <div className="journal-page-content" dangerouslySetInnerHTML={{
                  __html: decode(journalContent?.Content)
                }}
              />
            )}
          </div>
          {/*{ mediaSrc && (*/}
            <DefaultImage height={isMobile ? 180 : 300} width={isMobile ? 240 : 400} src={journalContent?.Icon ?? "https://picsum.photos/400/300"} alt={headerImageAltText} />
          {/*)}*/}
        </div>
        <div className="white section lato">
          <JournalForm />
        </div>
      </div>
    </Page>
  );
};