import { FC, PropsWithChildren, useMemo } from 'react';
import { TextBindings } from '../../compiler/enums';
import { Link, LinkProps } from 'react-router-dom';
import { scrollToTop } from '../utils/common';

export const DefaultLink: FC<PropsWithChildren<LinkProps>> = ({ children, ...props }) => {

  return (
    <Link 
      {...props}
      onClick={(e) => {
        scrollToTop();
        props.onClick && props.onClick(e);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          scrollToTop();
          // onClick?
        }
      }}
    >
      {children}
    </Link>
  );
};

export default DefaultLink;
