import { FC } from 'react';

type BoxTagProps = {
  text: string,
  color: string,
  borderColor: string | null,
};

export const BoxTag: FC<BoxTagProps> = ({ text, color, borderColor }) => {
  return (
    <div className="box-tag" style={{ border: `1px solid ${borderColor ?? "black"}`, backgroundColor: color }}>
      {text}
    </div>
  );
};
