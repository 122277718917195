import { FC } from 'react';
import { useIsMobile } from '../utils/common';
import { HeroCard, HeroCardProps } from './HeroCard';
import { Carousel } from 'antd';

export type HeroCardGroupProps = {
  heroCards: Array<HeroCardProps>,
  unwrap: boolean;
};

export const HeroCardGroup: FC<HeroCardGroupProps> = ({ heroCards, unwrap }) => {

  const isMobile = useIsMobile();

  const cardElements = heroCards && heroCards.map((heroCard: HeroCardProps, index: number) => {
    return <HeroCard key={index} {...heroCard} />
  });

  return (
    <div className={`hero-cards ${unwrap ? " unwrap" : ""}`}>
       { isMobile && !unwrap &&  (
        <Carousel arrows dotPosition={"bottom"} draggable infinite>
          {cardElements}
        </Carousel>
      )}
       { (!isMobile || unwrap) && (
        <>
          {cardElements}
        </>
      )}
    </div>
  );
};
