import { FC, useEffect, useMemo } from 'react';
import { BindingsUnion, GuideBindings, Links, PageBindings, QuestionnaireBindings, ResourceBindings } from '../../../compiler/enums';
import { StorageKeys } from '../../../config/Storage';
import useContentContext from '../../../common/hooks/useContentContext';
import { Navigate } from "react-router-dom";
import { Loading } from '../../../common/components/Loading';
import { getLangFromUrl } from '../../../util/functions';
import React from 'react';

export type DynamicRouteProps = {
  binding: BindingsUnion,
  bindingStoreKey: StorageKeys,
  component: JSX.Element,
  override?: boolean // TODO: remove after dev
}

export const DynamicRoute: FC<DynamicRouteProps> = ({
  binding, bindingStoreKey, component, override
}) => {
  const l = getLangFromUrl();
  const { getContent, loading } = useContentContext();

  const content = useMemo(() => {
    return getContent(bindingStoreKey, binding);
  }, [binding, bindingStoreKey, getContent]);

  // contentExists is null until contentKey has loaded, then true/false
  const contentExists = useMemo(() => {
    if (loading[bindingStoreKey]) {
      return null;
    }

    return content && (!content["Title"] || content["Title"] !== "NA");
  }, [bindingStoreKey, content, loading]);

  useEffect(() => {
    // TODO: Validate other content items here
    if ((Object.values<string>(GuideBindings).includes(binding) && bindingStoreKey !== StorageKeys.GuideKey)
     || (Object.values<string>(PageBindings).includes(binding) && bindingStoreKey !== StorageKeys.PageKey)
     || (Object.values<string>(ResourceBindings).includes(binding) && bindingStoreKey !== StorageKeys.ResourceKey)
     || (Object.values<string>(QuestionnaireBindings).includes(binding) && bindingStoreKey !== StorageKeys.QuestionnaireContentKey)) {
      throw Error(`Check routes: Mismatching store key & binding -- ${binding}`);
    }
  }, [binding, content, bindingStoreKey]);

  if (loading[bindingStoreKey] === true || contentExists === null) {
    return <Loading page />;
  }
  else if (!contentExists && !override) {
    return (
      <Navigate to={`/${l}${Links.NotFound}`} />
    );
  }

  return (
    <>
      { React.cloneElement(component, { content, binding }) }
    </>
  );
};
