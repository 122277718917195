import { FC, ReactNode } from "react";
import { decode } from "html-entities";

import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PossibleAnswer } from "../../../compiler/types";
import useWebAnalyticsContext from "../../../common/hooks/useWebAnalyticsContext";
import { Events, Templates, Origins } from 'brightsky-3/constants/Logging';
import { BoundButton } from "../../../common/components";
import { Space } from "antd";
import { QuestionnaireBindings } from "../../../compiler/enums";

type AnswerButtonsProps = {
  possibleAnswers: Array<PossibleAnswer>,
  currentQuestionIndex: number,
  qIndex: number,
  answers: Array<PossibleAnswer>,
  setAnswers: (answers: Array<PossibleAnswer>) => void,
  binding: QuestionnaireBindings,
};

export const AnswerButtons: FC<AnswerButtonsProps> = ({
  possibleAnswers,
  currentQuestionIndex,
  qIndex,
  answers,
  setAnswers,
  binding,
}) => {
  const { logEvent } = useWebAnalyticsContext();

  const recordAnswer = (index, answer, answerName) => {
    const newAnswers = [...answers];
    newAnswers[index] = {
       Value: answer,
       Title: answerName,
    };

    setAnswers(newAnswers);

    logEvent(
      Events.QuestionnaireAnswerEvent,
      Origins.QuestionnaireQuestion,
      Templates.questionnaire.answer(
        binding,
        currentQuestionIndex + 1,
        answer
      )
    );
  };

  if (!possibleAnswers) {
    return null;
  }

  return (
    <Space className="questionnaire-answer-button-container">
      { possibleAnswers.map((a, i) => {
        let icon: ReactNode | null = null;

        if (answers[qIndex]?.Title === a.Title) {
          icon = <FontAwesomeIcon icon={faCheckCircle} className="brightsky-questionnaire-answers-left-check" />;
        }

        const saveAnswer = () => {
          recordAnswer(qIndex, a.Value, a.Title);
        };

        return (
          <BoundButton
            className="brightsky-questionnaire-answer-button"
            key={i.toString()}
            icon={icon}
            text={decode(a.Title)}
            onClick={saveAnswer}
            type="default"
          />
        );
      })}
    </Space>
  );
};
