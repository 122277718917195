import { FC } from 'react';
import { Input } from 'antd';

type DefaultTextAreaProps = {
  onChange?: (e: any) => void,
  onBlur?: (e: any) => void,
  placeholder?: string | null,
  value: any,
  disabled?: boolean,
  id?: string,
  name?: string,
  className?: string,
}

export const DefaultTextArea: FC<DefaultTextAreaProps> = ({ onChange, onBlur, value, placeholder, id, name, className, disabled = false }) => {
  const { TextArea } = Input;
  return (
    <TextArea
      id={id}
      name={name}
      className={`default-textbox ${className ?? ''}`}
      placeholder={placeholder ?? undefined}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
    />
  );
};
