import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

type IconTagProps = {
  text: string, 
  icon: IconDefinition
};

export const IconTag: FC<IconTagProps> = ({ text, icon }) => {
  return (
    <div>
      <FontAwesomeIcon icon={icon} /> {text}
    </div>
  );
};
