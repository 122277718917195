import { Layout, Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import CountryToggle from "../components/CountryToggle";
import useConfigContext from "../hooks/useConfigContext";
import ConfigModal from "../../features/home/ConfigModal";
import { Loading } from "../components/Loading";
import { useCountryTranslation } from 'brightsky-3';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';

import { BoundButton } from "../components/BoundButton";
import { faArrowRight, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { faBars, faPhone } from "@fortawesome/pro-regular-svg-icons";
import useAppSettingsContext from "../hooks/useAppSettingsContext";
import { useNavigate } from "react-router-dom";
import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { decode } from "html-entities";
import useActiveCountry from "../hooks/useActiveCountry";
import Footer from "./components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TSkeleton } from "../components/TranslationSkeleton";
import useWebAnalyticsContext from "../hooks/useWebAnalyticsContext";
import { ExitSiteSafelyModal } from "./components/ExitSiteSafelyModal";
import { scrollToTop, useBinding, useBsLogo, useIsMobile, useLink, useTheme } from "../utils/common";
import { Links, PageBindings, TextBindings } from "../../compiler/enums";
import DefaultBanner from "../components/DefaultBanner";
import { DefaultTextbox } from "../components/DefaultTextbox";
import { FeedbackToggle } from "../components/feedback-toggle/FeedbackToggle";
import DefaultImage from "../components/DefaultImage";
import DefaultLink from "../components/DefaultLink";

import "./Layout.css";

const { Content } = Layout;

export const AppLayout: FC<PropsWithChildren<{className?: string}>> = ({ children, className }) => {
  const c = useActiveCountry();
  const { ct, l } = useCountryTranslation(c?.Code.iv);
  const { setHasConfirmedConfig, hasConfirmedConfig, setIsChangingCountry, loading, translationsLoading, hasConfirmedExitSafely } = useConfigContext();
  const { appSettings } = useAppSettingsContext();
  const { logEvent } = useWebAnalyticsContext();
  const searchResultsLink = useLink(Links.SearchResults);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [searchBarVisible, setSearchBarVisible] = useState<boolean>(isMobile);
  const [openKeys, setOpenKeys] = useState<Array<string>>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const overrideOpenKeys = useRef<Array<string> | null>(null);

  const bsLogo = useBsLogo();
  const theme = useTheme();

  const onExit = () => {
    window.location.href = appSettings?.exitUrl ?? "https://weather.com/";
  };

  const onCallEmergency = () => {
    const callEmergencyLink = decode(ct(`${TextBindings.LinkCallEmergencyServices}.Text`));
    if (Events?.AppCalledEmergency) {
      logEvent(Events.AppCalledEmergency, Origins.App, Templates.app.calledEmergency(callEmergencyLink));
    }
    window.open(callEmergencyLink);
  };

  useEffect(() => {
    setSearchBarVisible(isMobile);
  }, [isMobile]);

  const navItems: Array<{link: string, label: string}> = useMemo(() => {
    return [
      {
        link: `/${l}${Links.RecogniseAbuse}`,
        label: decode(ct(`${PageBindings.RecogniseAbuse}.Title`))
      },
      {
        link: `/${l}${Links.UnderstandAbuse}`,
        label: decode(ct(`${PageBindings.UnderstandAbuse}.Title`))
      },
      {
        link: `/${l}${Links.SeekingSupport}`,
        label: decode(ct(`${PageBindings.SeekingSupport}.Title`))
      },
      {
        link: `/${l}${Links.ProvidingSupport}`,
        label: decode(ct(`${PageBindings.ProvidingSupport}.Title`))
      },
      {
        link: `/${l}${Links.OnlineSafety}`,
        label: decode(ct(`${TextBindings.TitleOnlineSafety}.Text`))
      },
    ];
  }, [l, ct]);

  const layoutClassName = (className ?? "") + theme + (window.location.pathname === "/" ? " blur" : "");

  const callLabel = useBinding(`${TextBindings.NavTabCall}.Text`);
  const formattedCallLabel = isMobile ? callLabel?.split(" ")[1] : callLabel; // for mobile, only show the #

  const emergencyCallButton = (
    <BoundButton
      iconRight={faPhone}
      onClick={onCallEmergency}
      text={formattedCallLabel ?? undefined}
      tooltipBinding="Web_Tooltip_Call999.Text"
      className="phone-button emergency-call-button"
      aria-label={"Call Emergency"}
    />
  );

  const headerSearchButton = (
    <div id={"header-search-button"} className={isMobile ? "mobile" : ""}>
      { !isMobile && (
      <BoundButton
        ghost
        icon={<FontAwesomeIcon icon={faSearch} />}
        onClick={() => {
          if (hasConfirmedConfig) {
            setSearchBarVisible(prevState => !prevState) // should confirm exit safely first, otherwise these overlap
          }
        }}
        ariaLabel={TextBindings.TextSearch}
        aria-label={"Search"}
      />
      )}
      { searchBarVisible && (
        <DefaultTextbox
          autoFocus
          className="header-search-bar lato"
          prefix={faSearch}
          placeholderBinding={TextBindings.PlaceholderSiteSearch}
          value={searchValue}
          onPressEnter={(evt) => {
            const castVal = evt.target["value"]?.trim();
            if (castVal && castVal.length > 0) {
              if (!isMobile) {
                setSearchBarVisible(false);
              } else {
                overrideOpenKeys.current = [];
              }
              navigate(`${searchResultsLink}${'?query=' + castVal}`);
              scrollToTop();

              setTimeout(() => { // give time for menu to close before clearing value
                setSearchValue(undefined);
              }, 250);
            }
          }}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      )}
    </div>
  );

  const searchAriaLabel = useBinding(`${TextBindings.TextSearch}.Text`);

  // reset search value when search bar hidden
  useEffect(() => {
    if (!searchBarVisible && searchValue) {
      setSearchValue(undefined);
    }
  }, [searchBarVisible, searchValue])

  if (loading) {
    return (
      <Loading className={className} />
    );
  } else {
    return (
      <div id={isMobile ? "mobile-layout" : "layout"} className={layoutClassName + (isMobile ? " mobile" : "")}>
        { !isMobile && ( // TODO: confirm this condition
          <ConfigModal visible={!hasConfirmedConfig && window.location.pathname !== "/"} closable={true} />
        )}
        <ExitSiteSafelyModal visible={!hasConfirmedExitSafely && !!appSettings} /> { /* should not appear on root config site */ }
        <FeedbackToggle />
        { !isMobile && ( // TODO: confirm this condition
          <>
            <DefaultBanner 
              className="call-emergency"
              text={"Is this an emergency? Call 999 for immediate assistance."}
              binding={TextBindings.BannerEmergency}
              ctaButton={emergencyCallButton}
            />
          </>
        )}
        <Header className="header navigation">
          <div className="left-right-container">
            <div className="left">
              <DefaultLink to={`${l}${Links.Home}`} id="home-logo-button">
                <DefaultImage height={isMobile ? 35 : 35} src={bsLogo} alt="brightsky logo" /> {/* TODO: alt binding */}
              </DefaultLink>
              { !isMobile && (
                <CountryToggle 
                  className="country-toggle" 
                  onClick={() => {
                    setHasConfirmedConfig(false); 
                    setIsChangingCountry(true);
                  }}
                />
              )}
            </div>
            <div className="right">
              <Menu 
                id="primary-navbar" 
                mode="horizontal"
                selectedKeys={[window.location.pathname]} 
                tabIndex={-1}
                overflowedIndicator={isMobile ? <FontAwesomeIcon icon={faBars} /> : undefined}
                onClick={(e) => {
                  // if search bar clicked, prevent toggling submenu
                  if (isMobile && e.key === "search-button" && e.domEvent["code"] !== "Enter") {
                    overrideOpenKeys.current = openKeys;
                  }
                }}
                openKeys={openKeys ?? []}
                onOpenChange={(e) => {
                  if (!isMobile) {
                    setOpenKeys(e);
                    return;
                  }

                  if (e.includes("search-button")) { // when hitting enter on search bar
                    setOpenKeys([]);
                  } else if (overrideOpenKeys.current) {
                    setOpenKeys(overrideOpenKeys.current);
                    overrideOpenKeys.current = null;
                  } else {
                    setOpenKeys(e);
                    if (e.length === 0) {
                      setTimeout(() => { // give time for menu to close before clearing value
                        setSearchValue(undefined);
                      }, 250);
                    }
                  }
                }}
              >                
                { isMobile && (
                  <Menu.Item 
                    key={"search-button"} 
                    className={`bs-menu-item search-item${translationsLoading ? " loading" : ""} ${theme}`} 
                    style={{ backgroundColor: 'transparent' }}
                    aria-label={searchAriaLabel ?? "Search"}
                  >
                    <div className="menu-item-container">
                      { headerSearchButton }
                    </div>
                  </Menu.Item>
                )}
                { navItems && navItems.map((navItem) => {
                  return (
                    <Menu.Item 
                      key={navItem.link} 
                      className={`bs-menu-item${translationsLoading ? " loading" : ""}`} 
                      style={{ backgroundColor: 'transparent' }}
                      aria-label={navItem.label}
                    >
                      <div className="menu-item-container">
                        <TSkeleton>
                          <DefaultLink to={navItem.link} className="hover-underline lato">
                            {navItem.label}
                          </DefaultLink>
                        </TSkeleton>
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu>
              { !isMobile && headerSearchButton }
            </div>
          </div>
          <div>
            { isMobile && emergencyCallButton }
            { /* TODO: use binding & trim binding for mobile */ }
            <BoundButton 
              binding={TextBindings.ButtonExitSite} 
              text={isMobile ? "Exit" : "Exit Site"}
              id="exit-btn" 
              iconRight={faArrowRight} 
              onClick={() => onExit()}
            />
          </div>
        </Header>
        <Layout className="main">
          <Content className="content">
            <div id="content-container">
              {children}
            </div>
            <Footer />
          </Content>
        </Layout>
      </div>
    );
  }
}