import { FC, useRef, useState } from 'react';
import { Links, TextBindings } from '../../compiler/enums';
import { Form, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { BoundButton } from './BoundButton';

import { useNavigate } from "react-router-dom";
import { scrollToTop, useLink } from '../utils/common';
import { faMapMarkerAlt, faHeadset } from '@fortawesome/pro-regular-svg-icons';
import { DefaultTextbox } from './DefaultTextbox';
import useActiveCountry from '../hooks/useActiveCountry';
import { RedirectConfirmation, RedirectConfirmationRef } from './RedirectConfirmation';

type FindSupportSearchProps = {
  onSubmit?: Function;
  includeHelplines?: boolean;
  showBtnIcons?: boolean;
  noDisabled?: boolean;
  searchVal?: string | null;
};

export const FindSupportSearch: FC<FindSupportSearchProps> = ({ onSubmit, includeHelplines, showBtnIcons, searchVal, noDisabled }) => {
  const c = useActiveCountry();
  const [searchValue, setSearchValue] = useState<string | null>(searchVal ?? null);
  const [showHelp, setShowHelp] = useState<boolean>();
  const findSupportLink = useLink(Links.SupportServices);
  const nationalHelplinesLink = useLink(Links.NationalHelplines);
  const navigate = useNavigate();
  
  const handleSubmit = () => {
    if (!searchValue || searchValue.trim().length === 0) {
      setShowHelp(true);
      return;
    }

    if (onSubmit) {
      onSubmit();
    } else {
      navigate(`${findSupportLink}${'?query=' + searchValue}`);
      scrollToTop();
    }
  };

  const onConfirm = () => {
    // validate first
    if (!searchValue || searchValue.trim().length === 0) {
      setShowHelp(true);
      return;
    }

    handleSubmit();
  }

  const searchButton = (
    <BoundButton
      icon={showBtnIcons ? <FontAwesomeIcon icon={faMapMarkerAlt} /> : undefined}
      binding={TextBindings.ButtonLocateSupport}
      disabled={!searchValue && !noDisabled}
      text="Find Support"
      onClick={() => { onConfirm() }}
      onKeyDown={(e) => {
        if (e.key === "Enter") { onConfirm() }
      }}
    />
  );

  return (
    <Space size="middle" className="find-support-search">
      <Form.Item help={showHelp ? "Search value is required" : undefined}>
        <DefaultTextbox className="search-bar"
          prefix={faSearch}
          placeholderBinding={TextBindings.LabelEnterLocation}
          size="large"
          value={searchValue ?? undefined}
          onChange={(e) => {
            var castValue = e.target.value?.trim();
            if (castValue && castValue.length > 0 && showHelp) { // ensure there is a non-empty input before removing validation
              setShowHelp(false);
            }
            setSearchValue(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") { onConfirm() }
          }}
        />
      </Form.Item>
      { c?.Code.iv === "US" && (
        <RedirectConfirmation
          confirmationBinding={TextBindings.TextOpenNewTab}
        >
          { searchButton }
        </RedirectConfirmation>
      )}
      { c?.Code.iv !== "US" && (
        searchButton
      )}
      { includeHelplines && (
        <BoundButton 
          icon={showBtnIcons ? <FontAwesomeIcon icon={faHeadset} /> : undefined}
          binding={TextBindings.LabelNationalHelpline} 
          text="National Helplines" 
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            } else {
              navigate(nationalHelplinesLink);
              scrollToTop();
            }
          }}
          type="default"
        />
      )}
    </Space>
  );
};

export default FindSupportSearch;
