import { FC } from 'react';
import { TSkeleton } from './TranslationSkeleton';
import { NavCardProps } from './NavCard';
import { useNavigate } from 'react-router-dom';
import { scrollToTop, useBinding, useBoundCardTitle, useLink } from '../utils/common';
import { Links } from '../../compiler/enums';
import { getSimplePathname } from '../../util/functions';
import DefaultLink from './DefaultLink';

export const ShortcutCard: FC<NavCardProps> = ({
  titleBinding, textBinding, link
}) => {
  const navigate = useNavigate();

  if (!Object.values<string>(Links).includes(getSimplePathname(link))) {
    throw Error("ShortcutCard: Invalid link prop");
  }
  
  const boundTitle = useBoundCardTitle(titleBinding);
  const boundText = useBinding(`${textBinding}.Text`);
  const boundLink = useLink(link);

  return (
    <div 
      className="shortcut-card" 
      onClick={() => scrollToTop()} 
      tabIndex={0} 
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          navigate(boundLink);
          scrollToTop();
        }
      }}
    >
      <DefaultLink to={boundLink} tabIndex={-1}>
        <div className="card-body">
          <TSkeleton paragraph={{rows: 2}}>
            <div className="text-container">
              <h2 className="lato">{boundTitle}</h2>
              <div className="lato">{boundText}</div>
            </div>
          </TSkeleton>
        </div>
      </DefaultLink>
    </div>
  );
};
