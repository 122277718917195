import { FC } from 'react';
import { useIsMobile } from '../utils/common';
import { NavCard, NavCardProps } from './NavCard';
import { Carousel } from 'antd';

export type NavCardGroupProps = {
  navCards: Array<NavCardProps>,
};

export const NavCardGroup: FC<NavCardGroupProps> = ({ navCards }) => {

  const isMobile = useIsMobile();

  const cardElements = navCards && navCards.map((navCard: NavCardProps, index: number) => {
    return <NavCard key={index} {...navCard} />
  });

  return (
    <div className="nav-cards">
       { isMobile && (
        <Carousel arrows dotPosition={"bottom"} draggable infinite>
          {cardElements}
        </Carousel>
      )}
       { !isMobile && (
        <>
          {cardElements}
        </>
      )}
    </div>
  );
};
