import { useEffect } from 'react';
import SiteManifest from './features/navigation/SiteManifest';

import useLoader from './common/hooks/useLoader';
import { Loading } from './common/components/Loading';
import useWebAnalyticsContext from './common/hooks/useWebAnalyticsContext';
import useConfigContext from './common/hooks/useConfigContext';
import { getConsumerIdFromUrl, getCountrySubdomain, getLangFromUrl, getPriorityContentKey, isLocalIPConnection } from './util/functions';
import { useNavigate } from 'react-router';
import { StorageKeys } from "./config/Storage";
import useAppSettingsContext from './common/hooks/useAppSettingsContext';

export const Loader = function() {
  const loader = useLoader();
  const navigate = useNavigate();

  const { 
	loading, setCountry, setLanguages, setHasConfirmedConfig, setLanguage, setConfigLoading, currentTheme, 
	isChangingCountry, setCurrentTheme
  } = useConfigContext();
  const { appSettings } = useAppSettingsContext();

  const web_anal = useWebAnalyticsContext();

  const init = () => {
	return new Promise<void>(async (resolve, reject) => {
      const urlCountry = isLocalIPConnection() ? "US" : getCountrySubdomain(); // change this to test diff markets & config modal locally (on mobile)
      const urlLang = getLangFromUrl();

      var content_store = await loader.fetchCountries();
	  var countries = content_store[StorageKeys.CountryStore];

	  if (!countries) {
		throw Error("Loader Error: Countries not loaded");
	  }

      if (!urlCountry) {
		// if no country nor language is specified, then open up the config page and have them choose a country and a language
	    navigate("/");
		loader.setNullTranslation();

		// set country & translations to US/en by default, so that background text may render
	    setCountry(countries.find(x => x.Code.iv === "US"));
		loader.setTranslations("US", "en", null, true);
		setLanguage({ label: "English", value: "en" });
		web_anal.registerConsumer();

		resolve();
	  }

	  if (urlCountry) {
	    const url_consumer = getConsumerIdFromUrl();

	    // if at least the country is selected
	    setCountry(countries.filter(x => x.Code.iv === urlCountry)[0]);
			
	    const app_settings = await loader.fetchAppSettings(urlCountry);

	    const valid_langs = loader.getLanguagesByCountry(content_store[StorageKeys.CountryStore], urlCountry);
	    setLanguages(valid_langs);

		const default_lang = valid_langs.filter((x) => x.value === app_settings.defaultLanguageCode)[0];
		
	    if (!urlLang) {
		  // if country and no language, then pull up the site with the countries default language selected
		  loader.setTranslations(urlCountry, app_settings.defaultLanguageCode);
		  setLanguage(default_lang);
		  setHasConfirmedConfig(true);
		  navigate(`/${app_settings.defaultLanguageCode}/home`);

		  web_anal.registerConsumer(urlCountry, url_consumer);
		  
		  resolve();
	    } else {
	      const lang_match = valid_langs.filter((x) => x.value === urlLang)[0];
		  const priorityContentKey = getPriorityContentKey();
		  
		  if (lang_match) {
		    // if country and language are specified then bypass the config page
		    loader.setTranslations(urlCountry, urlLang)		    
			  .then(() => {
			    loader.fetchCountrySpecificContent(urlCountry, content_store, priorityContentKey);
			  });
			setLanguage(lang_match);
	        setHasConfirmedConfig(true);

		    web_anal.registerConsumer(urlCountry, url_consumer);
		    resolve();	
		  } else {
		    // if country and language are specified, but language is invalid, then pull up the site with the countries default language selected
		    loader.setTranslations(urlCountry, app_settings.defaultLanguageCode)
		      .then(() => {
				loader.fetchCountrySpecificContent(urlCountry, content_store, priorityContentKey);
			  });
			
		    setLanguage(default_lang);
		    setHasConfirmedConfig(true);
		    navigate(`/${app_settings.defaultLanguageCode}/home`);

		    web_anal.registerConsumer(urlCountry, url_consumer);
		    resolve();
		  }
	    }
	  }
	});
  };

  // leave dependency array empty
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
	setConfigLoading(true);
    init().then(() => {
	  setConfigLoading(false);
    });
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    // store currentTheme as "previous"
	if (currentTheme !== appSettings?.webTheme && appSettings?.webTheme && !isChangingCountry) {
      setCurrentTheme(appSettings?.webTheme);
    }
  }, [currentTheme, appSettings, isChangingCountry, setCurrentTheme]);
  
  // this warning doesn't matter if changing the language doesn't need to reload the site
 // if (!loading) {
 //   const urlLang = getLangFromUrl();
	//if (urlLang && urlLang !== language?.value && hasConfirmedConfig) {
	//  console.warn("New language detected in URL path except there was no page reload.", urlLang, language?.value);
	//}
 // }

  return (
	<>
	  { loading === true && (
	    <Loading fullscreen={true} />
	  )}
	  { loading === false && (
	    <SiteManifest />
	  )}
	</>
  );
}