import { ContentStorageKeys as BSContentStorageKeys } from 'brightsky-3';

export enum StorageKeys {
  TranslationKey = "PrimaryTranslationStore",
  ContentVersionKey = "ContentVersionStore",
  QuestionnaireContentKey = "QuestionnaireContentStore",
  AccordionContentKey = "AccordionContentStore",
  GuideKey = "GuideStore",
  MenuKey = "MenuStore",
  LanguagesKey = "LanguageStore",
  TagsKey = "TagsStorage",
  CurrentGuideKey = "CurrentGuideStore",
  CurrentLanguageKey = "CurrentLanguageStore",
  SettingsKey = "SettingsStore",
  AppSettingsKey = "AppSettingsStore",
  ListsKey = "ListsStore",
  AnalyticsKey = "AnalyticsStore",
  CountryStore = "CountryStore",
  ResourceKey = "ResourceStore",
  PageKey = "PageStore",
};

// USAGE: Adding storage keys here will automatically cache
//        the content on app startup
//        The "name" property should match that of the endpoint.
export const ContentStorageKeys: { key: StorageKeys, name: string }[] = BSContentStorageKeys.concat([
  { key: StorageKeys.ResourceKey, name: "Resources" },
  { key: StorageKeys.PageKey, name: "Pages" }
]);
