import { FC } from "react";
import { Page } from "../../common/components/page-component/Page";

import { decode } from 'html-entities';
import { LocalizedResourceContent } from "../../compiler/types";
import { ResourceCardGroup } from "../../common/components/ResourceCardGroup";

import "./ResourcePage.css";

type ResourcePageProps = {
  content?: LocalizedResourceContent,
};

export const ResourcePage: FC<ResourcePageProps> = ({
  content
}) => {
  const resourceContent = content;

  return (
    <Page furtherResources={resourceContent?.FurtherResources}>
      <div id="resource-page">
        <div className="color section header">
          { resourceContent?.Title && (
            <h1>
              { resourceContent?.Title }
            </h1>
          )}
          { resourceContent?.Description && (
            <div className="lato" dangerouslySetInnerHTML={{
                __html: decode(resourceContent?.Description)
              }}
            />
          )}
        </div>
        {resourceContent?.Resources && (
          <div className="white section">
            <ResourceCardGroup
              resourceCards={resourceContent?.Resources}
            />
          </div>
        )}
      </div>
    </Page>
  );
};