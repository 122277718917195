import { createContext, Dispatch, FC, useReducer, useMemo } from 'react';
import { CommonActions } from '../../compiler/types';
import { webAnalyticsContextReducer } from './reducers';

export interface WebAnalyticsContextType {
  loading: boolean,
  consumerId: string | null,
};

export type WebAnalyticsContextActions =
    CommonActions
    | { type: 'SET_CONSUMER_ID', payload: { consumerId: string; } }

export interface WebAnalyticsContextMemo {
  context: WebAnalyticsContextType;
  dispatch: Dispatch<WebAnalyticsContextActions>;
}

const defaultWebAnalyticsContext = {
  loading: false,
  consumerId: null,
};

export const WebAnalyticsContext = createContext<WebAnalyticsContextMemo>({
  context: defaultWebAnalyticsContext,
  dispatch: () => { }
});

export const WebAnalyticsContextProvider: FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [context, dispatch] = useReducer(webAnalyticsContextReducer, defaultWebAnalyticsContext);

  const webAnalyticsContextMemo = useMemo(() => {
    return { context, dispatch };
  }, [context, dispatch]);

  return (
    <WebAnalyticsContext.Provider value={webAnalyticsContextMemo}>
      {children}
    </WebAnalyticsContext.Provider>
  );
};
