import useConfigContext from '../../common/hooks/useConfigContext';
import useContentContext from '../../common/hooks/useContentContext';
import { StorageKeys } from "../../config/Storage";
import { useState, useMemo, useEffect, FC, useRef } from 'react';
import CountrySelect from '../../common/components/CountrySelect';
import { DefaultSelect } from '../../common/components';
import { Col, Form, Row, Spin } from 'antd';
import { DefaultModal } from '../../common/components/DefaultModal';
import useLoader from '../../common/hooks/useLoader';
import { BoundButton } from '../../common/components/BoundButton';
import useWebAnalyticsContext from '../../common/hooks/useWebAnalyticsContext';
import { Events, Templates } from 'brightsky-3/constants/Logging';
import useConfig from '../../config/hooks/useConfig';
import { useIsMobile, useTheme } from '../../common/utils/common';
import { Country, Language } from '../../compiler/types';
import { TextBindings } from '../../compiler/enums';
import { TSkeleton } from '../../common/components/TranslationSkeleton';
import i18n from '../../util/i18n';
import { AppSettings } from '../../common/contexts/AppSettingsContext';
import useAppSettingsContext from '../../common/hooks/useAppSettingsContext';
import { decode } from 'html-entities';
import { isLocalIPConnection } from '../../util/functions';

import "./ConfigModal.css";

export const ConfigModal: FC<{ visible?: boolean, closable?: boolean, className?: string }> = ({
  visible = true, closable = false, className = undefined
}) => {

  const env = useConfig();
  const { appSettings } = useAppSettingsContext();
  const { logEvent, consumerId } = useWebAnalyticsContext();
  const { getRawContent, getCountries } = useContentContext();
  const { languages, language, country, setHasConfirmedConfig, setIsChangingCountry, setConfigTranslationsLoading, configTranslationsLoading, isChangingCountry } = useConfigContext();
  const { setTranslations } = useLoader();
  const [form] = Form.useForm();
  const [countryRedirect, setCountryRedirect] = useState<{ isWebRedirect: boolean, WebRedirectURL: string | null }>({ isWebRedirect: false, WebRedirectURL: null });
  const [languageLoading, setLanguageLoading] = useState(false);
  const [appSettingsLoading, setAppSettingsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);
  const [selectedLanguages, setSelectedLanguages] = useState<Language[] | null>(languages);
  const [selectedAppSettings, setSelectedAppSettings] = useState<AppSettings | null>(appSettings);
  const isMobile = useIsMobile();
  const [configI18n, setConfigI18n] = useState<typeof i18n>(i18n.cloneInstance());
  const fieldsReset = useRef<boolean>(false);

  const theme = useTheme();
  const loader = useLoader();

  const countries = useMemo(() => {
    return getCountries();
  }, [getCountries]);

  useEffect(() => {
    const clone = i18n.cloneInstance();

    if (configI18n !== clone) {
      setConfigI18n(clone);
    }
    // intentionally re-render on these conditions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.options.resources, country]);

  // WEB REDIRECT DETAILS
  useEffect(() => {
    if (!country) {
      return;
    }

    const selectedCountry = countries.filter(c => c.Code.iv === country.Code.iv);
    setCountryRedirect({
      isWebRedirect: selectedCountry ? selectedCountry[0]?.WebRedirect?.iv : false,
      WebRedirectURL: selectedCountry ? selectedCountry[0]?.RedirectURL?.iv : null,
    });
  }, [countries, country]);

  const languageSelectLabel = useMemo(() => {
    const languageSelectBinding = `${selectedCountry?.Code.iv}_${TextBindings.LabelSelectLanguage}`;
    const boundLanguageSelect = configI18n.options.resources && selectedLanguage?.value ? configI18n.options.resources[selectedLanguage?.value]?.translation[languageSelectBinding]?.Text : null;

    if (!configTranslationsLoading && (!boundLanguageSelect || boundLanguageSelect === `${country?.Code.iv}_${languageSelectBinding}` || boundLanguageSelect === "NA")) {
      return "Select Language";
    }
    return decode(boundLanguageSelect);
  }, [selectedCountry?.Code.iv, configI18n.options.resources, selectedLanguage?.value, configTranslationsLoading, country?.Code.iv]);

  const buttonLabel = useMemo(() => {
    const goToWebsiteBinding = `${selectedCountry?.Code.iv}_${TextBindings.ButtonGoToWebsite}`;
    const boundGoToWebsiteText = configI18n.options.resources && selectedLanguage?.value ? configI18n.options.resources[selectedLanguage?.value]?.translation[goToWebsiteBinding]?.Text : null;

    if (!configTranslationsLoading && (!boundGoToWebsiteText || boundGoToWebsiteText === `${country?.Code.iv}_${goToWebsiteBinding}` || boundGoToWebsiteText === "NA"
      || boundGoToWebsiteText.trim() === "")) {
      return "Enter";
    }
    return decode(boundGoToWebsiteText);
  }, [selectedCountry?.Code.iv, configI18n.options.resources, selectedLanguage?.value, configTranslationsLoading, country?.Code.iv]);

  
  const countrySelectLabel = useMemo(() => {
    const countrySelectBinding = `${selectedCountry?.Code.iv}_${TextBindings.LabelSelectCountry}`;
    const boundCountrySelect = configI18n.options.resources && selectedLanguage?.value ? configI18n.options.resources[selectedLanguage?.value]?.translation[countrySelectBinding]?.Text : null;

    if (!configTranslationsLoading && (!boundCountrySelect || boundCountrySelect === `${country?.Code.iv}_${countrySelectBinding}` || boundCountrySelect === "NA"
      || boundCountrySelect.trim() === "")) {
      return "Select Country";
    }
    return decode(boundCountrySelect);
  }, [configI18n.options.resources, configTranslationsLoading, country?.Code.iv, selectedCountry?.Code.iv, selectedLanguage?.value]);

  const onCountrySelect = async (countryCode, country) => {
    setSelectedCountry(country);

    setSelectedLanguage(null);
    form.setFieldsValue({ language: null });

    // update web redirect
    setCountryRedirect({
      isWebRedirect: country.WebRedirect?.iv ?? false,
      WebRedirectURL: country.RedirectURL?.iv ?? null,
    });

    setLanguageLoading(true);

    const valid_langs = loader.getLanguagesByCountry(
	  getRawContent(StorageKeys.CountryStore), 
	  countryCode
	);

	setSelectedLanguages(valid_langs);// set default lang, then do everything else

    var appSettings = await loader.fetchAppSettings(countryCode, setSelectedAppSettings, setAppSettingsLoading);

	setSelectedLanguage(valid_langs.filter(x => x.value === appSettings.defaultLanguageCode)[0]);
    setTranslations(countryCode, appSettings.defaultLanguageCode, configI18n, true, setConfigTranslationsLoading);
	form.setFieldsValue({ language: appSettings.defaultLanguageCode});

    setLanguageLoading(false);
  };

  const onLanguageSelect = async (languageCode, language) => {
    setSelectedLanguage(language);
    configI18n?.changeLanguage(languageCode);

    if (Events?.SettingsChange) {
      logEvent(Events.SettingsChange, 'ConfigScreen_Web', Templates.settings.change('language', languageCode));
    }
  };

  const onConfirm = () => {
    if (!selectedCountry) {
      throw Error("Confirmed without country selection");
    }

    // for no-op confirmation, do not log or redirect
    // Need ref to imperatively close modal
    //if (selectedCountry === country && selectedLanguage === language) {
    //  return;
    //}

    if (Events?.SettingsChange) {
      logEvent(Events.SettingsChange, 'ConfigScreen_Web', Templates.settings.change('country', selectedCountry.Code.iv));
    }

    if (countryRedirect.isWebRedirect && countryRedirect.WebRedirectURL) {
      window.location.href = countryRedirect.WebRedirectURL;
    } else {
      if (env) {
        if (!selectedLanguage) {
          throw Error("Confirmed without language selection");
        }

        const localIPConnection = isLocalIPConnection();
        
        const baseCountryUri = localIPConnection ? env.baseCountryUri.replace('{localDomain}', window.location.hostname) : env.baseCountryUri.replace('{localDomain}', env.localDomain);
        const countryUrl = baseCountryUri.replace('{c}', selectedCountry.Code.iv.toLowerCase()).replace('{l}', selectedLanguage.value);
        window.location.href = `${countryUrl}/${consumerId}`;
      }
    }
  };

  const formValid = !!selectedCountry && (!!selectedLanguage || countryRedirect?.isWebRedirect === true);

  const footer = (
    <Row>
      <Col style={{ textAlign: 'right' }} span={24}>
        <BoundButton 
          onClick={onConfirm}
          text={!selectedCountry ? "Enter" : buttonLabel ?? "Enter"} 
          disabled={!formValid}
          loading={!!selectedCountry && configTranslationsLoading}
        />
      </Col>
    </Row>
  );
  
  useEffect(() => {
    if (visible && !fieldsReset.current && isChangingCountry) { // do not reset fields to selected country & lang on first config
      form.setFieldValue("country", country?.Code.iv);
      form.setFieldValue("language", language?.value);
      setSelectedCountry(country);
      setSelectedLanguage(language);
      fieldsReset.current = true;
    }
  }, [country, country?.Code.iv, form, language, language?.value, visible, isChangingCountry]);

  return (
    <DefaultModal
      visible={visible}
      footer={footer} 
      width={900}
      closable={closable}
      maskClosable={closable}
      onCancel={() => { 
        setHasConfirmedConfig(true);
        setIsChangingCountry(false);
        setSelectedLanguages(languages);
        setSelectedCountry(null);
        setSelectedLanguage(null);
        fieldsReset.current = false;
      }}
      className={"config-modal " + className + theme + (isMobile ? " mobile" : "")}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          country: null,
          language: selectedLanguage?.value,
        }}
      >
        <Form.Item name="country" required>
          <CountrySelect
            size="large"
            ariaLabel={"Select Country"}
            onChange={onCountrySelect}
            grid
            aria-label={countrySelectLabel}
            ignoreContext={!isChangingCountry} // ignore context country on initial config
          />
        </Form.Item>
        { languageLoading && (
          <Spin size="default" /> 
        )}
        { selectedLanguages && selectedLanguages.length > 0 && !(countryRedirect?.isWebRedirect === true) && !languageLoading && (
          <Form.Item 
            label={
              <TSkeleton size={"small"} customLoading={!languageSelectLabel}>
                {languageSelectLabel}
              </TSkeleton>
            } 
            name="language" 
            required
          >
            <DefaultSelect
              className="language-select"
              disabled={languageLoading} 
              loading={languageLoading} 
              ariaLabel={languageSelectLabel ?? "Select Language"}
              size="large" 
              onChange={(val: string) => {
                var language = selectedLanguages?.filter(x => x.value === val)[0];
                onLanguageSelect(val, language);
              }}
              items={selectedLanguages ?? undefined}
            />
          </Form.Item>
        )}
      </Form>
    </DefaultModal>
  );
};

export default ConfigModal;