import { FC, PropsWithChildren } from 'react';
import { Skeleton, SkeletonProps, Space } from 'antd';
import useConfigContext from '../hooks/useConfigContext';
import { useIsMobile } from '../utils/common';

export const TSkeleton: FC<PropsWithChildren<SkeletonProps | any>> = ({
  paragraph, active, children, width, size, siteHeader, customLoading,
}) => {

  const isMobile = useIsMobile();
  const { translationsLoading } = useConfigContext();

  if ((translationsLoading && customLoading === undefined) || customLoading === true) {
    if (siteHeader) {
      return (
        <Space className="site-header-skeleton" size="large" style={{ width: isMobile? "100%" : "unset" }}>
          <Skeleton.Input active={true} size={"large"} style={{ width: isMobile? "100%" : "600px" }} />
          <Skeleton.Input active={true} size={"large"} style={{ width: isMobile? "90%" : "400px" }} />
          <Skeleton.Input active={true} style={{ width: isMobile? "70%" : "500px" }} />
        </Space>
      )
    }

    if (paragraph) {
      return <Skeleton active={active ?? true} paragraph={paragraph} style={{width: width}} />;
    }

    return <Skeleton.Input active={true} size={size} style={{width: width}} />;
  }
  else {
    return children;
  }
};
