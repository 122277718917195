import axios from 'axios';

import useConfig from "../config/hooks/useConfig";
import { isLocalIPConnection } from './functions';
import devConfig from '../config/config.dev.json';

export default function useApi() {
  var config = useConfig();

  return axios.create({
    baseURL: isLocalIPConnection ? devConfig.apiUrl : config.apiUrl,
  });
}