import { FC } from 'react';
import { BoundButton } from './BoundButton';
import { TSkeleton } from './TranslationSkeleton';
import { TextBindings } from '../../compiler/enums';
import { useBinding, useBoundCardTitle, useLink } from '../utils/common';
import { NavCardProps } from './NavCard';
import DefaultImage from './DefaultImage';

export interface HeroCardProps extends NavCardProps {
  linkText?: string,
  linkBinding: TextBindings,
  imageSrc: string,
  alt: string,
};

export const HeroCard: FC<HeroCardProps> = ({
  titleBinding, textBinding, link, linkText, linkBinding, imageSrc, alt
}) => {
  const boundTitle = useBoundCardTitle(titleBinding);
  const boundText = useBinding(`${textBinding}.Text`);
  const boundLink = useLink(link);

  return (
    <div className="hero-card">
      <DefaultImage height={200} skeletonWidth={"100%"} src={imageSrc} alt={alt} />
      <div className="card-body">
        <div>
          <TSkeleton paragraph={{rows: 2}}>
            <u>
              <h2 className="lato">{boundTitle}</h2>
            </u>
            <span className="lato">{boundText}</span>
          </TSkeleton>
        </div>
        <BoundButton type="primary" text={linkText} binding={linkBinding} link={boundLink} size="large" />
      </div>
    </div>
  );
};
