import { PropsWithChildren, forwardRef, useImperativeHandle, useState } from 'react';
import { useBinding, useIsMobile, useTheme } from '../utils/common';
import { Popconfirm } from 'antd';
import { DefaultModal } from './DefaultModal';
import { TextBindings } from '../../compiler/enums';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

export interface RedirectConfirmationRef {
  setConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type RedirectConfirmationProps = {
  confirmationBinding: TextBindings | undefined,
};

export const RedirectConfirmation = forwardRef<RedirectConfirmationRef, PropsWithChildren<RedirectConfirmationProps>>(({
  confirmationBinding, children
}, ref) => {
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const theme = useTheme();
  
  const boundTitle = useBinding(`${confirmationBinding}.Text`);
  const cancelText = useBinding(`${TextBindings.LabelCancel}.Text`);

  useImperativeHandle(ref, () => {
    return {
      setConfirmationOpen
    };
  });

  if (!children || !React.isValidElement(children)) {
    return null;
  }

  // overrides onClick to open confirmation modal or popover
  const clonedChild = React.cloneElement(children as any, { onClick: () => setConfirmationOpen(true) });

  const onConfirm = (e) => {
    children.props.onClick && children.props.onClick(e);
    setConfirmationOpen(false);
  }

  if (isMobile) {
    return (
      <>
        { clonedChild }
        <DefaultModal
          className={"redirect-confirmation " + theme}
          visible={confirmationOpen}
          onOk={(e: any) => onConfirm(e)}
          onCancel={() => setConfirmationOpen(false)}
        >
          <div className="modal-content">
            <FontAwesomeIcon className="warning-icon" icon={faCircleExclamation} />
            {boundTitle}
          </div>
        </DefaultModal>
      </>
    );
  }

  return (
    <Popconfirm
      className={"redirect-confirmation"}
      title={confirmationBinding ? boundTitle : null}
      open={confirmationOpen}
      onConfirm={(e: any) => onConfirm(e)}
      onCancel={() => setConfirmationOpen(false)}
      cancelText={cancelText}
      okButtonProps={{
        className: theme
      }}
      cancelButtonProps={{
        className: theme
      }}
    >
      { clonedChild }
    </Popconfirm>
  );
});
