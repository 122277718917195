import { WebAnalyticsContextActions } from "./WebAnalyticsContext";
import { CommonActions } from "../../compiler/types";

export const setLoading = (loading: boolean): CommonActions => ({
  type: 'SET_LOADING',
  payload: { loading },
});

// Web Analytics Actions

export const setConsumerId = (consumerId: string): WebAnalyticsContextActions => ({
  type: 'SET_CONSUMER_ID',
  payload: { consumerId },
});
