import { ConsumerDataRequest, EventDataRequest } from "../compiler/types";
import api from "../util/api";

const registerConsumer = (consumerData: ConsumerDataRequest) => {
  return api().post(`Analytics/Register`, consumerData)
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

const logEvent = (eventData: EventDataRequest) => {
  return api().post(`Analytics/Event`, eventData)
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

const analyticsService = {
  registerConsumer,
  logEvent,
};

export default analyticsService;