import { LocationSearchRequest, UpdateLocationRequest } from "../features/find-support/types";
import api from "../util/api";

const getCountries = () => {
  return api().get('Content/Countries')
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

const getCountrySpecificContent = (storageKey: string, country: string, querySvg?: boolean) => {
  return api().get(`Content/${storageKey}?country=${country}` + (querySvg ? '&querySvg=true' : ''))
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const getCountrySpecificGuideContent = (country: string, querySvg?: boolean) => {
  return api().get(`Content/Guides?country=${country}` + (querySvg ? '&querySvg=true' : ''))
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const getCountrySpecificPageContent = (country: string) => {
  return api().get(`Content/Pages?country=${country}`)
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const getCountrySpecificQuestionnaireContent = (country: string) => {
  return api().get(`Content/Questionnaires?country=${country}`)
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const getHelplines = (country: string, searchFilters?: Array<string>) => {
  return api().get(`Content/Locations/Helplines?country=${country}` + (searchFilters && searchFilters.length > 0 ? `&tags=${searchFilters.join(',')}}` : ''))
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const getLocations = (request: LocationSearchRequest) => {
  return api().post(`Content/Locations`, request)
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const updateLocation = (l: string, request: UpdateLocationRequest) => {
  return api().post(`Content/LocationUpdateRequest?language=${l}`, request)
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const getAppSettings = (country: string) => {
  return api().get(`Content/appsettings?country=${country}`)
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const searchContent = (country: string, keyword: string) => {
  return api().get(`Content/Search?country=${country}&keyword=${keyword}`)
    .then((result) => { return result.data })
    .catch(error => Promise.reject(error));
}

const contentService = {
  getCountries,
  getCountrySpecificContent,
  getCountrySpecificGuideContent,
  getCountrySpecificPageContent,
  getCountrySpecificQuestionnaireContent,
  getHelplines,
  getLocations,
  getAppSettings,
  updateLocation,
  searchContent
};

export default contentService;