import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../hooks/useActiveCountry';
import useConfigContext from '../hooks/useConfigContext';
import { decode } from 'html-entities';
import { Links, LinksUnion, TextBindings, Themes } from "../../compiler/enums";
import blueBsLogo from "../../assets/blue-bs-logo.svg";
import greenBsLogo from "../../assets/green-bs-logo.svg";
import purpleBsLogo from "../../assets/purple-bs-logo.svg";
import blackBsLogo from "../../assets/black-bs-logo.svg";
import useAppSettingsContext from '../hooks/useAppSettingsContext';
import { useMediaQuery } from 'usehooks-ts';

// standardizes logic for nav card title bindings
export const useBoundCardTitle = (titleBinding) => {
  // use title binding as literal if not in TextBindings, or as textBinding
  const boundTitle = useBinding(!Object.values<string>(TextBindings).includes(titleBinding) ? `${titleBinding}` : `${titleBinding}.Text`);
  return boundTitle;
};

export const useIsMobile = () => {
  return useMediaQuery('(max-width: 999px)');
};

export const useIsSecondSmallestMobile = () => {
  return useMediaQuery('(max-width: 420px)');
};

// to adjust styling for header height = 110px
export const useIsSmallestMobile = () => {
  return useMediaQuery('(max-width: 260px)');
};

// to adjust styling guide template two column view
export const useIsVerticalGuideLayout = () => {
  return useMediaQuery('(max-width: 1300px)');
};

// shortcut to return binding if loaded
export const useBinding = (binding: string) => {
  const { isChangingCountry, savedCountry } = useConfigContext();

  const c = useActiveCountry();
  const { ct } = useCountryTranslation(isChangingCountry && savedCountry ? savedCountry.Code.iv : c?.Code.iv);
  const { translationsLoading } = useConfigContext();

  return translationsLoading ? null : decode(ct(binding));
};

// returns validated & localized link from link enum
export const useLink = (link: LinksUnion, ignoreCheck: boolean = false) => {
  if (!ignoreCheck && !Object.values<string>(Links).includes(link)) {
    throw Error("Invalid Link");
  }
  
  const c = useActiveCountry();
  const { l } = useCountryTranslation(c?.Code.iv);

  return `/${l}${link}`;
}

// returns themed logo src image
export const useBsLogo = () => {
  const { appSettings } = useAppSettingsContext();
  const theme = appSettings?.webTheme;

  if (theme === Themes.Purple) {
    return purpleBsLogo;
  } else if (theme === Themes.Green) {
    return greenBsLogo;
  } else if (theme === Themes.Blue) {
    return blueBsLogo;
  }
  
  return blackBsLogo; // default to black
}

// returns theme className
export const useTheme = () => {
  const { appSettings } = useAppSettingsContext();
  const { hasConfirmedConfig, isChangingCountry, currentTheme } = useConfigContext();
  
  const theme = appSettings?.webTheme ?? Themes.Plain;

  // prevent theme re-rendering when country modal is open
  if (isChangingCountry && currentTheme) {
    return ` ${currentTheme} `;
  }

  return ` ${!hasConfirmedConfig && !isChangingCountry ? Themes.Plain : theme} `; // spaces are intentional
}

export const getMediaUrl = (assetUrl, asset) => {
  return asset?.Media != null && asset?.Media?.length > 0 ? `${assetUrl}${asset?.Media[0]}` : null;
};

export const trimColon = (text: string | null) => {
  if (!text) {
    return text;
  }

  text = text.trim();

  if (text.at(text.length - 1) === ':') {
    text = text.substring(0, text.length - 1);
  }
  return text;
}

export const scrollToTop = () => { document.getElementsByClassName("ant-layout")[0].scrollTo({ top: 0 }); };