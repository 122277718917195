import React, { createContext, useMemo, FC, Dispatch, useReducer } from 'react';
import { TagComponent, ServiceDTO, MapRegion, CoordObj } from '../types';
import { CommonActions } from '../../../compiler/types';
import { locationSearchContextReducer } from './reducers';

export interface LocationSearchContextType {
  loading: boolean;
  tags: Array<TagComponent>,
  tagFilters: Array<string>,
  locationSearch: string | null,
  coordinates: CoordObj | null,
  mapRegion: MapRegion | null,
  serviceResults: Array<ServiceDTO>,
  selectedLocation: string | null,
};

export type LocationSearchContextActions =
  CommonActions
  | { type: 'SET_TAGS', payload: { tags: Array<TagComponent> } }
  | { type: 'SET_TAG_FILTERS', payload: { tagFilters: Array<string> } }
  | { type: 'SET_LOCATION_SEARCH', payload: { locationSearch: string | null } }
  | { type: 'SET_COORDINATES', payload: { coordinates: CoordObj } }
  | { type: 'SET_MAP_REGION', payload: { mapRegion: MapRegion } }
  | { type: 'SET_SERVICE_RESULTS', payload: { serviceResults: Array<ServiceDTO> } }
  | { type: 'SET_SELECTED_LOCATION', payload: { selectedLocation: string } }

export interface LocationSearchContextMemo {
  context: LocationSearchContextType;
  dispatch: Dispatch<LocationSearchContextActions>;
}

const defaultLocationSearchContext = {
  loading: false,
  tags: [],
  tagFilters: [],
  locationSearch: null,
  coordinates: null,
  mapRegion: null,
  serviceResults: [],
  selectedLocation: null,
};

export const LocationSearchContext = createContext<LocationSearchContextMemo>({
  context: defaultLocationSearchContext,
  dispatch: () => { }
});

export const LocationSearchContextProvider: FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [context, dispatch] = useReducer(locationSearchContextReducer, defaultLocationSearchContext);

  const locationSearchContextMemo = useMemo(() => {
    return { context, dispatch};
  }, [context, dispatch]);

  return (
    <LocationSearchContext.Provider value={locationSearchContextMemo}>
      {children}
    </LocationSearchContext.Provider>
  );
};
