export const getFlagClassName = (countryCode: string): string => {
  if (!countryCode) {
    return '';
  }

  var flagClassName = countryCode.toLowerCase();

  // fix flag class name conversions
  switch (countryCode) {
    case "CS":
      flagClassName = "cz";
      break;
    case "UK":
      flagClassName = "gb";
    }

  return flagClassName;
};