import { FC, PropsWithChildren, useMemo } from 'react';
import { Button, ButtonProps } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../hooks/useActiveCountry';
import { decode } from 'html-entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight as faArrowRightSolid } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight as faArrowRightRegular } from '@fortawesome/pro-regular-svg-icons';
import { TextBindings } from '../../compiler/enums';
import { BaseButtonProps } from 'antd/es/button/button';
import { scrollToTop, trimColon as trimColonFunc, useBinding } from '../utils/common';
import { AssetDTO } from '../../compiler/types';
import DefaultImage from './DefaultImage';
import DefaultLink from './DefaultLink';
import { RedirectConfirmation } from './RedirectConfirmation';

export type BoundButtonProps = {
  binding?: TextBindings,
  text?: string,
  iconRight?: IconDefinition,
  tooltipBinding?: string,
  link?: string,
  isCTA?: boolean, 
  type?: BaseButtonProps["type"] | "light-default",
  trimColon?: boolean,
  assetId?: string,
  asset?: AssetDTO,
  ariaLabel?: TextBindings,
  popConfirmOnClick?: boolean,
  popConfirmBinding?: TextBindings,
}

export const BoundButton: FC<PropsWithChildren<Omit<ButtonProps, 'type'> & BoundButtonProps>> = ({
  binding,
  text,
  type = 'primary',
  iconRight,
  className = 'addtl-class',
  link,
  isCTA,
  trimColon,
  assetId,
  asset,
  tooltipBinding, // currently unused due to below comment
  ariaLabel,
  children,
  popConfirmOnClick,
  popConfirmBinding,
  ...props
}) => {
  const c = useActiveCountry();
  const { ct } = useCountryTranslation(c?.Code.iv);
  const boundAriaLabel = useBinding(`${ariaLabel}.Text`);

  const buttonText = useMemo(() => {
    const textBinding = `${binding}.Text`;
    var bt = binding != null ? decode(ct(textBinding)) : text;
    // falls back to "text" if provided & binding is not resolved; may comment out after all content has been added?
    if (bt === `${c?.Code.iv}_${textBinding}` || bt === "NA") {
      return text;
    }

    return trimColon ? bt && trimColonFunc(bt) : bt;
  }, [binding, c?.Code.iv, ct, text, trimColon]);


  // TODO: Tooltips have been disabled until there is time/budget to
  //       create the translations needed to populate their values.

  iconRight = isCTA && !iconRight ? (type === "primary" ? faArrowRightSolid : faArrowRightRegular) : iconRight;

  const buttonProps = {
    "aria-label": ariaLabel && boundAriaLabel ? boundAriaLabel : buttonText ?? (props['aria-label'] ?? undefined),
    type: isCTA || type === "light-default" ? "default" : type,
    ...props,
  };

  const imageButton = (
    <Button
      className={`bound-button ${type} ${className} image-button`}
      {...buttonProps}
    >
      <DefaultImage asset={asset} assetId={assetId} />
      <div>{buttonText}</div>
    </Button>
  );

  const buttonElement = (
    <Button
      className={`bound-button ${type} ${className} ${isCTA ? "cta" : null} ${type === "light-default" ? "light-default" : null}`}
      onKeyDown={(e) => {
        if (e.key === "Enter" && (link || isCTA)) {
          scrollToTop();
        }
      }}
      {...buttonProps}
    >
      { children && (children)}
      { !children && (
        <div className="button-body">
          <div>{buttonText}</div>
          { iconRight && <FontAwesomeIcon icon={iconRight} /> }
        </div>
      )}
    </Button>
  );

  if (link) {
    return (
      <DefaultLink className="bs-nav-link" to={link} tabIndex={-1}>
        {buttonElement}
      </DefaultLink>
    );
  }

  return (
    <>
      {/* <Tooltip title={tooltipText}> */}
      { (assetId || asset) && ( imageButton )}
      { !assetId && !asset && (
        <>
          { popConfirmOnClick && (
            <RedirectConfirmation
              confirmationBinding={popConfirmBinding}
            >
              { buttonElement }
            </RedirectConfirmation>
          )}
          { !popConfirmOnClick && (
            buttonElement
          )}
        </>
      )}
      {/* </Tooltip> */}
    </>
  );
};
