import { useCallback, useContext } from 'react';
import { ContentContext } from '../contexts/ContentContext';
import { useCountryTranslation, StorageKeys } from 'brightsky-3';
import useActiveCountry from '../hooks/useActiveCountry';
import { getCountrySubdomain } from '../../util/functions';
import { ContentItem, Country } from '../../compiler/types';
import { BindingsUnion } from '../../compiler/enums';
import i18n from '../../util/i18n';

const useContentContext = () => {
  const { context, dispatch } = useContext(ContentContext);

  if (!context) {
    throw new Error('useContentContext must be used within a ContentContextProvider');
  }

  const { content } = context;
  const c = useActiveCountry();
  const sCountry = getCountrySubdomain();
  const l = i18n.language;
  const calcCountry = sCountry == null ? c : sCountry;

  const { bind, n } = useCountryTranslation(calcCountry);

  // get content without knowing storagekey
  const getContentFromBinding = <T extends ContentItem>(binding: BindingsUnion): T | null => {
    const b = bind(binding);
    var result = null;
    
    Object.keys(content).forEach((key: StorageKeys) => {
      var check = content[key][l as keyof object]?.[n]?.[b];
      if (check) {
        result = check;
        return;
      }
    });
    return result ?? null;
  };

  const getContent = <T extends ContentItem>(key: StorageKeys, binding: BindingsUnion): T | null => {
    const selected = getRawContent(key);
    if (!selected) {
      return null;
    }

    const b = bind(binding);
    return selected[l as keyof object]?.[n]?.[b];
  };

  const getRawContent = useCallback((key: StorageKeys): object | null => {
    if (!content) {
      return null;
    }
    const selected = content[key];
    //if (selected == null) {
    //  throw Error(`ContentContext: No content found matching ${key} binding`);
    //}

    return selected;
  }, [content]);

  const hasContentSet = (key: StorageKeys) => {
    return content != null && content[key];
  };
  
  const getCountries = useCallback((): Array<Country> => {
    const hasContent = content != null && content[StorageKeys.CountryStore] != null;
    if (hasContent) {
      return getRawContent(StorageKeys.CountryStore) as Array<Country>;
    }
    return [];
  }, [content, getRawContent]);

  const setAllContent = (content: Record<StorageKeys, object>) => {
    dispatch({
      type: 'SET_ALL_CONTENT',
      payload: { content },
    })
  };

  const setContent = (key: StorageKeys, content: object) => {
    dispatch({
      type: 'SET_CONTENT',
      payload: { key, content },
    })
  };

  const setContentLoading = (key: StorageKeys, loading: boolean) => {
    dispatch({
      type: 'SET_LOADING',
      payload: { key, loading },
    })
  };

  return {
    ...context,
    getContentFromBinding,
    getContent,
    getRawContent,
    hasContentSet,
    getCountries,
    setAllContent,
    setContent,
    dispatch,
    setContentLoading
  };
};

export default useContentContext;
