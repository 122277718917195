import { FC, PropsWithChildren } from 'react';
import { Modal, ModalProps } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../hooks/useActiveCountry';

import './common.css';
import { TextBindings } from '../../compiler/enums';

export const DefaultModal: FC<PropsWithChildren<ModalProps & { visible: boolean }>> = ({
  visible, onOk, onCancel, title, children, footer, width = 600, closable, loading, maskClosable, className, wrapClassName, styles, classNames,
}) => {
  const c = useActiveCountry();
  const { ct } = useCountryTranslation(c?.Code.iv);

  return (
    <Modal
      width={width} 
      title={title} 
      open={visible} 
      onOk={onOk} 
      onCancel={onCancel} 
      footer={footer} 
      cancelText={c?.Code.iv && c?.Code.iv !== "VF" ? ct(`${TextBindings.LabelCancel}.Text`) : "Cancel"}
      closable={closable}
      loading={loading}
      className={className}
      maskClosable={maskClosable}
      wrapClassName={wrapClassName}
      styles={styles}
      classNames={classNames}
    >
      {children}
    </Modal>
  );
};
