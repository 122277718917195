import { useContext } from 'react';
import { AppSettingsContext } from '../contexts/AppSettingsContext';
import { setLoading } from '../contexts/actions';

const useAppSettingsContext = () => {
  const { context, dispatch } = useContext(AppSettingsContext);

  if (!context) {
    throw new Error('useAppSettings must be used within a AppSettingsContextProvider');
  }

  const setAppSettingsLoading = (isLoading: boolean) => {
    dispatch(setLoading(isLoading))
  };

  const setAppSettings = (appSettings: object) => {
    dispatch({
      type: 'SET_APP_SETTINGS',
      payload: { appSettings },
    });
  };

  return {
    ...context,
    setAppSettings,
    setAppSettingsLoading,
    dispatch,
  };
};

export default useAppSettingsContext;
