import { FC, ReactNode, useMemo } from 'react';
import { Checkbox, Form, Radio, Space } from 'antd';
import { DefaultTextbox } from './DefaultTextbox';
import { DefaultTextArea } from './DefaultTextArea';
import { ListBindings, TextBindings } from '../../compiler/enums';
import useActiveCountry from '../hooks/useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import { DefaultDatePicker } from './DefaultDatePicker';
import { DefaultSelect } from './DefaultSelect';

export enum FormRowType {
  TextBox = 'textbox',
  TextArea = 'textarea',
  Radio = 'radio',
  Checkbox = 'checkbox',
  DatePicker = 'datepicker',
  Select = 'select',
}

type FormRowProps = {
  className?: string,
  name: string,
  type: FormRowType,
  label?: string,
  textBinding?: TextBindings,
  required?: boolean,
  touched?: boolean | undefined,
  error?: string | undefined,
  onChange?: (e: any) => void,
  onBlur?: (e: any) => void,
  value: any,
  defaultValue?: any,
  extra?: ReactNode,
  choices?: Array<{ binding: TextBindings | string, value: any }>,
  setFieldValue?: (name: string, value: any) => {},
  placeholder?: string,
  placeholderBinding?: TextBindings,
  itemsBinding?: ListBindings,
  items?: Array<{ label: string | string, value: any }>,
  direction?: "vertical" | "horizontal",
}

export const FormRow: FC<FormRowProps> = ({
  name, type, textBinding, required = false, touched, error, onChange, onBlur, value, extra, choices, setFieldValue, className, placeholderBinding,
  defaultValue, itemsBinding, direction, items, label, placeholder
}) => {
  const hasError = touched && error;

  const c = useActiveCountry();
  const { ct } = useCountryTranslation(c?.Code.iv);

  const boundText = useMemo(() => {
    if (!textBinding) {
      return null;
    }

    var label = decode(ct(`${textBinding}.Text`));
    label = label.trim();

    if (label.at(label.length - 1) === ':') {
      label = label.substring(0, label.length - 1);
    }

    return label;
  }, [ct, textBinding]);

  const boundPlaceholder = useMemo(() => {
    if (!placeholderBinding) {
      return undefined;
    }

    var placeholder = decode(ct(`${placeholderBinding}.Text`));
    placeholder = placeholder.trim();

    if (placeholder.at(placeholder.length - 1) === ':') {
      placeholder = placeholder.substring(0, placeholder.length - 1);
    }

    return placeholder;
  }, [ct, placeholderBinding]);

  return (
    <Form.Item
      label={type === 'checkbox' ? ' ' : (label ?? boundText)}
      validateStatus={hasError ? 'error' : ''}
      extra={extra}
      required={required}
      style={{ flexDirection: type === 'checkbox' ? 'row' : undefined }}
      className={className}
      help={hasError ? error : undefined}
    >
      { type === FormRowType.TextBox && (
        <DefaultTextbox 
          name={name} 
          onChange={onChange} 
          onBlur={onBlur} 
          value={value} 
          className={hasError ? 'default-textbox-error' : ''} 
          placeholder={boundPlaceholder}
        />
      )}
      { type === FormRowType.TextArea && (
        <DefaultTextArea 
          name={name} 
          onChange={onChange}
          onBlur={onBlur} 
          value={value} 
          className={hasError ? 'default-textbox-error' : ''} 
          placeholder={boundPlaceholder}
        />
      )}
      { type === FormRowType.Radio && choices && choices.length > 0 && (
        <Radio.Group
          onChange={evt => {
            setFieldValue && setFieldValue(name, evt.target.value);
          }}
          value={value}
          name={name}
        >
          <Space direction={direction ?? "vertical"}>
            { choices.map(choice => (
              <Radio value={choice.value} key={choice.value} checked={value === choice.value}>
                {!Object.values<string>(TextBindings).includes(choice.binding) ? choice.binding : decode(ct(`${choice.binding}.Text`))}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )}
      { type === FormRowType.Checkbox && (
        <Checkbox name={name} onChange={onChange} checked={value}>
          {boundText}
        </Checkbox>
      )}
      { type === FormRowType.DatePicker && (
        <DefaultDatePicker
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
        />
      )}
      { type === FormRowType.Select && (
        <DefaultSelect
          name={name} 
          onChange={value => {
            console.log('change', value);
            const castValue = value === undefined ? null : value;
            setFieldValue && setFieldValue(name, castValue);
            (onChange && castValue) && onChange(castValue); // if null or undefined, onChange throws an error
          }}
          onBlur={onBlur} 
          value={value} 
          className={hasError ? 'default-select-error' : ''} 
          placeholder={placeholder ?? boundPlaceholder}
          ariaLabel={name + " select"}
          itemsBinding={itemsBinding}
          items={items}
        />
      )}
    </Form.Item>
  );
};
