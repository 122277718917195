import { FC, useEffect, useMemo, useState } from "react";
import { BoundButton } from "../BoundButton";
import { TextBindings } from "../../../compiler/enums";
import { faCloud } from "@fortawesome/pro-solid-svg-icons";
import { DefaultModal } from "../DefaultModal";
import { useBinding, useIsMobile, useTheme } from "../../utils/common";
import { Divider, Row } from "antd";
import assetService from "../../../services/asset-service";
import { AssetsDTO } from "../../../compiler/types";
import useActiveCountry from "../../hooks/useActiveCountry";
import { useCountryTranslation } from 'brightsky-3';
import useAppSettingsContext from "../../hooks/useAppSettingsContext";
import { decode } from "html-entities";
import { FeedbackSubmissionRequest } from "../../../features/feedback/types";
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import useWebAnalyticsContext from "../../hooks/useWebAnalyticsContext";
import feedbackService from "../../../services/feedback-service";
import useConfigContext from "../../hooks/useConfigContext";

import "./FeedbackToggle.css";

export const FeedbackToggle: FC = () => {
  const c = useActiveCountry();
  const { ct, l } = useCountryTranslation(c?.Code.iv);
  const { appSettings } = useAppSettingsContext();
  const { translationsLoading } = useConfigContext();
  const theme = useTheme();
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
  const [rating, setRating] = useState<number | null>(null);
  const [cloudAssets, setCloudAssets] = useState<AssetsDTO | null>(null);
  const isMobile = useIsMobile();

  const { logEvent } = useWebAnalyticsContext();
  const [success, setSuccess] = useState<string | null>();
  const [error, setError] = useState<string | null>();
  
  const cloudAssetIds = useMemo(() => {
    return [
      "5b2bec41-9c27-44c7-8165-29376b6d4cb4", // hate
      "a234fc74-322b-4a9b-a276-9fe16c077c27", // dislike
      "7c3b0dec-7c8d-4c93-8133-c93b07cecf33", // neutral
      "69d85748-b1de-4fcd-8c06-f3646247ea37", // like
      "442a98d2-6031-44d7-a7c0-878397b82015", // love
    ];
  }, []);
  
  const feedbackSubmitSuccess = useBinding(`${TextBindings.SuccessSubmittedFeedback}.Text`);
  const feedbackSubmitFailure = useBinding(`${TextBindings.ErrorFeedbackFailed}.Text`);
  const feedbackSubText = useBinding(`${TextBindings.FeedbackContent2}.Text`);

  const feedbackQuestion = useMemo(() => {
    if (!appSettings?.feedbackModalQuestion) {
      return null;
    }

    let binding: TextBindings | null = null;
    switch (appSettings.feedbackModalQuestion) {
      case "EaseOfUseRating":
        binding = TextBindings.TextFeedbackEaseOfUse; break;
      case "HelpfulRating":
        binding = TextBindings.TextFeedbackHelpful; break;
      case "UseAgainRating":
        binding = TextBindings.TextFeedbackUseAgain; break;
    }

    var boundQuestion = binding ? decode(ct(`${binding}.Text`)) : null;
    const questionParts = boundQuestion?.split(". ");
    return questionParts && questionParts.length > 1 ? questionParts[1] : boundQuestion;
  }, [appSettings?.feedbackModalQuestion, ct]);

  useEffect(() => {
    if (appSettings?.feedbackModalQuestion) {
      setLoading(true);
      assetService.getAssets(cloudAssetIds)
        .then((data) => {
          setCloudAssets(data);
          setLoading(false);
        })
        .catch((err) => {
          console.warn("Error loading cloud assets for feedback modal:", err);
        });
    }
  // only need to run this once / on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // clear state values on close
  useEffect(() => {
    if (!visible) {
      if (rating) {
        setRating(null);
      }
      if (error) {
        setError(null);
      }      
      if (success) {
        setSuccess(null);
      }
    }
  }, [error, rating, success, visible]);

  const onSubmit = () => {
    if (!rating) {
      console.warn("feedbackModal onSubmit: No rating to submit");
      return null;
    }

    if (!appSettings?.feedbackModalQuestion) {
      console.warn("feedbackModal onSubmit: Invalid question key");
      return null;
    }
    setSubmissionLoading(true);
    var submission: FeedbackSubmissionRequest = {
      HelpfulRating: null,
      EaseOfUseRating: null,
      UseAgainRating: null,
      AgeRange: null,
      Gender: null,
      Ethnicity: null,
    };

    submission[appSettings?.feedbackModalQuestion] = rating;

    if (Events?.FeedbackSubmit) {
      logEvent(Events.FeedbackSubmit, Origins.Feedback, Templates.feedback.submit(submission));
    }
    feedbackService.submitFeedback(submission)
      .then(() => {
        setSubmissionLoading(false);
        setSuccess(feedbackSubmitSuccess);
        setTimeout(() => {
          setSuccess('');
        }, 5000);

        // clear values
        setRating(null);
      },
      () => {
        setSubmissionLoading(false);
        setError(feedbackSubmitFailure);
      }
    );
  };

  if (!appSettings?.feedbackModalQuestion) {
    return null;
  }

  return (
    <>
      <BoundButton 
        className={`feedback-toggle ${visible ? "toggled" : ""}`} 
        binding={TextBindings.LinkFeedback} 
        iconRight={faCloud}
        onClick={() => {visible === false && setVisible(true)}}
      />
      <DefaultModal
        loading={loading || translationsLoading}
        className={`feedback-modal ${theme} ${isMobile ? "mobile" : ""}`}
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        styles={{
          mask: { display: isMobile ? undefined : "none" },
        }}
      >
        { cloudAssets && (
          <div>
            <h2 className="averia-serif">{feedbackQuestion}</h2>
            <span className="lato">{feedbackSubText}</span>
            <Divider />
            <Row className="cloud-btn-row lato">
              { cloudAssets.items.map((cloudAsset, index) => {
                return (
                  <BoundButton
                    className={`cloud-btn ${rating === index + 1 ? "selected" : ""}`}
                    key={cloudAsset.id} 
                    asset={cloudAsset} 
                    text={cloudAsset.metadata[`alt-${l}`]}
                    onClick={() => { setRating(index + 1) }}
                  />
                );
              })}
            </Row>
            <Row className="submit-row">
              <BoundButton
                className="submit-btn"
                binding={TextBindings.ButtonSubmitFeedback}
                disabled={!rating}
                onClick={() => onSubmit()}
                loading={submissionLoading}
              />
              { success != null && <h3 className="submit-validation">{success}</h3>}
              { error != null && <div className="submit-validation" style={{ color: 'red' }}>{error}</div>}
            </Row>
          </div>
        )}
      </DefaultModal>
    </>
  );
};