import { FC, ReactElement } from "react";
import { Page } from "../../common/components/page-component/Page";
import { GuideTemplate, TextBindings, GuideBindings } from "../../compiler/enums";
import { decode } from 'html-entities';
import { GuideAccordion } from "./components/GuideAccordion";
import { LocalizedGuideContent } from "../../compiler/types";
import DefaultImage from "../../common/components/DefaultImage";
import { useIsMobile } from "../../common/utils/common";

import "./GuideComponent.css";

type GuideComponentProps = {
  content?: LocalizedGuideContent,
  binding?: GuideBindings,
  template: GuideTemplate,
  headerImageAltText: string,
  title?: string,
  introCta?: ReactElement,
  moreBinding?: TextBindings,
};

const Guide: FC<GuideComponentProps> = ({
  content, template, headerImageAltText, title, introCta, moreBinding, binding,
}) => {
  const guideContent = content;
  const isMobile = useIsMobile();
  const isOnlineSafetyGuide = binding === GuideBindings.OnlineSafety;

  return (
    <Page furtherResources={guideContent?.FurtherResources}>
      <div className="guide-component">
        <div className="color section header">
          <div>
            <h1>
              {title ?? decode(guideContent?.Title)}
            </h1>
            { guideContent?.Description && !isOnlineSafetyGuide && (
              <div className="lato" dangerouslySetInnerHTML={{
                  __html: decode(guideContent?.Description)
                }}
              />
            )}
          </div>
          <div className="guide-image">
            <DefaultImage height={isMobile ? 180 : 300} width={isMobile ? 240 : 400} src={"https://picsum.photos/400/300"} alt={headerImageAltText} />
          </div>
        </div>
        { template === GuideTemplate.TemplateOne && (
          <>
            { isOnlineSafetyGuide && (
              <div className={`${isOnlineSafetyGuide ? 'white' : 'color'} section`}>
                <div dangerouslySetInnerHTML={{ // TODO: replace with introBinding
                    __html: decode(guideContent?.Description)
                  }}
                />
                {introCta && introCta}
              </div>
            )}
            <div className={`${isOnlineSafetyGuide ? 'color' : 'white'} section`}>
              <GuideAccordion 
                guides={guideContent?.Guides} 
                template={template} 
                moreBinding={moreBinding}
              />
            </div>
          </>
        )}
        { template === GuideTemplate.TemplateTwo && (
          <div className="white section">
            <GuideAccordion 
              guides={guideContent?.Guides} 
              template={template} 
              moreBinding={moreBinding}
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default Guide;