import { useContext } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';
import { setLoading } from '../contexts/actions';
import { Country, Language } from '../../compiler/types';

const useConfigContext = () => {
  const { configContext, configDispatch } = useContext(ConfigContext);

  if (!configContext) {
    throw new Error('useConfigContext must be used within a ConfigContextProvider');
  }

  const setConfigLoading = (loading: boolean) => {
    configDispatch(setLoading(loading));
  };

  const setLanguage = (language: Language | null) => {
    configDispatch({
      type: 'SET_SELECTED_LANGUAGE',
      payload: { language },
    })
  };

  const setSavedLanguage = (language: Language | null) => {
    configDispatch({
      type: 'SET_SAVED_LANGUAGE',
      payload: { language },
    })
  };

  const setLanguages = (languages: Array<Language>) => {
    console.log("LANGUAGES CHANGED", languages);
    configDispatch({
      type: 'SET_LANGUAGES',
      payload: { languages },
    })
  };

  const setCountry = (country: Country | null) => {
    configDispatch({
      type: 'SET_SELECTED_COUNTRY',
      payload: { country },
    })
  };  

  const setSavedCountry = (country: Country | null) => {
    configDispatch({
      type: 'SET_SAVED_COUNTRY',
      payload: { country },
    })
  };
  
  const setHasConfirmedConfig = (hasConfirmedConfig: boolean) => {
    configDispatch({
      type: 'SET_HAS_CONFIRMED_CONFIG',
      payload: { hasConfirmedConfig },
    })
  };

  const setHasConfirmedExitSafely = (hasConfirmedExitSafely: boolean) => {
    configDispatch({
      type: 'SET_HAS_CONFIRMED_EXIT_SAFELY',
      payload: { hasConfirmedExitSafely },
    })
  };

  const setIsChangingCountry = (isChangingCountry: boolean) => {
    configDispatch({
      type: 'SET_IS_CHANGING_COUNTRY',
      payload: { isChangingCountry },
    })
  };

  const setTranslationsLoading = (translationsLoading: boolean) => {
    configDispatch({
      type: 'SET_TRANSLATIONS_LOADING',
      payload: { translationsLoading },
    })
  };

  const setConfigTranslationsLoading = (configTranslationsLoading: boolean) => {
    configDispatch({
      type: 'SET_CONFIG_TRANSLATIONS_LOADING',
      payload: { configTranslationsLoading },
    })
  };

  const setCurrentTheme = (currentTheme: string) => {
    configDispatch({
      type: 'SET_CURRENT_THEME',
      payload: { currentTheme },
    })
  };

  return {
    ...configContext, 
    configDispatch, 
    setConfigLoading, 
    setLanguage,
    setSavedLanguage,
    setCountry,
    setSavedCountry,
    setLanguages,
    setHasConfirmedConfig,
    setHasConfirmedExitSafely,
    setIsChangingCountry,
    setTranslationsLoading,
    setConfigTranslationsLoading,
    setCurrentTheme
  };
};

export default useConfigContext;
