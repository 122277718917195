import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Page } from "../../common/components/page-component/Page";

import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from "../../common/hooks/useActiveCountry";
import { Links, TextBindings } from "../../compiler/enums";
import { decode } from 'html-entities';
import { LocalizedPageContent } from "../../compiler/types";

import GoogleMap from 'google-maps-react-markers';
import FindSupportSearch from "../../common/components/FindSupportSearch";
import { Loading } from "../../common/components/Loading";
import { BoundButton } from "../../common/components";
import useAppSettingsContext from "../../common/hooks/useAppSettingsContext";
import useLocationSearchContext from "./hooks/useLocationSearchContext";
import { useLocation, useSearchParams } from "react-router-dom";
import useConfig from "../../config/hooks/useConfig";
import MapMarker from "./components/MapMarker";

import { TagFilters } from "./components/TagFilters";
import { faSliders } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UpdateDetailsModal } from "./components/UpdateDetailsModal";
import { ServiceResultCard } from "./components/ServiceResultCard";
import { useBinding, useLink } from "../../common/utils/common";
import { Divider, Row } from "antd";

import "./ServiceDirectory.css";

type ServiceDirectoryProps = {
  content?: LocalizedPageContent;
  isHelplines: boolean;
};

const ServiceDirectory: FC<ServiceDirectoryProps> = ({ content, isHelplines }) => {
  const config = useConfig();
  const c = useActiveCountry();
  const { ct } = useCountryTranslation(c?.Code.iv);
  const serviceDirectoryContent = content;
  
  const [filters, setFilters] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const { appSettings } = useAppSettingsContext();
  const { loading, runSearch, locationSearch, setLocationSearch, serviceResults, mapRegion } = useLocationSearchContext();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const findSupportLink = useLink(Links.SupportServices);
  const nationalHelplinesLink = useLink(Links.NationalHelplines);
  const locationQuery = searchParams.get('query');
  const nationalHelplinesHeader = useBinding(`${TextBindings.NavAbuseAdviceHelplines}.Text`);
  const isUS = c?.Code.iv === "US";

  const physicalLocations = useMemo(() => {
    return serviceResults ? serviceResults.filter(r => !r.IsNationalHelpLine) : [];
  }, [serviceResults]);

  const nationalHelplines = useMemo(() => {
    return serviceResults ? serviceResults.filter(r => r.IsNationalHelpLine) : [];
  }, [serviceResults]);
  
  const showingResultsLabel = useMemo(() => {
    var resultsLabel = decode(ct(`${TextBindings.TextResults}.Text`));
    resultsLabel = resultsLabel.trim();

    if (resultsLabel.at(resultsLabel.length - 1) === ':') {
      resultsLabel = resultsLabel.substring(0, resultsLabel.length - 1);
    }

    const resultCount = isHelplines ? nationalHelplines.length : physicalLocations.length;

    return `${decode(ct(`${TextBindings.LabelShowingResultsFor}.Text`))} "${locationQuery}" (${resultCount} ${resultsLabel})`;
  }, [ct, isHelplines, locationQuery, nationalHelplines.length, physicalLocations.length]);

  const handleSearch = useCallback((l, newFilters?) => {
    const calcFilters = newFilters ? newFilters : filters;
    const calcLocation = l ? l : locationSearch;
    runSearch(
      calcLocation,
      null,
      calcFilters
    );
  }, [filters, locationSearch, runSearch]);

  useEffect(() => {
    setLocationSearch(locationQuery ?? null);
    if (locationQuery || location.pathname === nationalHelplinesLink) {
      if (isUS && location.pathname === findSupportLink) {
        window.open(`https://www.domesticshelters.org/search#?q=${locationQuery}&page=1`);
        handleSearch(""); // still query helplines
      } else {
        handleSearch(locationQuery);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationQuery, searchParams]);

  const handleUpdateSearch = (newFilters) => {
    setFilters(newFilters);
    handleSearch(locationSearch, newFilters);
  };

  const mapDetails = useMemo(() => {
    return {
      center: {
        lat: mapRegion?.Latitude ?? 0,
        lng: mapRegion?.Longitude ?? 0,
      },
      zoom: 11
    };
  }, [mapRegion]);

  if (serviceDirectoryContent == null) {
    return null;
  }
  
  return (
    <Page>
      <div className="service-directory">
        <div className="color section header">
          <h1>
            {decode(serviceDirectoryContent.Title)}
          </h1>
          { serviceDirectoryContent?.Content && (
            <div className="lato" dangerouslySetInnerHTML={{
                __html: decode(serviceDirectoryContent?.Content)
              }}
            />
          )}
          { !isHelplines && (
            <FindSupportSearch includeHelplines showBtnIcons searchVal={locationQuery} />
          )}
        </div>
        { (searchParams.size > 0 || location.pathname === nationalHelplinesLink) && ( // if searchParams = 0, first time load of page && should not show results section
          <div className="white section">
            { loading && (
              <Loading />
            )}
            { !loading && (
              <>
                { !isHelplines && !isUS && (
                  <div className="locate-support-results">
                    { physicalLocations && physicalLocations.length > 0 && (
                      <>
                        <div className="map-results-controls">
                          <div className="text">
                            {showingResultsLabel}
                          </div>
                          <div className="button">
                            { appSettings?.locationFiltering && (
                              <BoundButton
                                type="light-default"
                                icon={<FontAwesomeIcon icon={faSliders} />}
                                binding={TextBindings.TextFilterResults}
                                tooltipBinding="Web_Tooltip_FilterResults.Text"
                                onClick={() => setFiltersOpen(true)}
                              />
                            )}
                            <TagFilters filters={filters} visible={filtersOpen} onUpdated={handleUpdateSearch} onClose={() => setFiltersOpen(false)} />
                          </div>
                        </div>
                        <div className="google-map">
                          <GoogleMap
                            apiKey={config?.googleMapsKey}
                            defaultCenter={mapDetails.center}
                            defaultZoom={mapDetails.zoom}
                            onGoogleApiLoaded={() => Function.prototype}
                          >
                            { physicalLocations
                                .filter(p => p.DisplayOnMap === true)
                                .map((p, i) => {
                                   return (
                                     <MapMarker 
                                       key={i.toString()} 
                                       lat={p.Location.Latitude} 
                                       lng={p.Location.Longitude} 
                                       text={i + 1} 
                                     />
                                   );
                            })}
                          </GoogleMap>
                        </div>
                      </>         
                    )}
                    { appSettings?.submitServiceRequestEnabled && (
                      <UpdateDetailsModal located="Support" />
                    )}
                    <div className="service-result-container">
                      { physicalLocations && physicalLocations.map((location, index) => {
                        return (
                          <ServiceResultCard key={"location-result-" + index} isHelpline={isHelplines} service={location} index={index} />
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="national-helplines-results">
                  { !isHelplines && (   
                    <>
                      { !isUS && (
                        <Divider />
                      )}
                      <Row justify="center" className="national-helplines-header">
                        <h1>
                          {nationalHelplinesHeader}
                        </h1>
                      </Row>
                    </>
                  )}
                  { appSettings?.submitServiceRequestEnabled && (
                    <UpdateDetailsModal located="Helplines" />
                  )}
                  <div className="service-result-container">
                    { nationalHelplines && nationalHelplines.map((helpline, index) => {
                      return (
                        <ServiceResultCard key={"helpline-result-" + index} isHelpline={isHelplines} service={helpline} index={index} />
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

export default ServiceDirectory;