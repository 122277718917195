import { useContext, useEffect, useMemo } from 'react';
import { LocationSearchContext } from '../contexts/LocationSearchContext';
import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import useConfig from '../../../config/hooks/useConfig';
import http from 'brightsky-3/services/httpRequestService';
import { getDistance } from 'geolib';
import useActiveCountry from '../../../common/hooks/useActiveCountry';
import useWebAnalyticsContext from '../../../common/hooks/useWebAnalyticsContext';
import useContentContext from '../../../common/hooks/useContentContext';
import { LocalizedTagComponent, LocationContent, ServiceDTO, LocationSearchRequest, MapRegion, CoordObj } from '../types';
import contentService from '../../../services/content-service';

const useLocationSearchContext = () => {
  const { context, dispatch } = useContext(LocationSearchContext);

  if (!context) {
    throw new Error('useLocationSearchContext must be used within a SearchContextProvider');
  }
  
  const { tags, tagFilters } = context;
  const Config = useConfig();
  const c = useActiveCountry();
  const { logEvent } = useWebAnalyticsContext();
  const { l } = useCountryTranslation();
  const { content, loading } = useContentContext();

  const countryName = useMemo(() => {
    if (!content || !content[StorageKeys.CountryStore]) {
      return null;
    }
    const cacheStore = content[StorageKeys.CountryStore];
    const cc = `${c?.Code.iv}`;
    const filteredCountry = cacheStore.filter(c => c.Code.iv === cc);
    return filteredCountry.length > 0 ? filteredCountry[0].Name.en : null;
  }, [c, content]);

  useEffect(() => {
    if (!loading && tags.length === 0) {
      const hasTagsContent = content != null && content[StorageKeys.TagsKey] != null;
      if (hasTagsContent) {
        dispatch({
          type: 'SET_TAGS',
          payload: { tags: content[StorageKeys.TagsKey] },
        });
      }
    }
  }, [content, loading, tags.length, dispatch]);

  const getMapRegion = (locationData: Array<ServiceDTO>, coords: {lat: number, lng: number}) => {
    let minX = coords.lat;
    let maxX = coords.lat;
    let minY = coords.lng;
    let maxY = coords.lng;

    locationData.forEach(point => {
      minX = Math.min(minX, point.Location.Latitude || 0);
      maxX = Math.max(maxX, point.Location.Latitude || 0);
      minY = Math.min(minY, point.Location.Longitude || 0);
      maxY = Math.max(maxY, point.Location.Longitude || 0);
    });

    const midX = (minX + maxX) / 2;
    const midY = (minY + maxY) / 2;
    const deltaX = maxX - minX;
    const deltaY = maxY - minY;

    const region = {
      Latitude: midX,
      Longitude: midY,
      LatitudeDelta: deltaX + 0.05,
      LongitudeDelta: deltaY + 0.05,
    };

    setMapRegion(region);
  };

  const getTag = (tagId: string): LocalizedTagComponent | null => {
    const tagMatches = tags?.filter(t => t.Id === tagId);
    if (!tagMatches || tagMatches.length === 0) {
      return null;
    }

    return {
      Id: tagMatches[0].Id,
      Title: tagMatches[0].Title[l],
      Icon: tagMatches[0].Icon.iv,
      ColorCode: tagMatches[0].ColorCode.iv,
      BorderColorCode: tagMatches[0].BorderColorCode.iv,
      Type: tagMatches[0].Type.iv,
    };
  };

  const mapResult = (location: LocationContent, startingLocation: {latitude: number, longitude: number} | null): ServiceDTO => {
    const distance =
      location?.Location?.iv != null && startingLocation && location?.Location?.iv?.Longitude && location?.Location?.iv?.Latitude
        ? (
            getDistance(startingLocation, {
            longitude: location?.Location?.iv?.Longitude,
            latitude: location?.Location?.iv?.Latitude,
          }) / 1000)
          .toFixed(2)
        : 0;

    const flatTags = location.Tags?.iv?.map(tagId => getTag(tagId)).filter(x => x !== null) as Array<LocalizedTagComponent>;
    return {
      Name: location.Name != null ? location.Name[l] : '',
      Tags: flatTags,
      PrimaryPhoneNumber: location.PrimaryPhoneNumber,
      PhoneNumbers: location.PhoneNumbers?.iv?.map(p => p.Number),
      PrimaryEmailAddress: location.PrimaryEmailAddress,
      EmailAddresses: location.EmailAddresses?.iv?.map(e => e.Email),
      Address: location.Address != null ? location.Address[l] : '',
      Hours: location.Hours != null ? location.Hours[l] : '',
      Website: location.Website?.iv,
      Description: location.Description != null ? location.Description[l] : '',
      Distance: distance,
      Location: {
        Latitude: location.Location ? location?.Location?.iv?.Latitude : null,
        Longitude: location.Location ? location?.Location?.iv?.Longitude : null,
      },
      DisplayOnMap: location.DisplayOnMap?.iv,
      IsNationalHelpLine: location.IsNationalHelpLine?.iv,
      LastVerified: location.LastVerified?.iv,
      Order: location.Order?.iv == null ? -1 : location.Order?.iv,
    };
  };

  const compareNumbers = (a: ServiceDTO, b: ServiceDTO) => {
    const n1 = a.Order != null && a.Order >= 0 ? a.Order : 100000;
    const n2 = b.Order != null && b.Order >= 0 ? b.Order : 100000;
    return n1 - n2;
  };

  const getNationalHelplines = (currentFilters, onComplete?: Function) => {
    if (!c) {
      return;
    }

    setLoading(true);
    const searchFilters = !currentFilters ? tagFilters : currentFilters;
    contentService.getHelplines(c?.Code.iv, searchFilters)
      .then((result) => {
        let locationData = [];
        try {
          locationData = result
            .map(location => {
              const mappedResult = mapResult(location, null);
              return mappedResult;
            })
            .sort(compareNumbers);
          if (onComplete) onComplete(locationData);
        } catch (ex) {
          console.warn('Error loading helplines', ex);
        }
        setServiceResults(locationData);
      })
      .catch((err) => {
        console.warn('Error querying helplines:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLocationResults = (geolocation, onComplete?: Function, currentFilters?) => {
    if (!c) {
      return;
    }

    setLoading(true);
    const currentTagFilters = currentFilters || tagFilters;
    const locationSearchRequest: LocationSearchRequest = {
      Tags: currentTagFilters,
      Country: c.Code.iv,
      Latitude: geolocation.lat,
      Longitude: geolocation.lng,
    };

    contentService.getLocations(locationSearchRequest)
      .then((result: Array<LocationContent>) => {
        const startingLocation = {
          longitude: geolocation.lng,
          latitude: geolocation.lat,
        };

        const locationData = result.map(location => {
          const mappedResult = mapResult(location, startingLocation);
          return mappedResult;
        });
        setServiceResults(locationData);

        getMapRegion(
          locationData.filter(l2 => l2.Location.Latitude != null && l2.Location.Longitude != null && !l2.IsNationalHelpLine),
          geolocation
        );
        if (onComplete) {
          onComplete(locationData);
        }
      })
      .catch((err) => {
        console.warn('Error searching locations: ', JSON.stringify(locationSearchRequest), err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const runLocationNameSearch = (search: string, onComplete, currentFilters) => {
    if (!Config) {
      console.error("Error: Invalid config, unable to run location name search");
    }

    setLoading(true);
    let calcSearch = search;
    if (search && search.toUpperCase().startsWith('JE4')) {
      calcSearch = `${search}, Jersey, Channel Islands`;
    } else if (search && search?.indexOf(`, ${countryName}`) === -1) {
      calcSearch = `${search}, ${countryName}`;
    }

    if (search) {
      const googleMapsApi = `https://maps.googleapis.com/maps/api/geocode/json?address=${calcSearch}&key=${Config?.googleMapsKey}`;
      http.get(
        googleMapsApi,
        mapsResult => {
          const firstResult = mapsResult.data.results.length > 0 ? mapsResult.data.results[0] : null;
          const geolocation = firstResult ? firstResult.geometry.location : null;
          setCoordinates(geolocation);
          getLocationResults(geolocation, onComplete, currentFilters);
        },
        err => {
          console.warn('Maps API Failure: ', err);
        }
      );
    } else {
      getNationalHelplines(currentFilters);
    }
  };

  const runSearch = (search, onComplete, currentFilters) => {
    const filterCount = currentFilters ? currentFilters.length : tagFilters.length;
    runLocationNameSearch(search, onComplete, currentFilters);
    if (Events?.LocationSearchEvent) {
      logEvent(Events.LocationSearchEvent, Origins.LocationSearch, Templates.location.search(search, filterCount));
    }
  };

  const addTagFilter = (tagId) => {
    const newTagFilters = [...tagFilters];
    newTagFilters.push(tagId);
    setTagFilters(newTagFilters);
  };

  const removeTagFilter = tagId => {
    const newTagFilters = [...tagFilters];
    setTagFilters(newTagFilters.filter(t => t !== tagId));
  };

  const setTagFilters = (tagFilters: Array<string>) => {
    dispatch({
      type: 'SET_TAG_FILTERS',
      payload: { tagFilters },
    })
  };

  const setCoordinates = (coordinates: CoordObj) => {
    dispatch({
      type: 'SET_COORDINATES',
      payload: { coordinates },
    })
  };

  const setSelectedLocation = (selectedLocation: string) => {
    dispatch({
      type: 'SET_SELECTED_LOCATION',
      payload: { selectedLocation },
    })
  };

  const setLocationSearch = (locationSearch: string | null) => {
    dispatch({
      type: 'SET_LOCATION_SEARCH',
      payload: { locationSearch },
    })
  };

  const setMapRegion = (mapRegion: MapRegion) => {
    dispatch({
      type: 'SET_MAP_REGION',
      payload: { mapRegion },
    })
  };

  const setServiceResults = (serviceResults: Array<ServiceDTO>) => {
    dispatch({
      type: 'SET_SERVICE_RESULTS',
      payload: { serviceResults },
    })
  };

  const setLoading = (loading: boolean) => {
    dispatch({
      type: 'SET_LOADING',
      payload: { loading },
    })
  };

  return {
    ...context,
    runSearch,
    setMapRegion,
    setLocationSearch,
    setLoading,
    setTagFilters,
    addTagFilter,
    removeTagFilter,
    setSelectedLocation,
    getNationalHelplines,
  };
};

export default useLocationSearchContext;
